import React, { useState, useEffect, Suspense } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../../api";
import { useTranslation } from "react-i18next";
import * as ReactBootstrap from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_IDENTIFIER,
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
  CASE_ELIGIBILITY_RESPONSE,
  CASE_COUNTER,
  CASE_OWNER,
  EDIT_BUTTON_CLICK,
  CASE_EDIT
} from "../../../ducks/redux/actionTypes";
import CaseData from "../../../models/Case/Case";
import CaseDrool from "../../../models/Case/CaseDrool";
import "./index.css";
import { CCTV_Question1, COVID_CASE_QUESTION } from "../QuestionType/Question";
import { BackValue } from "../../../models/shared/Enum";
import { isNullOrUndefined } from "../../../utils/formatting";
import { userChosenUploadedMediaIdentifiers } from "../../../ducks/redux/actions/userChoices";

const TableCell = React.lazy(() => import("@material-ui/core/TableCell"));
const TableRow = React.lazy(() => import("@material-ui/core/TableRow"));
const TableBody = React.lazy(() => import("@material-ui/core/TableBody"));
const Loader = React.lazy(() => import("react-loader-spinner"));
const Button = React.lazy(() => import("../../../Button/Button"));
const ExitModalContent = React.lazy(
  () => import("../../Modal/ExitModalContent")
);

interface ReviewCaseProps extends RouteComponentProps<any> {}
const CaseReview: React.FC<ReviewCaseProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState("");
  const [redAlert, setRedAlert] = useState("");
  const [isSingleTenancy,setIsSingleTenancy] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const CRMCategoryProperty = "pea_casecategory";
  enum AssetTypeId {
      Block = 1,
      Property = 2,
      Estate = 3,
      EstateArea = 4,
      DividedBlock = 5,
      Scheme = 6
  };

  enum userTenancyCount {
    Single = 1
  };

  enum AssetTypeName {
    Block = "Block",
    Property = "Property",
    Estate = "Estate",
    EstateArea = "Estate Area",
    DividedBlock = "Divided Block",
    Scheme = "Scheme"
  };

  let tenancyIdentifier: any = "";
  let tenancyType: string;
  let landlordName: string = "null";
  let caseCategory: string;

  
  const [assigneeName, setAssigneeName] = useState("");
  const [assigneeType, setAssigneeType] = useState("");

  const caseTypeDescription = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const assetType = useSelector(
    (state: any) => state.caseCreationReducer.caseAssetType
  );

  const caseSubType = useSelector(
    (state: any) => state.caseCreationReducer.caseSubType
  );

  const caseSubTypeCategory = useSelector(
    (state: any) => state.caseCreationReducer.caseSubTypeCategory
  );

  const caseTypeId = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationId
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );

  //  Validating asset identifier according to block or property case type

  const selectedPropertyAddress = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyAddress
  );

  const userChosenContactPhone = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );

  const userChosenContactName = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );

  const userChosenRelationDetails = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );

  const userChosenRelationId = useSelector(
    (state: any) => state.userChoicesReducer.userChosenReporterId
  );

  const caseCategoryAdditionalFields = useSelector(
    (state: any) => state.caseCreationReducer.caseAdditionalFields
  );

  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  )
  const userTenancy =
    useSelector((state: any) => state?.addresses?.customertenancies) || [];
 const caseTenancyType = useSelector(
  (state: any) => state.caseCreationReducer.caseTenancyType
);
const caseAssetCheck = useSelector(
  (state: any) => state.caseCreationReducer.caseAssetCheck
);
const caseCustomerName = useSelector(
  (state: any) => state.caseCreationReducer.caseCustomerName
);
const isCaseMedia = useSelector(
  (state:any) => state?.caseCreationReducer?.isCaseMediaCheck
);

const questionInfoData = useSelector(
  (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
);
  
if( assetType == t("property") || isNullOrUndefined(assetType)){
  userTenancy.map((data: any, index: number) => {
    if (data?.tenancySummary?.premisesIds[0] === assetDetails?.id) {
      tenancyType = data?.tenancySummary.tenancyType;
      return (tenancyIdentifier = data?.tenancySummary?.tenancyId);
    }
  });
}
else{
  const activeTenancySummaryDetails = userTenancy.filter(
    (ts: { tenancySummary
      : { statuses: any[]; }; }) =>
      ts.tenancySummary
      .statuses.find(
        (s) => s.type === "isTenancyActive"
      )?.value === true
  );
  if(activeTenancySummaryDetails.length > 0){
  tenancyType = activeTenancySummaryDetails[0]?.tenancySummary.tenancyType
  tenancyIdentifier = activeTenancySummaryDetails[0]?.tenancySummary?.tenancyId
  }
}

  const selectedQuestions =
    useSelector(
      (state: any) => state?.caseCreationReducer?.caseCreationAdditionalInfo
    ) || [];

  const caseSelectedQuestionIdsAnswers = useSelector(
    (state: any) => state.caseCreationReducer?.caseSelectedQuestionIdsAnswers
  );

  const selectedQuestionIds = caseSelectedQuestionIdsAnswers.map((item:any) => item.questionId);
  const filterQuestionsSelected = selectedQuestions
  .filter((question:any) => selectedQuestionIds.includes(question.questionId))
  .sort((a:any, b:any) => parseInt(a.sequence) - parseInt(b.sequence));

  let AnsValueArr: any = [];

  let isCovidInFlow = false;
  let isCCTV = false;

  const selectedAnswers = useSelector(
    (state: any) => state?.caseCreationReducer?.caseSelectedAnswers
  );
  const caseAddress = useSelector(
    (state: any) => state?.userChoicesReducer?.caseAssetAddress
  );

  const customerAssociatedAssets = useSelector(
    (state: any) => state?.addresses?.customerassociatedassets[0]
  );

  const tenancy = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );

  const postCode = (() => {
    let code = "";
    if (customerAssociatedAssets && customerAssociatedAssets.length > 0) {
      for (const asset of customerAssociatedAssets) {
        if (asset.assetType === tenancy && asset.addresses && asset.addresses.postalAddresses.length > 0) {
          code = asset.addresses.postalAddresses[0].postcode;
          break;
        }
      }
    }
    return code;
  })();



  const address = caseAddress? caseAddress: selectedPropertyAddress;

  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const contactInformationValue = useSelector(
    (state: any) => state.caseCreationReducer?.contactInformationValue
  );
  //  ==== Converting selected answer id into value ====
  
  const caseTenancyID = useSelector(
    (state: any) => state?.caseCreationReducer?.caseTenancyId
  );
  const isCaseMediaAllowed = useSelector(
    (state:any) => state?.userChoicesReducer?.isCaseMediaAllowed
  );
  const userChosenUploadedMediaIdentifiers = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenUploadedMediaIdentifiers
  );
  AnsValueArr = [...selectedAnswers];

  filterQuestionsSelected?.map((q: any, index: number) => {
    if (q.label === COVID_CASE_QUESTION && q.isEnabled === "true") {
      isCovidInFlow = true;
    }
    if (q.label === CCTV_Question1 && q.isEnabled === "true") {
      isCCTV = true;
      caseCategory = caseCategoryAdditionalFields.find((x:any) => x.fieldName == CRMCategoryProperty)?.attribute;
    }
    q?.options?.map((data: any) => {
      if (data.id === selectedAnswers[index]) {
        return (AnsValueArr[index] = data.value);
      }
    });
  });

  let storeIndex: any = [];

  selectedAnswers.map((data: any, index: number) => {
    storeIndex.push(index);
  });

  storeIndex.map((_: any, index: number) => {
    if (index !== storeIndex[index]) {
      selectedAnswers[index] = undefined;
    }
  });

  if (
    caseCategoryAdditionalFields &&
    caseCategoryAdditionalFields[0]?.valueFrom && !isCCTV
  ) {
    caseCategory = AnsValueArr[0];
  }
  
  let StaticCategory = caseCategoryAdditionalFields.find((x:any) => x.fieldName == CRMCategoryProperty)?.attribute;

  const callCaseDroolAPIS = async () => {
    setIsLoading(true);
    const droolRequest = new CaseDrool();
    if(caseSubType == 'Rehousing and Lettings'){
      droolRequest.caseSubType = 'Housing Options'
    }
    else{
    droolRequest.caseSubType = caseSubType;
    }
    droolRequest.caseSubTypeCategory = caseSubTypeCategory;
    droolRequest.caseCategory = caseCategory || caseSubTypeCategory;
    droolRequest.landLord = landlordName;
    droolRequest.tenancyType = caseTenancyType ? caseTenancyType : tenancyType;
    droolRequest.postalCode = postCode ? postCode.split(" ")[0] : "";
    droolRequest.channel = "ColleagueSelfService";
    const teamDeterminationKey = "com.myspace.pb_team_determination.ActionItem";
    try {
      const response = await api.caseDroolAPI(droolRequest);
      if (
        response?.value?.[teamDeterminationKey]
          ?.owningTeam != null
      ) {
        setAssigneeName(
          response?.value?.[teamDeterminationKey]
            ?.owningTeam
        );
        setAssigneeType(
          response?.value?.[teamDeterminationKey]
            ?.owningTeamCategory
        );
      } else {
        props.history.push("/genericerror");
        dispatch({ type: CLEAR_CASE_STATE });
        dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    } finally {
      setIsLoading(false);
    }
  };

  const removeUndefinedResponses = () =>{
    do {
      selectedAnswers.filter((data: any, index: number) => {
        if (data == undefined) {
          selectedAnswers.splice(index, 1);
          filterQuestionsSelected.splice(index, 1);
        }
      });
    } while (selectedAnswers.includes(undefined));
  }

  const fetchGetCaseAssetAssociateData = async () => {
    try {
      setIsLoading(true);
        const response = await api.getCaseAssetAssociate(assetDetails?.id);
      if (response) {
        dispatch({ type: CASE_ELIGIBILITY_RESPONSE, payload: response });
      }
      landlordName =
        response?.associates?.landLord?.organisation?.name || "UNKNOWN";
      if (landlordName) {
        callCaseDroolAPIS();
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    }
  };

  let timerId: any;
  useEffect(() => {
    window.scrollTo(0,0);
    fetchGetCaseAssetAssociateData();
    dispatch({ type: CASE_EDIT, payload: false });
    if(userTenancy.length === userTenancyCount.Single){
      setIsSingleTenancy(true);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const clearCaseDataFromRedux = () => {
    dispatch({ type: CLEAR_CASE_STATE });
  };

  const backButtonHandler = () => {
    if(backValue == BackValue.Asset360View && contactInformationValue == t("Contact4")){
      props.history.push("/CaseContactInformation")
    }
    else {
      dispatch({ type: CASE_COUNTER, payload: questionInfoData?.length - 1 });
      props.history.push("/ContactDetails");
    }
  };

  const onExitHandler = () => {
    clearCaseDataFromRedux();
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else if(backValue == BackValue.WorkOrder360View) {
      props.history.push("/WorkOrder360View");
    }else{
      props.history.push("/Case360View");
    }
  };

  const getAssetDetails = () => {
    let assetTypeID;
    const assetTypeName = assetType;
    switch (assetTypeName) {
          case AssetTypeName.Block:
              assetTypeID = AssetTypeId.Block;
              break;
          case AssetTypeName.Property:
              assetTypeID = AssetTypeId.Property;
              break;
          case AssetTypeName.Estate:
              assetTypeID = AssetTypeId.Estate;
              break;
          case AssetTypeName.EstateArea:
              assetTypeID = AssetTypeId.EstateArea;
              break;
          case AssetTypeName.DividedBlock:
              assetTypeID = AssetTypeId.DividedBlock;
              break;
          case AssetTypeName.Scheme:
              assetTypeID = AssetTypeId.Scheme;
              break;
      }
    return [assetTypeID, assetTypeName];
  }

  const onContinueHandler = async () => {
    setIsLoading(true);
    const [assetID, assetName] = getAssetDetails();
    const caseRequest = new CaseData();
    caseRequest.caseTypeId = parseInt(caseTypeId);
    caseRequest.caseTypeDescription = caseTypeDescription;
    caseRequest.asset = {
      type: {
        assetTypeId: assetID,
        assetTypeName: assetName,
      },
    };

    //add desc in case of covid
    caseRequest.caseTypeDescription =
      isCovidInFlow == true ? caseTypeDescription : caseTypeDescription;
    caseRequest.assignee = assigneeName;
    caseRequest.assigneeType = assigneeType;
    caseRequest.identifiers = {
      assetIdentifier: assetDetails?.id,
      customerIdentifier: customerIdentifier || null,
      tenancyIdentifier: caseTenancyID? caseTenancyID: !isNullOrUndefined(tenancyIdentifier)? tenancyIdentifier : null,
      documentIdentifiers: userChosenUploadedMediaIdentifiers
    };
    caseRequest.contactDetails = {
      phoneNumber: userChosenContactPhone || null,
      name: userChosenContactName || null,
      relationId: parseInt(userChosenRelationId) || null,
      relation: userChosenRelationDetails || null,
    };
    // Removing blank value from question and answers
    removeUndefinedResponses();
   
    caseRequest.additionalInfo = selectedAnswers?.map(
      (ans: any, index: number) => {
        return {
          questionId: filterQuestionsSelected[index]?.questionId,
          responseId:
            filterQuestionsSelected[index]?.type === "Radio" ||
              filterQuestionsSelected[index]?.type === "List"
              ? parseInt(ans)
              : null,

          responseText:
            filterQuestionsSelected[index]?.type === "DateTime" ||
              filterQuestionsSelected[index]?.type === "Text"
              ? ans
              : null,
        };
      }
    );

    try {
      timerId = setTimeout(function () {
        setShowError("Case_CC022");
      }, 1000);

      const response = await api.createCaseData(caseRequest);
      dispatch({ type: CASE_IDENTIFIER, payload: response?.caseIdentifier });
      dispatch({ type: CASE_OWNER, payload: assigneeName });
      setIsLoading(true)
      timerId = setTimeout(function () {
       props.history.push("/casefinalconfirmation");
      }, 8000);
     
    } catch (e) {
      setIsLoading(false)
      setRedAlert("Case_CC023");
    } finally {
      setShowError("");
      //dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    }
  };

  const displayCustomerContactDetails = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("Case_Customer_Details_Text_Two")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base div-word-break padding-new color_black global-txt"
            >
              {userChosenContactPhone} <br></br>{userChosenContactName}<br></br>
              {userChosenRelationDetails}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="contact-details"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  props.history.push("/ContactDetails");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const displayCaseType = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("Case_Type_Title")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            >
              {caseTypeDescription}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="caseType"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({ type: CASE_COUNTER, payload: 0 });
                  props.history.push("/CaseType");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const displaySelectedProperty = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("Case_Property_msg")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {address}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            {!isSingleTenancy &&  (backValue == BackValue.Customer360View) &&
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/selectTenancy");
                }}
              ></i>
            </div>}
          </TableCell>
        </TableRow>
      </>
    );
  };
  const displaySelectedAssetType = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("OPC033")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {assetType}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/CaseAsset");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const displaySelectedCustomer = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("OPC003b")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {caseCustomerName}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/SelectCustomer");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const displayContactPage = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("OPC035")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {contactInformationValue}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/CaseContactInformation");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const displayCaseSelectedCustomer = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("selectedCustomer")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {caseCustomerName}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/CaseSelectCustomer");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const displayMediaPage = () => {
    return (
      <>
        <TableRow
          id={"confirmation-tr1"}
          className="flex items-center bg-white"
        >
          <TableCell
            id={"confirmation-tr1-td1"}
            className="w-5/12 p-3 firstcol"
          >
            <div
              id={"confirmation-tr1-div1"}
              className="text-base padding-new color_black global-txt"
            >
              {t("OPC062")}
            </div>
          </TableCell>
          <TableCell
            id={"confirmation-tr1-td2"}
            className="w-5/12 p-3 secondcol"
          >
            <div
              id={"confirmation-tr1-div2"}
              className="text-base padding-new color_black global-txt"
            > 
              {userChosenUploadedMediaIdentifiers.length>0 ? t("Yes") : t("No")}
            </div>
          </TableCell>

          <TableCell
            id={"confirmation-tr1-td3"}
            className="w-5/12 p-3 thirdcol paddright text-center"
          >
            
            <div id={"confirmation-tr1-div3"} className="edt-spc div-left padding-new text-center">
              <i
                data-testid="tenancy"
                id={"confirmation-tr1-icon"}
                className="fas fa-edit text-teal text-lg"
                onClick={() => {
                  dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                  props.history.push("/UploadMedia");
                }}
              ></i>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <div className="container sm:mx-1 ctn-fht casejourney-container" id="case-container" data-testid="case-container" role="main">
    
      {/* <div className="container"> */}
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />
        }
      >
        {isLoading ? (
          <>
            <Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />
            {showError && (
              <div
                data-testid="alert-warning"
                className="error mb-8"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      {" "}
                      {t(showError)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>

            <div className="mb-16">
              <div id="confirmation-main-div" className="global-txt">
                <h2
                  data-testid="case-title"
                  className="text-purple-100 text-2xl mb-2"
                >
                  {t("Case_Review_Title")}
                </h2>
                <p data-testid="case-title-t1" className="mb-4 color_black"></p>
                <p data-testid="case-title-t2" className="mb-4 color_black">
                  {t("Case_Details_Text2")}
                </p>
              </div>

              <div className="repair-text " id="confirmation-div-table">
                <ReactBootstrap.Table striped className="container-new">
                  <TableBody role="presentation"
                    id="confirmation-table"
                    className="w-full border border-grey"
                  > <th className="hidden">{t("table_heading")}</th>
                    {displaySelectedProperty()}
                    {(!isNullOrUndefined(caseCustomerName) &&assetType == t("property")) ? displaySelectedCustomer(): null}
                    {caseAssetCheck? displaySelectedAssetType(): null}
                    {displayCaseType()}
                    {filterQuestionsSelected
                      .slice(0, selectedAnswers.length)
                      .map((q: any, id: number) => {
                        return (
                          <>
                            <TableRow
                              key={id}
                              id={"confirmation-tr1"}
                              className={
                                AnsValueArr[id] === undefined ||
                                  AnsValueArr[id] === null
                                  ? "hideQuestion"
                                  : "flex items-center bg-white"
                              }
                            >
                              <TableCell
                                id={"confirmation-tr1-td1"}
                                className="w-5/12 p-3 firstcol"
                              >
                                <div
                                  id={"confirmation-tr1-div1"}
                                  className="text-base padding-new color_black global-txt"
                                >
                                  {q.label == COVID_CASE_QUESTION ? t("Case_Covid_CC007"): q.label}
                                </div>
                              </TableCell>
                              <TableCell
                                id={"confirmation-tr1-td2"}
                                className="w-5/12 p-3 secondcol"
                              >
                                <div
                                  id={"confirmation-tr1-div2"}
                                  className="text-base padding-new color_black div-wrap global-txt"
                                >
                                  {AnsValueArr[id]}
                                </div>
                              </TableCell>

                              <TableCell
                                id={"confirmation-tr1-td3"}
                                className="w-5/12 p-3 thirdcol paddright"
                              >
                                <div
                                  id={"confirmation-tr1-div3"}
                                  className="edt-spc div-left padding-new text-center"
                                >
                                  <i
                                    data-testid="question"
                                    id={"confirmation-tr1-icon"}
                                    className="fas fa-edit text-teal text-lg"
                                    onClick={() => {
                                      dispatch({type: EDIT_BUTTON_CLICK, payload: true})
                                      // props.history.push("caseQuestionnaire");
                                      props.history.push({
                                        pathname: "/CaseQuestionnaire",
                                        state: { id: `${id}` },
                                      });
                                    }}
                                  ></i>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                   {(isCaseMedia == 'true' && isCaseMediaAllowed) && displayMediaPage()}
                    {!isNullOrUndefined(contactInformationValue)? displayContactPage(): null}
                    {contactInformationValue == t("Contact1") ? displayCaseSelectedCustomer(): null}
                    {/* === Display contact details ===      */}
                    {((backValue == BackValue.Customer360View)|| ((backValue == BackValue.Asset360View || backValue == BackValue.Case360View)  && (isNullOrUndefined(contactInformationValue) ||contactInformationValue == t("Contact1") ||contactInformationValue == t("Contact2") ||contactInformationValue == t("Contact3"))))? displayCustomerContactDetails() : null}
                  </TableBody>
                </ReactBootstrap.Table>
              </div>
            </div>

            {redAlert && (
              <div
                data-testid="alert-warning"
                className="warning mb-8 unblockingMessage"
                role="alert"
                placeholder="alertNo"
              >
                <div className="w-full px-2 py-2 bg-red-20 flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
                  </div>
                  <div className="flex items-center text-black">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t(redAlert)}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div
              className="flex justify-between items-center pb-4 md:pb-8"
              id="case-exit-btn"
            >
              <ExitModalContent
                id="repairproperty-exitmodal"
                exit={onExitHandler}
                btn_msg="return_case_button"
              />
              <Button
                onClick={onContinueHandler}
                data-testid="continue"
                className="pad-bot"
                disabled={false}
                id="btn_caseproperty"
              >
                {t("continue")}{" "}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
          </div>
        )}
      </Suspense>
      </div>
  );
};

export default CaseReview;
