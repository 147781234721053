import React,{ useState } from "react";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import Modal from ".";

const ExitModalContent: React.FC<ModalProps> = ({
  exit,
  contentMessage,
  btn_msg,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const id = "id";
  const clearUserStates = () => {
    exit();
    setIsOpen(false)
  };

  return (
    <>
      <div>
        {t(contentMessage) == t("Remove_Attachment_Text") ? (
          <a onClick={() => setIsOpen(true)} data-testid="Delete_Button">
            <i className="deleteBtn">X</i>
          </a>
        ) : (
          <a
            id={id}
            aria-label="Exit"
            className="text-teal exit-btn a-style cursor-pointer appGreenColor"
            onClick={() => setIsOpen(true)}
          >
            {t("exit_button")}
          </a>
        )}
      </div>
      <Modal open={isOpen} cssClasses="modalPopUp">
        <div id="exitmodal-div1" className="modalPopUpContainer container my-1">
          <div
            id="exitmodal-div2"
            className="py-lg-0 py-sm-0 h5 my-5 question-font-size text-center global-txt"
          >
            {contentMessage ? t(contentMessage) : t("CPR002")}
          </div>
          <div
            id="exitmodal-div3"
            className="modalButtonContainer 	
button-positions flex flex-wrap text-center pad-bot pt-lg-0"
          >
            <Button
              id="exitmodal-btn2"
              className="button-class SMS-class"
              onClick={() => setIsOpen(false)}
            >
              {btn_msg ? t(btn_msg) : t("return_repair_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
            <Button
              id="exitmodal-btn3"
              onClick={clearUserStates}
              className="button-class SMS-class"
            >
              {t(contentMessage) == t("Remove_Attachment_Text") ?t("Remove_Attachment"): t("leave_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExitModalContent;
