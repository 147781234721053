import {
  QUERY_POSTCODE,
  SAVE_TENANCIES,
  SAVE_ASSOCIATED_ASSETS,  
  SAVE_SELECTED_TENANCICY,
  SAVE_CUSTOMER_ID,
  APP_CONFIG_VALUE,
  SAVE_ASSET_ADDRESS,
  SAVE_IS_ELEGIBILITY_REPAIR,
  SAVE_DEFAULT_ADDRESS,
  WORK_ORDER_REPAIR,
  CUSTOMER_DETAILS,
  SAVE_CUSTOMER_TENANCIES,
  APP_CONFIG_COVID_VALUE,
  REPAIR_HISTORY_DATA,
  SAVE_CUSTOMERS_PHONE_NUMBERS,
  SAVE_CUSTOMERID,
  SAVE_CONTRACTOR_NAME,
  SAVE_MAINTRESP,
  SAVE_REPRESP,
  SAVE_HEATINGCASE,
  SAVE_DETERMINED_CONTRCACTOR_DETAILS,
  SAVE_CONTRCACTOR_PAYLOAD,
  SAVE_AVAILABILITY_RESPONSE,
  SESSION_TIME_OUT_MESSAGE,
  SAVE_REFERENCE_NUMBER,
  SAVE_PREFERENCE_NUMBER,
  APP_CONFIG_VANDALISM_VALUE,
  SAVE_TENANCY_SUMMARY_LIST,
  ASSET_DATA,
  SAVE_CONTRCACTOR_INTEGERATED,
  SAVE_AVAILABILITY_METADATA_RESPONSE,
  SAVE_EXTERNAL_WORKORDER_CONTRACTORS,
  SAVE_SUPPLIMENTARY_CODES
} from "../actionTypes";

export const assetData = (assetData: any): any => {
  return { type: ASSET_DATA, payload: assetData };
};

export default function getAddress(address: any): any {
  return { type: QUERY_POSTCODE, address };
}

export const saveCustomerTenaciesResponse = (
  customerTenaciesResponse: any
): any => {
  return { type: SAVE_CUSTOMER_TENANCIES, payload: customerTenaciesResponse };
};

export const appConfigCovidToggle = (covidtoggleValue: any): any => {
  return { type: APP_CONFIG_COVID_VALUE, payload: covidtoggleValue };
};

export const saveCustomerTenacies = (customertenancies: any): any => {
  return { type: SAVE_TENANCIES, payload: customertenancies };
};

export const saveAssociatedAssests = (associatedAssets: any): any => {
  return { type: SAVE_ASSOCIATED_ASSETS, payload: associatedAssets };
};

export const saveTenancySummaryList = (tenancySummaryList: any): any => {
  return { type: SAVE_TENANCY_SUMMARY_LIST, payload: tenancySummaryList };
};

export const saveAssestAddresses = (assetAddress: any): any => {
  return { type: SAVE_ASSET_ADDRESS, payload: assetAddress };
};

export const saveAvailabilityResponse = (
  contractorAvailabilityResponse: any
): any => {
  return { type: SAVE_AVAILABILITY_RESPONSE, payload: contractorAvailabilityResponse };
};

export const selectedTenacyType = (selectedTenacyType: any): any => {
  return { type: SAVE_SELECTED_TENANCICY, payload: selectedTenacyType };
};

export const saveCustomerId = (customerid: any): any => {
  return { type: SAVE_CUSTOMER_ID, payload: customerid };
};

export const appConfigToggle = (toggleValue: any): any => {
  return { type: APP_CONFIG_VALUE, payload: toggleValue };
};

export const saveIsEligibilityRepair = (isEligibilityRepair: any): any => {
  return { type: SAVE_IS_ELEGIBILITY_REPAIR, payload: isEligibilityRepair };
};

export const saveDefaultAddress = (defaultaddresss: any): any => {
  return { type: SAVE_DEFAULT_ADDRESS, payload: defaultaddresss };
};
export const workOrderRepairObject = (workOrderRepair: any): any => {
  return { type: WORK_ORDER_REPAIR, payload: workOrderRepair };
};

export const saveCustomerDetails = (customerDetails: any): any => {
  return { type: CUSTOMER_DETAILS, payload: customerDetails };
};
export const saveCustomerphoneNumber = (payload: any): any => {
  return { type: SAVE_CUSTOMERS_PHONE_NUMBERS, payload };
};

export const getCustomerId = (custmerID: any): any => {
  return { type: SAVE_CUSTOMERID, payload: custmerID };
};

export const getContractorName = (contractorname: any): any => {
  return { type: SAVE_CONTRACTOR_NAME, payload: contractorname };
};

export const getMaintResp = (maintresp: any): any => {
  return { type: SAVE_MAINTRESP, payload: maintresp };
};
export const getRepairResp = (repairresp: any): any => {
  return { type: SAVE_REPRESP, payload: repairresp };
};

export const getHeatingHotWaterCase = (heatinghotwatercase: any): any => {
  return { type: SAVE_HEATINGCASE, payload: heatinghotwatercase };
};

export const getDeterminedContractorDetails = (payload: any) => {
  return { type: SAVE_DETERMINED_CONTRCACTOR_DETAILS, payload };
};

export const getContractorPayload = (payload: any) => {
  return { type: SAVE_CONTRCACTOR_PAYLOAD, payload };
};

export const showSessionTimeOutMessage = (payload: any) => {
  return { type: SESSION_TIME_OUT_MESSAGE, payload };
};
export const getReferenceNumber = (referenceno: any): any => {
  return { type: SAVE_REFERENCE_NUMBER, payload: referenceno };
};
export const getPrefferenceNumber = (preferenceno: any): any => {
  return { type: SAVE_PREFERENCE_NUMBER, payload: preferenceno };
};

export const getIntegeratedContractor = (payload: any) => {
  return { type: SAVE_CONTRCACTOR_INTEGERATED, payload };
};

export const repairHistoryData = (payload: any) => {
  return { type: REPAIR_HISTORY_DATA, payload };
};
export const saveSupplimentaryCodes = (supplimentaryCodes: any): any => {
  return { type: SAVE_SUPPLIMENTARY_CODES, payload: supplimentaryCodes };
};