import moment from 'moment'
import { PremisesAddress } from '../../models/shared/Address/PremisesAddress'
import Address from '../../models/Customer/Address'
import PostalAddress from '../../models/shared/Address/PostalAddress'
import { CaseOwnerMatrix } from '../../models/Case/CaseOwnerMatrix'
import { CustomerOrProperty } from '../../models/shared/Enum'
import { ContractorRepairPriority, RepairPriority } from "../../models/Repair/CreateRepair";

// Dates received in the format 1952-03-15T00:00:00Z
export const formatDate = (date: string | null): string => {
  if (!date) return 'None'
  return date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')
}

export const isWithinSixMonths = (date: string): boolean => {
  const today = new Date()
  return moment(new Date(today)).diff(new Date(date), 'months', true) < 6
}

export const formatDateWithMonthInLetters = (dateToFormat: string | null): string => {
  if (!dateToFormat) return '-'
  const date = moment(dateToFormat).format('DD MMM YYYY')
  return `${date}`
}

export const isNullOrUndefined = (obj: any): boolean => {
  let result = false;
  if (obj === undefined || obj === null || obj === '' || obj === "") {
    result = true;
  }
  return result;
}

export function titleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function capitalizeName(name: string | undefined | null): string {
  if (!name) {
    return '';
  }

  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const formatDateWithFullMonthInLetters = (dateToFormat: string | null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('DD MMMM YYYY')
  return `${date}`
}

// Dates received in the format 1952-03-15T00:00:00Z
export const formatDateWithTime = (date: string, date2?: string): string => {
  if (!date && !date2) {
    return '-'
  }

  /*
  // TODO: Once we have tests, see if we can refactor into the following
  const time1 = moment.utc(moment(date)).format('DD-MM-YYYY, h.mma')
  const time2 = moment.utc(moment(date2)).format('-h.mma')
  return time2 ? `${time1}${time2}` : time1
  */

  const mmnt = moment(date)

  const d = mmnt.format('DD-MM-YYYY')
  const h = mmnt.hour()
  const m = mmnt.minute()

  let hour = h
  const minute = doubleDigit(m)
  let periodOfDay = 'am'

  if (hour >= 12) {
    hour = hour - 12
    periodOfDay = 'pm'
  }

  let formattedNewDate: string = ''
  if (!date2) {
    formattedNewDate = `${d}, ${hour}.${minute}${periodOfDay}`
  } else {
    let h2 = moment(date2).hour()
    const m2 = moment(date2).minute()
    let periodOfDay2 = 'am'
    if (h2 >= 12) {
      h2 = h2 - 12
      periodOfDay2 = 'pm'
    }
    formattedNewDate = `${d}, ${hour}.${minute}${periodOfDay}-${h2}.${m2}${periodOfDay2}`
  }

  return formattedNewDate
}

export const doubleDigit = (value: number): number | string => {
  const newValue = value >= 10 ? value : `0${value}`
  return newValue
}

export function addressToString(address: PremisesAddress | null): string {

  if (!address) return ''
  const { addressLine1, addressLine2, addressLine3, postcode } = address

  const addressLine4 = (address as PremisesAddress).addressLine4 || ''
  const addressLine5 = (address as PremisesAddress).addressLine5 || ''

  const allLines = [
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    postcode,
  ]

  const addField = (input: string, field: string | null) => {
    if (!field) return input
    if (!input) return field
    return `${input}, ${field}`
  }

  return (
    allLines.reduce((addr: string, line) => {
      return addField(addr, line || null)
    }, '')
  )
}
export function addressToStringDetails(address: Address | PostalAddress | null): string {
  if (!address) return ''
  const { addressLine1, addressLine2, addressLine3, postcode } = address

  const addressLine4 = (address as Address).addressLine4 || ''
  const addressLine5 = (address as Address).addressLine5 || ''
  const formattedAddress = (address as Address).formattedAddress || ''
  const town = (address as PostalAddress).town || ''

  if (formattedAddress) return formattedAddress

  const allLines = [
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    town,
    postcode,
  ]

  const addField = (input: string, field: string | null) => {
    if (!field) return input
    if (!input) return field
    return `${input}, ${field}`
  }

  return (
    formattedAddress ||
    allLines.reduce((addr: string, line) => {
      return addField(addr, line)
    }, '')
  )
}

export function csvToMultiline(s: string): string {
  if (!s || typeof s !== 'string') return s

  return s
    .split(',')
    .map(str => str.trim())
    .join('\n')
}

export const stringToHTML = (s: string) => s.split(',')

export function valueOrPlaceholder(val: string | null) {
  const EMPTY_PLACEHOLDER = '-'
  return val || EMPTY_PLACEHOLDER
}

export const priorityType = (text: any) => {
  if (text === undefined || text === null) return ''

  const priority = text?.includes('(') ? text?.slice(0, text.indexOf('(')) : text
  return priority
}

export const bracketMessage = (text: any) => {
  if (text === undefined || text === null) return ''

  const messageInBracket = text?.includes('(')
    ? text?.slice(text.indexOf('('), text.indexOf(')') + 1)
    : ''
  return messageInBracket
}

export const textPreview = (text: string) => {
  const textLimit = 30
  const previewText = text.length > textLimit ? `${text.substring(0, textLimit)}...` : text

  return previewText
}

export const formatDateWithFromCalendar = (date: string | ''): string => {
  if (!date) {
    return '-'
  }

  // TODO - Adding one day as calendar's date is not working well with moment.js
  const calendarDate = moment(date).format('DD-MM-YYYY')

  return calendarDate
}

export const formatDateWithFullMonthWeekInLetters = (dateToFormat: string | null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('dddd DD MMMM YYYY')
  return `${date}`
}

export const dateToHour = (date: string): string => date.split('T')[1]?.split(':')[0]

export const formatDateTime = (dateToFormat: string | null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('YYYY-MM-DD')
  return `${date}`
}

export const formatCustomerEditDateTime = (dateToFormat: string | null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('YYYY/MM/DD')
  return `${date}`
}
export const formathourminutes = () => {
  var date = new Date();
  let hours: any;
  let minutes: any;
  if ((date.getMinutes() > date.getUTCMinutes()) && (date.getHours() > date.getUTCHours())) {
    hours = (date.getHours() - date.getUTCHours())
    minutes = (date.getMinutes() - date.getUTCMinutes())
  }
  else if ((date.getUTCMinutes() > date.getMinutes()) && (date.getHours() > date.getUTCHours())) {
    hours = (date.getHours() - date.getUTCHours()) - 1
    minutes = 60 + date.getMinutes() - date.getUTCMinutes();
  }
  else if ((date.getMinutes() > date.getUTCMinutes()) && (date.getUTCHours() > date.getHours())) {
    hours = date.getHours() + (24 - date.getUTCHours())
    minutes = date.getMinutes() - date.getUTCMinutes()
  }
  else if ((date.getUTCHours() > date.getHours()) && (date.getUTCMinutes() > date.getMinutes())) {
    hours = date.getHours() + (24 - date.getUTCHours())
    minutes = 60 + date.getMinutes() - date.getUTCMinutes();
  }
  else if ((date.getHours() > date.getUTCHours() && (date.getMinutes() == date.getUTCMinutes()))) {
    hours = date.getHours() - date.getUTCHours()
    minutes = 0;
  }
  else {
    hours = 0;
    minutes = 0;
  }
  let raisedSinceMinutes: any;
  if (minutes > 10)
    raisedSinceMinutes = minutes;
  else
    raisedSinceMinutes = '0' + minutes;
  let raisedUptoMinutes: any;
  if ((59 - minutes) > 10)
    raisedUptoMinutes = 59 - minutes
  else
    raisedUptoMinutes = '0' + minutes
  const time = [
    hours,
    raisedSinceMinutes,
    raisedUptoMinutes
  ]
  return time;
}

export const formatDateWithSlashFromCalendar = (date: string | ''): string => {
  if (!date) {
    return '-'
  }

  // TODO - Adding one day as calendar's date is not working well with moment.js
  const calendarDate = moment(date).format('DD/MM/YYYY')

  return calendarDate
}

export const formatContent = (content: string | '' | null | undefined): string => {
  if (!content) {
    return '-'
  }
  return content;
}

export const formatCustomerName = (content: string | '' | null | undefined): string => {
  if (!content) {
    return 'Peabody Contact'
  }
  return content;
}

export const formatDateTimeSlash = (dateToFormat: string | null): string => {
  if (!dateToFormat) return 'None'
  const date = moment(dateToFormat).format('DD/MMM/YYYY')
  return `${date}`
}

export const getAppointmentDateFormattedComment = (item: any) => {
  const appointmentStartDate =
    item.appointmentStartDatetime
      ? moment(
        item.appointmentStartDatetime
      ).format("LL")
      : "";
  const appointmentStartTime =
    item.appointmentStartDatetime
      ? moment(
        item.appointmentStartDatetime
      ).format("LT")
      : "";
  const appointmentEndTime =
    item.appointmentEndDatetime
      ? moment(
        item.appointmentEndDatetime
      ).format("LT")
      : "";
  return appointmentStartDate + " between " + appointmentStartTime + " and " + appointmentEndTime;
}


export const mappedCaseOwnerName = new Map();
mappedCaseOwnerName.set('Estate Services Enquiries', CaseOwnerMatrix["Estate Services Enquiries"])
  .set('Homeowner Sales Services', CaseOwnerMatrix["Homeowner Sales Services"])
  .set('NM', CaseOwnerMatrix["NM"])
  .set('Homeownership Team', CaseOwnerMatrix["Homeownership Team"])
  .set('Incomes officer', CaseOwnerMatrix["Incomes officer"])
  .set('Leasehold Compliance Team', CaseOwnerMatrix["Leasehold Compliance Team"])
  .set('Leasehold Team', CaseOwnerMatrix["Leasehold Team"])
  .set('New Homes (Development)', CaseOwnerMatrix["New Homes (Development)"])
  .set('Private Rent Team', CaseOwnerMatrix["Private Rent Team"])
  .set('Property Accounts', CaseOwnerMatrix["Property Accounts"])
  .set('Rehousing Services', CaseOwnerMatrix["Rehousing Services"])
  .set('Rehousing and Lettings', CaseOwnerMatrix["Rehousing and Lettings"])
  .set('Resale And Staircasing Team', CaseOwnerMatrix["Resale And Staircasing Team"])
  .set('SAR Team', CaseOwnerMatrix["SAR Team"])
  .set('Specialist Case Management', CaseOwnerMatrix["Specialist Case Management"])
  .set('Allocations and Lettings', CaseOwnerMatrix["Allocations and Lettings"])
  .set('Business Support', CaseOwnerMatrix["Business Support"])
  .set('Property Accounts Team', CaseOwnerMatrix["Property Accounts Team"])
  .set('MyPeabody Hub Complaints', CaseOwnerMatrix["MyPeabody Hub Complaints"])
  .set('Env Services South', CaseOwnerMatrix["Env Services South"]);


export const removeTagsFromContent = (str: any) => {
  if ((str === null) || (str === '') || (str === undefined))
    return '-';
  else
    str = str.toString();
  return str.replace(/<[^>]*>/g, '\r\n');
}

export   const retrievePriority = (
  isEmergency: boolean | undefined,
  isOutOfHours: boolean | undefined,
  isRecall: boolean | null | undefined,
  priorityCode: string | undefined,
) => {
  let priority = "";
  if (isRecall) {
    priority = "Next Available (Recall)";
  } else if (priorityCode === ContractorRepairPriority.DampAndMould) {
    priority = RepairPriority.DM14Days;
  } else {
    priority = isEmergency
      ? isOutOfHours
        ? "Emergency (Out of hours)"
        : "Emergency"
      : "Next Available";
  }
  return priority;
};

export const getUpdateOwnerName = (ownerName: any) => {
  let updatedOwnerName = '';
  if (!isNullOrUndefined(ownerName)) {
    const mappedOwnerName = mappedCaseOwnerName.get(ownerName);
    updatedOwnerName = !isNullOrUndefined(mappedOwnerName) ? mappedOwnerName : ownerName;
  }
  return titleCase(formatContent(updatedOwnerName.replace("Patchless South 2", "Neighbourhood Manager Team")));
}
export const getDeepLink = (type: CustomerOrProperty, id: string) => {
  const params = {
    [CustomerOrProperty.Customer]: {
      etn: 'contact',
      formId: '9dc196be-ce7d-45b7-925c-38469494955f',
    },
    [CustomerOrProperty.Property]: {
      etn: 'pea_cdm_premisesaddress',
      formId: 'b25ba958-93e8-4a25-946c-8d860648e897',
    },
  }

  const baseUrl = process.env.REACT_APP_VANTAGE_URL ?? ''
  console.log("baseUrl-", baseUrl);
  const url = baseUrl.replace('{etn}', params[type].etn)
    .replace('{formId}', params[type].formId)
    .replace('{id}', id)

  return url
}
export { CustomerOrProperty }

export const getRepairPriority = async (contractorRepairPriority: string) => {
  const repairPriority =
    contractorRepairPriority === ContractorRepairPriority.Emergency ? RepairPriority.Emergency
      : (contractorRepairPriority === ContractorRepairPriority.NextAvailable
        || contractorRepairPriority == ContractorRepairPriority.DampAndMould
      ) ? RepairPriority.NextAvailable : null;

  return repairPriority;
}