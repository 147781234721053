import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import * as api from "../../api";
import { useDispatch, useSelector } from "react-redux";
import Customer from "../../models/Customer/Customer";
import { PremisesAddress } from "../../models/shared/Address/PremisesAddress";
import { addressToString, formatDateWithFromCalendar } from "../../utils/formatting";
import { CustomerSecurityProfile } from "../../models/Customer/CustomerSecurityProfile";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { PermissionsList } from "../../models/Permissions";
import './index.css';
import customertImg from '../../assets/icons/customer.png';
import '../../utils/commonCss/icons.css'
import { clearReferralInformation } from "../../ducks/redux/actions/FinancialReferral";

interface CustomerCardProps extends RouteComponentProps<any> {
}
const CustomerCard: React.FC<CustomerCardProps> = (props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const customerID = useSelector((state: any) => state?.userChoicesReducer?.custommerID);
  const customerData = useSelector(
    (state: any) => state?.userChoicesReducer?.customersData
  );
  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const [customerContact, setCustomerContact] = useState<Customer>();
  const [isCustInfo, setIsCustInfo] = useState(false);
  const[isDateOfBirth, setIsDateOfBirth] = useState(false);
  const [referralToggle, setReferralToggle] = useState("true")
  const [dropDown, setDropDown] = useState("none");
  const [referralLink, setReferralLink] = useState(true)
  const [userName, setUserName]  = useState('')
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  const catalystCustomer = useSelector(
    (state: any) => state?.userChoicesReducer?.landlordCheck
  );

  useEffect(()=>{
    (async () => {
        try {
            const ReferralToggle = await api.GetAppConfigToggleValue("IsP360CatalystFinancialReferral");
            setReferralToggle(ReferralToggle.value)
        }catch(e:any){
            props.history.push("/GenericError");   
        }
            
    })()

    const showReferral = getPermissionsData?.indexOf(
      PermissionsList.create_customer360_financial_inclusion_referral
    );
    if (showReferral !== -1) {
      setReferralLink(true);
    } else {
      setReferralLink(false);
    }

},[]);
  
  useEffect(() => {
    (async () => {
      trackEvent("CustomerCard");
      const showDateOfBirth = getPermissionsData?.indexOf(PermissionsList.view_customer360_customer_dob);
        if (showDateOfBirth != -1) {
          setIsDateOfBirth(true)
        }
        else {
          setIsDateOfBirth(false)
        }
      try {
        const customerResponse :CustomerResponseDetails= customerData;
        const customerContactParamsEntry: Customer = new Customer();
        try {
          const payload : CustomerSecurityProfile = await api.getCustomerSecurityProfile(customerID)

            if (payload?.userId != null) {
              customerContactParamsEntry.peabodyRegistration = "Yes"
              setUserName(payload?.userName)
            }
          }
          catch (e:any) {
             if (e?.status === 404) {
              customerContactParamsEntry.peabodyRegistration = "No"
            }
            else {
              customerContactParamsEntry.peabodyRegistration = "Unknown"
            }
        }
            customerContactParamsEntry.dateOfBirth = customerResponse?.person?.dateOfBirth || ""
            customerContactParamsEntry.customerType = customerResponse?.statuses?.find(
              (a) => a.type === "isCustomerActive"
            )?.value === true ? t("active_txt") : t("former_txt") || "";
            customerContactParamsEntry.workPhone =
              customerResponse?.addresses?.telecomsAddresses.find(
                (a) => a.telecomsAddressType === "workPhone"
              )?.number || "";
            customerContactParamsEntry.postalAddress = addressToString((customerResponse?.addresses?.postalAddresses[0] as PremisesAddress))
            customerContactParamsEntry.mobilePhone =
              customerResponse?.addresses.telecomsAddresses.find(
                (a) => a.telecomsAddressType === "mobilePhone"
              )?.number || "";
            customerContactParamsEntry.homePhone =
              customerResponse?.addresses.telecomsAddresses.find(
                (a) => a.telecomsAddressType === "homePhone"
              )?.number || "";
            customerContactParamsEntry.email =
              customerResponse?.addresses.digitalAddresses.find(
                (a) => a.digitalAddressType === "email"
              )?.address || "";
            setCustomerContact(customerContactParamsEntry);
            if (
              customerContactParamsEntry.mobilePhone == "" ||
              customerContactParamsEntry.email == "" || !isValidEmail(customerContactParamsEntry.email)
              || !isValidNumber(customerContactParamsEntry.mobilePhone)
            ) {
              setIsCustInfo(true);
            } else {
              setIsCustInfo(false);
            }
      } catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })();
  },[isActiveTenancy]);

  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
  }
  const isValidNumber = (number: any) => {
    const re = /^[+0-9]+$/;
    return re.test(number);
  };
  const callCustomerEditProfile = () => {
    props.history.push("/CustomerEditProfile");
  };

const dropDownFunction = () => {
    if (dropDown === "none")
        setDropDown("block");
    else
        setDropDown("none");
}

const handleFinancialReferral = () => {      
    dispatch(clearReferralInformation({}))
    props.history.push("/CustomerConsent");
 }   
  return (
    <div className="relative pb-16 border border-grey bg-grey rounded-lg cust-card mt-4">

      <div className="px-4 py-2 border-b border-grey bg-teal rounded-t-lg">
        <div className="flex flex-row d-flex bd-highlight items-center">
        <img src={customertImg} className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft" alt=""/>
          <h2 className="my-0 text-white bd-highlight">{t("Customer_Profile")}</h2>
          { ((!catalystCustomer && referralToggle === "true")|| referralToggle === "false") && customerData?.statuses[0]?.value && <div className="menu-nav ms-auto bd-highlight">
                    <div className="dropdown-container">
                      <div className="three-dots kebabMenu" data-testid="dots_menu" onClick={dropDownFunction} ></div>
                      {referralLink && <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                          <a data-testid="view_trans" onClick={handleFinancialReferral} className="text-black text-base no-underline global-txt"><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("Financial_FI001a")}</div></a>
                      </div>}
                    </div>
                </div>}


        </div>
      </div>

      
      <div className= {"py-2 darker-grey px-4 mb-2 global-txt" }>
          <div className="pr-4 px-2 mt-2"><b>{t("My_Peabody_registration")}</b></div>
          <div className="px-2">{customerContact?.peabodyRegistration?customerContact?.peabodyRegistration: "-"} </div>
        

        {userName && <div className="px-2">
          <div className="pr-4 mt-2"><b>{("Username")}</b></div>
          <div className="cardEmail">{userName} </div>
        </div> }
        </div>
        <div className="px-4 mb-8 global-txt">
        <div className="px-2 py-2">
          <div className="pr-4 py-2"><b>{t("Customer_type")}</b></div>
          <div>{customerContact?.customerType?customerContact?.customerType: "-"}</div>
        </div>
        {isDateOfBirth ? (
        <div className="px-2 py-2 ">
          <div className="pr-4"><b>{t("Date_of_birth")}</b></div>
          <div>  {formatDateWithFromCalendar(customerContact?.dateOfBirth || "-")}</div>
        </div> ) : null }
        <div className="px-2 py-2">
          <div className="pr-4"><b>{t("Email")}</b></div>
          <div> {customerContact?.email ? customerContact?.email : "-"}</div>
        </div>
        <div className="px-2 py-2">
          <div className="pr-4"><b>{t("Mobile")}</b></div>
          <div>  {customerContact?.mobilePhone
            ? customerContact?.mobilePhone
            : "-"}</div>
        </div>
        <div className="px-2 py-2">
          <div className="pr-4"><b>{t("Home")}</b></div>
          <div>{customerContact?.homePhone ? customerContact?.homePhone: "-"}</div>
        </div>
        <div className="px-2 py-2">
          <div className="pr-4"><b>{t("Business")}</b></div>
          <div>{customerContact?.workPhone? customerContact?.workPhone: "-"}</div>
        </div>
        <div className="px-2 py-2">
          <div className="pr-4"><b>{t("Postal_address")}</b></div>
          <div className="">  {customerContact?.postalAddress? customerContact?.postalAddress: "-"}</div>
        </div>
      </div>
      {!isCustInfo ? (
        <div></div>
      ) : (
        <div className="alert mb-8">
          <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
            <div className="px-4 pr-8">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
            </div>
            <div className="flex items-center">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t("missing_information_profile")}</p>
            </div>
          </div>
        </div>
      )}
      <div className="w-full px-4 py-4 flex justify-between absolute bottom-0">
        <div><a className="global-txt text-teal underline cursor-pointer" onClick={callCustomerEditProfile}><b>{t("Edit_profile")} </b></a></div>
      </div>
    </div>
  )
};
export default CustomerCard;