import React, { useEffect, useRef, useState } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import {SearchInput } from './components'
import SearchSection from './Section'
import { useDispatch } from 'react-redux'
import { isSearch } from '../../ducks/redux/actions/userChoices'

type SearchProps = {
  className?: string
  customerSearchText?:any
  getCustomerSearchText?:any
  defaultValue?: string
  onChange?: (s: string) => any
  onClear?: (s: string) => any
  onSubmit: (s: string) => any
  placeholder?: string
  style?: object
  value?: string
  isDisabled?: boolean | true;
  datatestid?: any
}

const Search: React.FC<SearchProps> = ({
  className,
  customerSearchText,
  getCustomerSearchText,
  defaultValue = '',
  onChange,
  onClear,
  onSubmit,
  placeholder,
  style,
  value,
  isDisabled,
  datatestid
}) => {
  const [localValue, setLocalValue] = useState(defaultValue)
  const currentValue = value ?? localValue
  const prevDefaultValue = useRef(defaultValue)
  const [searchMode, setSearchMode] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    if (prevDefaultValue.current !== defaultValue) {
      setLocalValue(defaultValue)
      prevDefaultValue.current = defaultValue
    }
    if(customerSearchText!= undefined && getCustomerSearchText){
      if (onChange) {
      onChange(customerSearchText)
      }
      onSubmit(customerSearchText)
    }
    if(customerSearchText == undefined && !getCustomerSearchText && window.location.href.includes('?')){
      if (onChange) {
      onChange('')
      }
      onSubmit('')
      setSearchMode(false)
    } 
  }, [defaultValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
    if(e.target.value.trim() === "" || e.target.value === null)
      setSearchMode(false)
  }
  const handleSearchClick = (e: React.MouseEvent) => {
    if (currentValue === 'Flenderson') {
      throw new Error('Testing stack trace 12345')
    }
    onSubmit(currentValue)
    setSearchMode(true)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === "13") {
      setSearchMode(true)
      onSubmit(currentValue)
      e.target.blur()
      e.target.parentNode.blur();
    }
  }

  const resetSearch = (e: React.MouseEvent) => {
    setLocalValue('')
    if (onClear) {
      onClear(currentValue)
    }
    setSearchMode(false)
  }

  return (
    <SearchInput
      className={className}
      //defaultValue={defaultValue}
      data-bdd="search"
      placeholder={placeholder ?? 'Search'}
      value={localStorage.getItem("option")!='false'? customerSearchText !== undefined && getCustomerSearchText ? customerSearchText: currentValue:''}
      onChange={handleChange}
      onBlur={() => onSubmit(currentValue)}
      onKeyDown={handleKeyDown}
      style={{ ...style }}
      data-testid={datatestid}
      endAdornment={
        <SearchSection currentValue={customerSearchText !== undefined && getCustomerSearchText ? customerSearchText : currentValue} resetSearch={resetSearch} handleSearchClick={handleSearchClick} 
        searchMade = {searchMode}/>
      }
      labelWidth={0}
      disabled={isDisabled}
    />
  )
}

export default Search
