import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom';


interface WorkOrderResultDetailsProps extends RouteComponentProps<any> {
    workorderdetails: any;
}

const WorkOrderResultDetails: React.FC<WorkOrderResultDetailsProps> = ({workorderdetails}) => {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation()

  const callPageHandler = () => {
    setIsActive(!isActive);
  }
  
  return (

    <div>
      <div className={`${isActive ? "bg-dl-teal px-4 py-1" : "bg-dl-teal px-4 py-1 rounded-b-lg"}`}
>
        <div onClick={(event) => callPageHandler()}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            callPageHandler();
          }
        }}
         data-testid="WorkOrderResultDetails-main-accor1" className="cursor-pointer flex flex-row justify-between items-center">
          <p className="my-0  "><strong>{t("More_DetailsCP106")}</strong></p>
          <div className="plus-sign mt-10 cursor-pointer">
            {isActive ? "⨯" : "+"}
          </div>
        </div>
      </div>
      {isActive ? (

        <div>         
          {      
            <div className="flex flex-col bg-grey md:flex-row justify-between md:mt-1">
                <div className="w-full md:w-6/12 lg:w-6/12 flex items-center px-1 mt-3 px-4">                
                    <p>{workorderdetails?.job?.description +". "+ workorderdetails?.job?.additionalInformation}</p>
                </div>
            </div>            
            
            }

        </div>

      ) : (
        <div></div>
      )}
    </div>
  )
}


export default WorkOrderResultDetails