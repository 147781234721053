import { useEffect, useState } from 'react';
import B2T from '../../assets/image/b2t.png';
import useBackToTop from './useBackToTop';

export  default function BackToTopComponent() {
  
const {width} = useBackToTop()
const [footerVisible, setFooterVisible] = useState(false)

function myfunction(value:any) { 
  const item = value?.getBoundingClientRect(); 
  return ( 
    
      item?.top >= 0 &&  
      item?.bottom <= ( 
          window.innerHeight + 35 || 
          document.documentElement.clientHeight + 35) 
      
  ); 
} 


useEffect(()=>{
  let mybutton = document.getElementById("footer");
  window.addEventListener('scroll', () => { 
    if (myfunction(mybutton)) { 
        setFooterVisible(true)
    } else { 
        setFooterVisible(false)
    } 
  }); 
     
},[width])

return (
  <>
  <button id = "myBtn" style={{display:'contents'}} onClick={()=> window.scrollTo(0, 0)} onKeyPress={()=> window.scrollTo(0, 0)}>
    <img src={B2T}  role="button" alt="backtotop"
      className={footerVisible? "backToTopDesktop": "backToTop"}
      data-testid="b2t"
    />
    </button>
    </>
  )
}