import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import tooltip from "../../assets/icons/tooltip.png";
import "./index.css";
import Tooltip from "@mui/material/Tooltip";
import Modal from "../Modal";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { assetByIdDataObject } from "../../ducks/redux/actions/dashboardReducer.actions";

interface HighRiseBuilding extends RouteComponentProps<any> {}

const HighRise: React.FC<HighRiseBuilding> = (props) => {
  const { t } = useTranslation();
  const {id, assetType} = useSelector((state: any) => state?.addresses?.assetData) || {};
  const [highRiseRes, setHighRiseRes] = useState<any>();
  const [popup, setPopup] = useState(false);
  const [openPAP, setOpenPAP] = useState(false)
  const [openBSM, setOpenBSM] = useState(false)
  const [openAP, setOpenAP] = useState(false)
  const [openRP, setOpenRP] = useState(false)
  const [Isloading, setIsloading] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isHighRise, setIsHighRise] = useState(false)
  const [furtherAttr, setFurtherAttr] = useState('')

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);


  
  const getAssociate = useCallback(async (id) => {
    try {
      const data = await api.getAssetsByid(id)
      setFurtherAttr(data?.configuration?.highriseCategoryName)
      setIsHighRise(data?.statuses?.isHighRise)
      if (data?.statuses?.isHighRise){
        const response = await api.GetHighRiseProperty(id);
        setHighRiseRes(response?.associates?.interestedParties);
      }
      
    } catch (e: any) {
      console.log(e);
    }finally{
      setIsloading(false)
    }
  }, [id]);

const getBlockIdAssociatedWithProperty = useCallback (async()=> {
  setIsloading(true)
  try{
    let res = await api.getAssociatedAssetsKey(id);
    res?.map((data:any)=> {
      if(data?.assetType === "Block"){
        getAssociate(data?.id)
      }else{
        setIsloading(false)
      }
    })
  }catch(e:any){
    console.log(e)
    setIsloading(false)
  }
  
},[id])

  useEffect(() => {
    if(assetType === "Block"){
      getAssociate(id);
      setIsloading(true)
    }else if(assetType === "Property"){
      getBlockIdAssociatedWithProperty()
    }
  }, []);

const handlePAPTooltipOpen = (event:any) => {
    
    if(width<=820) {
        setOpenPAP(true);
    }
}
const handleAPTooltipOpen = (event:any) => {
    if(width<=820) {
        setOpenAP(true);
    }
}
const handleRPTooltipOpen = (event:any) => {
    if(width<=820) {
        setOpenRP(true);
    }
}
const handleBSMTooltipOpen = (event:any) => {
    if(width<=820) {
        setOpenBSM(true);
    }
}

  return (
    <div data-testid="child">
      <Modal open={popup} cssClasses="modalPopUp">  
        <div className="py-4 px-4">
            <strong className="HighRiseText text-purple-100">{t('HR_BI006')}</strong>
           <strong className="close" onClick={()=>setPopup(false)} onKeyPress={()=>setPopup(false)}>X</strong>  
           <p></p>      
            <p>{t('HR_BI007')}</p>
            <p>{t('HR_BI007p')} <a href ="https://www.peabody.org.uk/your-home/home-safety/fire-safety/high-rise-buildings/" target="_blank" rel="noopener">{t('HR_BI007link')}</a></p>
        </div>
      </Modal>
      {Isloading && <div className="div-loader">
        <div>
          <LoadingSpinner />
        </div>
      </div>} 
       { !Isloading && isHighRise && <div>
                {furtherAttr !== "NAP" && <div className="sections__container mt-2 mb-2">
                  <div className="sections__container__a inactive ">
                   <b> {" "}
                    {t("HR_BI002a")}</b></div>
                    <div className=" sections__container__b active">
                
                    <Tooltip
                title="The PAP manages fire and structural risk and will create a resident engagement plan and respond to any complaints."
                componentsProps={{
                    tooltip: {
                    sx: {
                        bgcolor: "#E3F2FF",
                        color: "#000",
                        border: "1px solid #1551C1",
                        fontSize: "16px",
                        ["& .MuiTooltip-tooltip"]: {
                            width: "500px"
                          }
                    }
                    }
                }}
                open={openPAP}
                onClose={()=>{setTimeout (()=>{setOpenPAP(false)},3000)}}
                >
      

                
                
                    <img
                        src={tooltip}
                        alt="tooltip1"
                        className="toolInfo"
                        onClick={handlePAPTooltipOpen} onKeyPress={handlePAPTooltipOpen}   style={{ display: "inline" }}
                 onMouseOver={() => setOpenPAP(true)} onFocus={() => setOpenPAP(true)}
                 onMouseLeave={() => setOpenPAP(false)}
                        />
                            
                     
                
                
                </Tooltip>
  
               </div>
                  <div className=" sections__container__c active">
                    <div >
                      {
                        highRiseRes?.principleAccountablePerson?.person?.name
                          ?.givenName || '-'
                      }
                    </div>
                    <div className={" a-blue"} onClick={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.principleAccountablePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }}  onKeyPress={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.principleAccountablePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }}>
                      {
                        highRiseRes?.principleAccountablePerson?.addresses
                          ?.digitalAddresses[0]?.emailAddresses[0]?.value || '-'
                      }
                    </div>
                  </div>
                </div>}
              
              
                {(furtherAttr !== "NAP" && furtherAttr !== "PAP") && <div className="sections__container mt-2 mb-2">
                  <div className="sections__container__a inactive ">
                    {" "}
                    <b>{t("HR_BI003a")} </b></div>
                    <div className=" sections__container__b active">

                    <Tooltip
                title="The AP helps to manage risk. They may employ a managing agent to be the responsible person."
                open={openAP}
                onClose={()=>{setTimeout (()=>{setOpenAP(false)},3000)}}
                componentsProps={{
                    tooltip: {
                    sx: {
                        bgcolor: "#E3F2FF",
                        color: "#000",
                        border: "1px solid #1551C1",
                        fontSize: "16px",
                        ["& .MuiTooltip-tooltip"]: {
                            width: "500px"
                          }
                    }
                    }
                }}
                >
                
                    <img
                        src={tooltip}
                        alt="tooltip2"
                        onClick={handleAPTooltipOpen} onKeyPress={handleAPTooltipOpen} className="toolInfo" 
                 onMouseOver={() => setOpenAP(true)} onFocus={() => setOpenAP(true)}
                 onMouseLeave={() => setOpenAP(false)} />
                    
                       
                
                </Tooltip>               
                 </div>  
                
                 
                  <div className=" sections__container__c active">
                  <div>
                      {highRiseRes?.accountablePerson?.person?.name?.givenName || '-'}
                    </div>
                    <div className={" a-blue"} onClick={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.accountablePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }} onKeyPress={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.accountablePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }}>
                      {
                        highRiseRes?.accountablePerson?.addresses
                          ?.digitalAddresses[0]?.emailAddresses[0]?.value || '-'
                      }
                    </div>
                  </div>
                </div>}
              
             
                 {furtherAttr !== "NAP" && <div className="sections__container mt-2 mb-2">
                   <div className="sections__container__a inactive ">
                    {" "}
                    <b>{t("HR_BI004a")}</b></div>
                    <div className=" sections__container__b active">
                
                    <Tooltip
                title="The BSM regularly checks your building and you can contact them for concerns about fire or structural safety."
                open={openBSM}
                onClose={()=>{setTimeout (()=>{setOpenBSM(false)},3000)}}
                componentsProps={{
                    tooltip: {
                    sx: {
                        bgcolor: "#E3F2FF",
                        color: "#000",
                        border: "1px solid #1551C1",
                        fontSize: "16px",
                        ["& .MuiTooltip-tooltip"]: {
                            width: "500px"
                          }
                    }
                    }
                }}
                >
                
                
                    <img
                        src={tooltip}
                        alt="tooltip3"
                        onClick={handleBSMTooltipOpen} onKeyPress={handleBSMTooltipOpen} className="toolInfo" 
                 onMouseOver={() => setOpenBSM(true)} onFocus={() => setOpenBSM(true)}
                 onMouseLeave={() => setOpenBSM(false)}
                        />
                    
                    
                
                </Tooltip> 
                </div> 
                  
                  <div className=" sections__container__c active">
                  <div>
                      {
                        highRiseRes?.buildingSafetyManager?.person?.name
                          ?.givenName || '-'
                      }
                    </div>
                    <div className={" a-blue"} onClick={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.buildingSafetyManager?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }} onKeyPress={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.buildingSafetyManager?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }}>
                      {
                        highRiseRes?.buildingSafetyManager?.addresses
                          ?.digitalAddresses[0]?.emailAddresses[0]?.value || '-'
                      }
                    </div>
                  </div>
                </div>}
              
            <div className="sections__container mt-2 mb-2">
               <div className="sections__container__a inactive ">
               <b> {" "}
               {t("HR_BI005a")}</b></div>
                <div className=" sections__container__b active">
               
                <Tooltip
                title="The RP manages your communal areas and all fire safety equipment so that the building is safe from the risk of fire spread."
                open={openRP}
                onClose={()=>{setTimeout (()=>{setOpenRP(false)},3000)}}
                componentsProps={{
                    tooltip: {
                    sx: {
                        bgcolor: "#E3F2FF",
                        color: "#000",
                        border: "1px solid #1551C1",
                        fontSize: "16px",
                        ["& .MuiTooltip-tooltip"]: {
                            width: "500px"
                          }
                    }
                    }
                }}
                >
                    
                    <img
                        src={tooltip}
                        alt="tooltip4"
                        onClick={handleRPTooltipOpen} onKeyPress={handleRPTooltipOpen} className="toolInfo" 
                    onMouseOver={() => setOpenRP(true)} onFocus={() => setOpenRP(true)}
                    onMouseLeave={() => setOpenRP(false)}
                        />
                    
                    
                </Tooltip>
                
                </div>
              
              <div className=" sections__container__c active">
              <div >
                  {highRiseRes?.responsiblePerson?.person?.name?.givenName || '-'}
                </div>
                <div className={" a-blue"} onClick={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.responsiblePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }} onKeyPress={(e)=> {
                      window.location.href =`mailto:${highRiseRes?.responsiblePerson?.addresses
                        ?.digitalAddresses[0]?.emailAddresses[0]?.value}`;
                        e.preventDefault();
                    }}>
                  {
                    highRiseRes?.responsiblePerson?.addresses
                      ?.digitalAddresses[0]?.emailAddresses[0]?.value || '-'
                  }
                </div>
              </div>
            </div>
          

          
        </div>
       }
        <div className="w-full py-4 flex justify-between absolute bottom-0">
            <div className="w-100">
            <b>  <a
                id="repairs-Create"
                data-testid="repairs-Create"
                className="underline a-blue"
                onClick={() => setPopup(true)}
                onKeyPress={() => setPopup(true)}
              >
                {t("HR_BI006")}{" "}
              </a></b>
            </div> 
          </div>
    </div>
  );
};

export default HighRise;
