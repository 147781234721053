import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  formatContent,
  getUpdateOwnerName,
  isNullOrUndefined,
} from "../../../utils/formatting";
import ExitModalContent from "../../Modal/ExitModalContent";
import { Button } from "react-bootstrap";
import "./style.css";
import { CaseActionItem } from "../../../models/Case/CaseActionItem";

interface CaseUpdateActionProps extends RouteComponentProps<any> {}
const CaseUpdateActionComponent: React.FC<CaseUpdateActionProps> = (props) => {
  const [textValue, setTextValue] = useState("");
  const { t } = useTranslation();
  const caseData = useSelector(
    (state: any) => state?.userChoicesReducer?.caseData
  );
  const actionDetail = useSelector(
    (state: any) => state.caseCreationReducer.caseActionDetail
  );

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
    })();
  }, []);

  const backButtonHandler = () => {
    props.history.push("/CaseSelectAction");
  };
  const saveButtonHandler = async () => {
    var caseTypeActionDetails = new CaseActionItem();
    caseTypeActionDetails.type = "Action";
    caseTypeActionDetails.identifiers.caseIdentifier =
      caseData.identifiers.caseIdentifier;
    caseTypeActionDetails.identifiers.actionIdentifier =
      actionDetail.metaData.activityIdentifier;
    caseTypeActionDetails.metaData = [
      { key: "status", value: "inprogress" },
      {
        key: "annotations",
        value: [
          { key: "subject", value: "Updated in P360" },
          { key: "description", value: textValue },
        ],
      },
    ];

    try {
     await api.UpdateAnAction(
        caseData.identifiers?.caseIdentifier,
        actionDetail.metaData.activityIdentifier,
        caseTypeActionDetails
      );
    } catch (e) {
      props.history.push("/genericerror");
    } finally {
      props.history.push("/Case360View");
    }
  };

  const saveCompleteButtonHandler = async () => {
    var caseTypeActionDetails = new CaseActionItem();
    caseTypeActionDetails.type = "Action";
    caseTypeActionDetails.identifiers.caseIdentifier =
      caseData.identifiers.caseIdentifier;
    caseTypeActionDetails.identifiers.actionIdentifier =
      actionDetail.metaData.activityIdentifier;
    caseTypeActionDetails.metaData = [
      { key: "status", value: "completed" },
      {
        key: "annotations",
        value: [
          { key: "subject", value: "Updated in P360" },
          { key: "description", value: textValue },
        ],
      },
    ];
    try {
    await api.UpdateAnAction(
        caseData.identifiers?.caseIdentifier,
        actionDetail.metaData.activityIdentifier,
        caseTypeActionDetails
      );
    } catch (e) {
      props.history.push("/genericerror");
    } finally {
      props.history.push("/Case360View");
    }
  };
  const exitButtonHandler = () => {
    props.history.push("/CaseSelectAction");
  };
  const onChange = (e: any) => {
    setTextValue(e.target.value);
  };
  return (
    <div>
      <div className="border-t border-grey bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
        <div className="container mx-auto pb-12 ctn-fht">
          <div className="w-full px-2 lg:px-0 ">
            <div className="mt-4 mb-8 ">
              <p className="text-teal text-xl mt-4 mb-4 md:mb-12">
                <a
                  className="back-btn appGreenColor"
                  data-testid="backButtonHandler"
                  onClick={backButtonHandler}
                  aria-hidden="true"
                >
                  <i className="fas fa-angle-left mr-2"></i>
                  {t("back")}
                </a>
              </p>
            </div>
            <h1 className="sm:px-3 sm:font">{t("UpdateAction")}</h1>
            <div className="border border-grey bg-grey rounded-lg mb-8 mt-8">
              <div className="bg-brick-20 px-4 py-2 rounded-t-lg">
                <div className="flex flex-row items-center">
                  <strong>
                    {t("Case_Reference")}{" "}
                    {formatContent(caseData?.caseReferenceNumber)}
                  </strong>
                </div>
              </div>
              <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                <div className="mb-2">
                  <strong>{t("CaseTitle")}</strong>
                  <br />
                  {formatContent(caseData?.caseSubTypeCategory)}
                </div>
                <div className="mb-2">
                  <strong>{t("CP085")}</strong>
                  <br />
                  {getUpdateOwnerName(caseData?.owner?.name)}
                </div>
              </div>
            </div>
            <div className="border border-grey bg-grey rounded-lg mb-8">
              <div className="bg-dl-teal px-4 py-2 rounded-t-lg">
                <div className="flex flex-row justify-between items-center">
                  <p className="my-0 text-base">
                    <strong>{actionDetail.type}</strong>
                  </p>
                </div>
              </div>
              <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                <div className="mb-2">
                  <strong>{t("Created_On")}</strong>
                  <br />{" "}
                  {moment(actionDetail.createdDate).format(
                    "DD-MM-YYYY, h:mm:ss A"
                  )}
                </div>
                {actionDetail.metaData &&
                  !isNullOrUndefined(actionDetail.metaData["owner"]) && (
                    <div className="mb-2">
                      <strong>{t("CP085")}</strong>
                      <br />
                      {actionDetail.metaData["owner"]}
                    </div>
                  )}
                {actionDetail.metaData &&
                  !isNullOrUndefined(actionDetail.metaData["createdBy"]) && (
                    <div className="mb-2">
                      <strong>{t("Created_By_CP115")}</strong>
                      <br />
                      {actionDetail.metaData["createdBy"]}
                    </div>
                  )}
                {actionDetail.metaData &&
                  !isNullOrUndefined(actionDetail.metaData["status"]) && (
                    <div className="mb-2">
                      <strong>{t("CP086")}</strong>
                      <br />
                      {actionDetail.metaData["status"]}
                    </div>
                  )}
              </div>
              <div className="px-4 py-2 grid grid-cols-1 mt-3 global-txt">
                {actionDetail.type &&
                  actionDetail.type == "Appointment" &&
                  actionDetail.metaData &&
                  actionDetail.metaData["appointmentDate"] && (
                    <div className="mb-2">
                      <strong>{t("Appointment_Date")}</strong>
                      <br />{" "}
                      {moment(actionDetail.metaData["appointmentDate"]).format(
                        "DD-MM-YYYY, h:mm:ss A"
                      )}
                    </div>
                  )}
                <div className="mb-4">
                  <strong>{t("actionType")}</strong>
                  <br />
                  {actionDetail.metaData["actionType"]}
                </div>
              </div>
            </div>
            <div className="sm:px-3 w-3/4 md:text-left pt-2 pb-0 pl-1 global-txt">
              <b> {t("ActionUpdateDetails")}</b>
            </div>

            <div
              id="repAddDet-div3"
              className="global-txt mt-1"
              data-testid="repairdetails"
            >
              <textarea
                data-testid="Case_question_details"
                id="outlined-basic"
                className="textarea-mob mt-2 border-dark-grey global-txt border-grey vul-teal"
                placeholder=""
                value={textValue}
                aria-label="Action Update Details"
                onChange={(e) => onChange(e)}
              />
            </div>
            <div
              className="flex justify-between items-center pb-4 md:pb-8 sm:mx-1"
              id="repreason-main-btn"
            >
              <div className="w-10 float-right">
                <ExitModalContent
                  data-testid="case-exitmodal appGreenColor"
                  btn_msg="return_page_button"
                  style={{ marginLeft: "0.5rem" }}
                  exit={exitButtonHandler}
                />
              </div>
              <div className="w-90 float-right">
                <Button
                  className="pad-bot float-right ml-2 savecompletebutton"
                  data-testid="btn_saveComplete"
                  onClick={saveCompleteButtonHandler}
                  disabled={isNullOrUndefined(textValue)}
                >
                  {t("SaveandComplete")}
                </Button>
                <Button
                  className="pad-bot float-right savebutton savecompletebutton"
                  data-testid="btn_save"
                  onClick={saveButtonHandler}
                  disabled={isNullOrUndefined(textValue)}
                >
                  {t("save")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseUpdateActionComponent;
