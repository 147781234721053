import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { CaseHistoryItem } from "../../../models/Case/CaseHistory";
import Loader from "react-loader-spinner";
import { isNullOrUndefined, removeTagsFromContent } from "../../../utils/formatting";
import { StyledOverflowHidden } from "./component";
import ServiceRequestImg from '../../../assets/icons/service-request-details.png';

interface CaseChronologySummaryProps extends RouteComponentProps<any> {}
const CaseChronologySummaryComponent: React.FC<CaseChronologySummaryProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const caseData = useSelector(
    (state: any) => state?.userChoicesReducer?.caseData
  );
  const [caseHistoryDetails, setCaseHistoryDetails] = React.useState<
    CaseHistoryItem[]
  >([]);
  const [caseHistoryCount, setCaseHistoryCount] = useState(0);
  const [dropDown, setDropDown] = useState("none");
  useEffect(() => {
    getChronology();
  }, []);
  const isCaseManagementResponse = useSelector(
    (state:any) => state?.caseCreationReducer?.isCaseManagementToggle
);
const isUpdateAnActionResponse = useSelector(
  (state:any) => state?.caseCreationReducer?.isUpdateAnActionToggle
);
  const getChronology = async () => {
    try {
      setIsLoading(true);
      const allItems: any = [];
      const CaseHistory: any = await api.getCaseHistories(
        caseData.identifiers?.caseIdentifier
      );
      if (CaseHistory?.historyItems?.length > 0) {
        let filteredResults = [];
        if (CaseHistory.historyItems.length > 1) {
          const sortedResults = CaseHistory.historyItems.sort(
            (a: any, b: any) =>
              new Date(b.createdDate).valueOf() -
              new Date(a.createdDate).valueOf()
          );
          const finalSortedResults = sortedResults.sort(
            (a: any, b: any) =>
              new Date(a.createdDate).valueOf() -
              new Date(b.createdDate).valueOf()
          );
          filteredResults.push(finalSortedResults);
        } else filteredResults.push(CaseHistory.historyItems);

                filteredResults.map((items: any) => {
                    items.map((eachItem: CaseHistoryItem) => {
                        allItems.push(eachItem);
                    });
                }) 

                if (allItems.length > 0) {
                    setCaseHistoryCount(allItems.length);
                    const filteredItems = allItems.filter(
                        (item: any) => item.type !== ""
                    );
                    const filteredHistorySummary = filteredItems?.sort((a: any, b: any) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()).slice(0, 2)
                    setCaseHistoryDetails(filteredHistorySummary);
                }
            }
        } catch (e) {
            console.log(e);
            props.history.push("/genericerror");
        }
        finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }
    }

    const callCaseChronologyPage = () => {
        props.history.push("/CaseChronologyList");
    }
    const dropDownFunction = () => {
        if (dropDown === "none") setDropDown("block");
        else setDropDown("none");
      };
      const callAddUpdate = async () => {
        props.history.push("/addupdate");
      };
      const callUpdateAction = async () => {
        props.history.push("/CaseSelectAction")
      };

    return (
        <div>
            <div className="w-full">
                {isLoading ? (
                    <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={70}
                        width={50}
                    />
                ) : (
                        <>
                            <div className="px-4 py-2 border-b border-grey bg-teal rounded-t-lg mb-2">
                                <div className="flex flex-row d-flex bd-highlight items-center">
                                    <img alt="S_r_history" src={ServiceRequestImg} className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft"/>
                                    <h2 className="my-0 text-white-100 bd-highlight">{t("OPC040")}</h2>
                                    <div className="menu-nav ms-auto bd-highlight">
            {isCaseManagementResponse &&<div className="dropdown-container">
                    <div
                      className="three-dots bg-teal kebabMenu"
                      data-bs-toggle="dropdown"
                      data-testid="dropFunction"
                      onClick={dropDownFunction}
                      onKeyDown={dropDownFunction}
                    ></div>

                    <div
                      className="dropdown-ast px-2 dropdown-menu show mr-3"
                      style={{ display: dropDown }}
                    >
                      <a
                        data-testid="caseAddUpdate"
                        onClick={callAddUpdate}
                        onKeyDown={callAddUpdate}
                        className="text-black text-base no-underline global-txt"
                      >
                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                          {t("AddUpdate")}
                        </div>
                      </a>
                     {isUpdateAnActionResponse && <a
                        data-testid="caseUpdateAction"
                        onClick={callUpdateAction}
                        onFocus={ () => {} }
                        onKeyDown={ () => {}}
                        className="text-black text-base no-underline global-txt"
                      >
                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                          {t("UpdateAction")}
                        </div>
                      </a>}
                    </div>
                  </div>}
                </div>
                                </div>
                            </div>
                            {caseHistoryDetails?.length > 0 ? (
                                <>
                                <div >
                                    {
                                        caseHistoryDetails?.map(
                                            (eachCaseUpdate: CaseHistoryItem, key: any) => {
                                                return (
                                                    <div className="px-4 py-2">
                                                        <div className="border border-grey bg-grey rounded-lg mb-2">
                                                            <div className="bg-peach px-4 py-2 rounded-t-lg global-txt">
                                                                <div className="flex flex-row justify-between items-center">
                                                                    <p className="my-0 text-lg"><strong>{eachCaseUpdate.type}</strong></p>
                                                                </div>
                                                            </div>

                                                            <div>
                              <div className="px-4 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                   <div className="mb-2">
                                      <strong>{t("Created_On")}</strong><br />  {moment(eachCaseUpdate.createdDate).format(
                                           "DD-MM-YYYY, h:mm:ss A"
                                      )}
                               </div>
                              {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["status"]) && <div className="mb-2">
                                 <strong>{t("CP086")}</strong>
                                 <br />
                               {eachCaseUpdate.metaData["status"]}
                               </div>}
                          </div>
                          <div className="grid grid-cols-1 global-txt">
                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["owner"]) && <div className="mb-2 px-4">
                                   <strong>{t("CP085")}</strong><br />{eachCaseUpdate.metaData["owner"]}
                                </div>}

                            {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["createdBy"]) && <div className="mb-2 px-4">
                               <strong>{t("Created_By_CP115")}</strong><br />{eachCaseUpdate.metaData["createdBy"]}
                            </div>}

                                                                {eachCaseUpdate.type && eachCaseUpdate.type == "Appointment" && eachCaseUpdate.metaData && eachCaseUpdate.metaData["appointmentDate"] && <div className="mb-2 px-4">
                                                                    <strong>{t("Appointment_Date")}</strong><br />  {moment(eachCaseUpdate.metaData["appointmentDate"]).format(
                                                                        "DD-MM-YYYY, h:mm:ss A"
                                                                    )}
                                                                </div>}
                                                                {(eachCaseUpdate.type === "Action" && eachCaseUpdate.metaData
                                    && !isNullOrUndefined(eachCaseUpdate.metaData["actionType"])) && (
                                  <div className="mb-2 px-4">
                                    <strong>{t("actionType")}</strong>
                                    <br />
                                    <div>
                                      {eachCaseUpdate.metaData["actionType"]}
                                    </div>
                                  </div>
                                )}
                                {eachCaseUpdate.type !== "Action" && (eachCaseUpdate.type !== "Attachment Added" ||
   (eachCaseUpdate.type == "Attachment Added" && !isNullOrUndefined(eachCaseUpdate.comment))) &&<Fragment>
    <div className="mb-2 px-4">
                                                                     <strong>{t("comment")}</strong><br /><div><StyledOverflowHidden>{removeTagsFromContent(eachCaseUpdate.comment)} </StyledOverflowHidden></div>
                                                                </div></Fragment>}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                      </div>
              <span className="w-full px-4 py-4 flex justify-between absolute bottom-0 global-txt" data-testId="ViewAllCaseChronologyPlaceholder">
                  {caseHistoryCount > 2 && (
                        <a
                          href=""
                          data-testId="ViewAllCaseChronology"
                          className="text-teal underline"
                          onClick={callCaseChronologyPage}
                        >
                          <b>{t("View_Case_History")}</b>
                        </a>
                  )}
                </span>
            </>
            ) : (
                                    <div>
                                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md px-4 mt-4">
                                            {t("OPC041")}
                                        </p>
                                    </div>
                                )}
                        </>
                    )
                }
            </div>
        </div>

    )
}

export default CaseChronologySummaryComponent;