import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import Loader from "react-loader-spinner";
import {
  userChosenPropertyId,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenAdditionalDescription,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll,
  isEditState
} from "../../ducks/redux/actions/userChoices";

import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import {
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  workOrderRepairObject,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
} from "../../ducks/redux/actions/getAddress";
import CustomerName from "../../models/Customer/CustomerName";
import * as api from "../../api";
import {
  RepairReporterId,
  ReportContactDetails,
} from "../../models/Repair/Repair";
import { isNullOrUndefined } from "../../utils/formatting";
import { PermissionsList } from "../../models/Permissions";
import { Helper } from "../../utils/Helper/HelperUtil";
interface CustomerDetailsProps extends RouteComponentProps<any> { }
const CustomerDetails: React.FC<CustomerDetailsProps> = (props) => {
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
    "Complaint" = 3,
    "Technical Inspection" = 5,
    "Disrepair" = 6,
    "Right to Improve" = 7,
    "Mutual Exchange" = 8,
    "Leaseholder Improvement Request" = 9,
    "Estate Management Survey" = 10,
    "Keysafe Installation" = 11,
    "Void Survey" = 12
  }

  const { t } = useTranslation();
  const customerPhone = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber
  );
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ??
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  const [inputState, setInputState] = useState<any>(t("Customer_Option"));
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  var errorArray: any[] = [];
  const [errorArrayState, seterrorArrayState] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberInput, setNumberInput] = useState("");
  const [checkRadio, setCheckRadio] = useState<boolean>(true);
  const radioText = t("Provide_Number");
  const [radiovalue, setRadioValue] = useState(
    customerPhone !== null && customerPhone !== undefined && customerPhone !== ""
      ? customerPhone[0]?.number
        ? `${customerPhone[0].number}@0`
        : radioText
      : radioText
  );
  
  const [isinputdisabled, setIsInputDisabled] = useState<boolean>(true);
  const userChosenContactDetailsBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactDetails
  );
  const userChosenContactNameBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );
  const userChosenContactPhoneBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );
  const userChosenRelationSelect = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );

  const userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );

  const userChosenVandalismQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenVandalismAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );
  const isSurveyValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isSurvey
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenSurveyReason = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.dashboardReducer.formState?.priorityCode
  );


  const hideVulnerablePagePermission = getPermissionsData?.indexOf(PermissionsList.workoder_vulnerable_hide_permission) >= 0;
  const hideVulnerablePage = hideVulnerablePagePermission || workOrderObject.isEmergency
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.history.action === "PUSH" || props.history.action === "POP" || props.history.action === "REPLACE") {
      if (userChosenContactDetailsBackButton) {
        if (userChosenContactDetailsBackButton.includes('@')) {
          setRadioValue(userChosenContactDetailsBackButton);
        }
        else {
          setRadioValue(userChosenContactDetailsBackButton);

          customerPhone?.map((number: any, index: number) => {
            if (number.number === userChosenContactDetailsBackButton) {
              setRadioValue(userChosenContactDetailsBackButton + '@' + index)
            }
          })
        }
        setIsInputDisabled(radiovalue !== radioText);
      }
      else{
        setIsInputDisabled(
          (checkRadio && !isNullOrUndefined(customerPhone)) 
            ? true 
            : radiovalue !== radioText  );
      }

    }
    if (userChosenContactDetailsBackButton === radioText) {
      setRadioValue(radioText);
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
      if (userChosenRelationSelect === "Friend or family") {
        dispatch(userChosenRelationDetails("Friend or Family"));
      } else {
        dispatch(userChosenRelationDetails(userChosenRelationSelect));
      }
    }
    else if (
      !userChosenContactDetailsBackButton &&
      !userChosenContactPhoneBackButton
    ) {
      if (customerPhone?.length)
        dispatch(userChosenContactDetails(`${customerPhone[0]?.number}@0`));
      dispatch(userChosenRelationDetails(""));
    }
    else {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
    }
   if(customerPhone  && customerPhone.length === 0){
    setCheckRadio(false)
   }
  }, [checkRadio, customerPhone, radiovalue]);

  const updateSelection = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(isEditState(false));
    }
    if (e.target.value !== radioText) {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      setNumberInput("");
      setUserInput("");
      seterrorArrayState([]);
      errorArray = [];
      dispatch(isEditState(false));
    } else if (e.target.value === radioText) {
      dispatch(userChosenRelationDetails("Customer"));
      setCheckRadio(false);

    }
    setRadioValue(e.target.value);
    dispatch(userChosenContactDetails(e.target.value));
    setIsInputDisabled(radiovalue === radioText);
  };
  const inputChangeHandler = (event: any) => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(isEditState(false));
    }
    // if value is not blank, then test the regex
    seterrorArrayState([]);
    errorArray = [];
    if (event.target.value === "" || Helper.ValidationRegex.NumericOnly.test(event.target.value)) {
      //  @ts-ignore
      setNumberInput(event.target.value.toString().slice(0, 11));

      dispatch(userChosenContactPhone(event.target.value));
    }
  };

  const numberValidate = () => {  
    if (isinputdisabled) {
      if(isSurveyValue) 
        {
        props.history.push("/SurveyConfirmationDetails");
        } 
        else if (assetDetails.assetType !== "Property" && isinputdisabled) {
        props.history.push("/BlockCommunication");
      }
       else {
        props.history.push("/ConfirmationDetails");
      }
      return true; 
    }
    const phoneEmpty = t("SSR014");
    const phoneInvalid = t("SSR002");

    const ukCountryCode = "0";  
    if (!numberInput) {
      errorArray.push(phoneEmpty);
      seterrorArrayState(errorArray);
      textvalidate();
      return false; 
    }  
    if (!numberInput.startsWith(ukCountryCode) || numberInput.length < 11) {
      errorArray.push(phoneInvalid);
      seterrorArrayState(errorArray);
      textvalidate();
      return false;
    }
    if(!textvalidate()) return false;
    errorArray = [];
    return true; 
  };
  const nameError = t("SSR058");
  const contactLengthError = t("Contact_Length");
  const textvalidate = () => {
    if (!userInput || (userInput && userInput.length <= 0)) {
      errorArray.push(nameError);
      seterrorArrayState(errorArray);
      return false; 
    }
    if (userInput.length < 2 || userInput.length > 60) {
      errorArray.push(contactLengthError);
      seterrorArrayState(errorArray);
      return false;
    }
    if (errorArray.length === 0) {
      if (isSurveyValue) {
        props.history.push("/SurveyConfirmationDetails");
      }
      else if (assetDetails.assetType !== "Property" && errorArray.length === 0) {
        props.history.push("/BlockCommunication");
      }
      else {
        props.history.push("/ConfirmationDetails");
      }
    }
    return true; 
  };
  const backButtonHandler = () => {
    dispatch(isEditState(false));
    if (isSurveyValue) {
      props.history.push("/SurveyLocation");
      return;
    }
    if (assetDetails.assetType === "Property") {
      
    if (hideVulnerablePage && assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true) {
        props.history.replace("/VandalismQuestion");
      }
      else if (isRepairMedia === "true" && hideVulnerablePage && !covidConfigValue) {
        props.history.push("/RepairUploadmedia");
      }
      else if (isRepairMedia === "false" && hideVulnerablePage && !covidConfigValue) {
        props.history.push("/RepairAdditionalDetails");
      }
      else {
        props.history.push("/vulnerabilityquestion");
      }
    } else if (priority !== "Emergency") {
      if (hideVulnerablePage !== undefined && !hideVulnerablePage) {
        props.history.push("/vulnerabilityquestion");
      }
      else if (assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true) {
        props.history.replace("/VandalismQuestion");
      }
      else if (isRepairMedia === "true") {
        props.history.push("/RepairUploadmedia");
      }
      else {
        props.history.push("/RepairAdditionalDetails");
      }
    }
    else {
      if (assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true) {
        props.history.replace("/VandalismQuestion");
      }
      else if (isRepairMedia === "true") {
        props.history.push("/RepairUploadmedia");
      }
      else {
        props.history.push("/RepairAdditionalDetails");
      }
    }
  };

  const exitButtonHandler = () => {
    dispatch(userChosenPropertyId(""));

    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(isEditState(false));

    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    seterrorArrayState([]);
    setCloseAfterSaving(true);
    if (!numberValidate()) {
      return;
    }
  
    dispatch(isEditState(false));
    dispatch(userChosenSaveExit(true));
  
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder));
  
    submitForm();
    
    props.history.push(
      `/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`
    );
  
    setTimeout(() => {
      dispatch(userChosenSaveExit(false));
    }, 6000);
  };

  const callPageHandler = () => {
    seterrorArrayState([]); 
  // Only call submitForm if numberValidate returns true
    if (numberValidate()) { 
      submitForm();
    }
  };

  const nameInputChangeHandler = (e: any) => {
    seterrorArrayState([]);
    const re = Helper.ValidationRegex.AlphabetWithSpecialChars;
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      setUserInput(e.target.value);
      dispatch(userChosenContactName(e.target.value));
      dispatch(userChosenRelationDetails(inputState))
    }
  };

  const submitForm = () => {
    try {
      setIsLoading(true);
      workOrderObject.reporterContactDetails = new ReportContactDetails();
      if (radiovalue !== radioText) {
        const radioUpdatedValue = radiovalue.split("@");

        workOrderObject.reporterContactDetails.contactNumber = radioUpdatedValue[0];
      } else {
        workOrderObject.reporterContactDetails.contactNumber =
          numberInput.length > 0 ? numberInput : null;
        dispatch(userChosenRelationDetails(inputState));
        dispatch(userChosenContactPhone(numberInput));
        dispatch(userChosenContactName(userInput));
        dispatch(userChosenContactDetails(radioText));
      }
      const contactPersonName = new CustomerName({
        title: null,
        givenName: radiovalue !== radioText ? (" ") : userInput,
        surname: null,
      });
      workOrderObject.reporterContactDetails.name =
        contactPersonName;

      workOrderObject.reportedById =
        RepairReporterId[inputState as keyof typeof RepairReporterId];
      workOrderObject.reportedBy = inputState;

      workOrderObject.reporterContactDetails.reporterToBeContacted = true;

      userChosenVulnerabilityQuestion !== "" &&
        userChosenVulnerabilityQuestion !== null &&
        userChosenVulnerabilityQuestion !== "No"
        ? (workOrderObject.isVulnerable = true)
        : (workOrderObject.isVulnerable = false);
      userChosenVulnerabilityAdditionalInformation !== ""
        ? (workOrderObject.VulnerableResponseText = userChosenVulnerabilityAdditionalInformation)
        : (workOrderObject.VulnerableResponseText = null);

      if (userChosenVandalismQuestion === "Yes") {
        workOrderObject.crimeReferenceNumber = userChosenVandalismAdditionalInformation;
        workOrderObject.repairCause = "Vandalism or Damage";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      }
      else if (isSurveyValue) {
        workOrderObject.repairCause = userChosenSurveyReason;
      }
      else {
        workOrderObject.crimeReferenceNumber = "";
        workOrderObject.repairCause = "General Wear and Tear";
        workOrderObject.repairCauseId =
          RepairCauseId[
          workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      }

      workOrderObject.priority = priorityCodeValue; 

      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters = _worksOrderRequest.updateWorkOrderParameter(
        workOrderObject
      );
      api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: { target: { value: any } }) => {
    setInputState(e.target.value);
    dispatch(userChosenRelationDetails(e.target.value));
  };

  let errorfieldsArray: any[] = [];
  errorArrayState?.forEach((err: any) => {
    errorfieldsArray.push(
      <div className="error">
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center text-black">
            <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
              {err}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <Container id="custDetails-container" role="main" className="ctn-fht">
      <div
        id="custDetails-back-div"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
      >
        <p id="custDetails-p" className="text-teal text-xl mt-4 mb-8">
          <a
            className="back-btn"
            data-testid="custDetailsBackBtn"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            {t("back")}
          </a>
        </p>
      </div>
      <div id="custDetails-main-div" className="container">
        <div
          id="custDetails-h1"
          className="my-4 text-purple-100 text-2xl mb-2 global-txt"
        >
          {isSurveyValue ? t("Survey.Survey_Customer_Details") : t("Customer_Details")}

        </div>
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={5000}
          />
        ) : (
          <>
            <div
              className="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3 d-flex flex-row repair-text"
              id="options"
            >
              <RadioGroup
                id="custDetails-radiogroup"
                onChange={updateSelection}
                value={radiovalue}
              >
                {!isNullOrUndefined(customerPhone) ? (customerPhone?.map((number: any, index: number) => {
                  return (
                    number.number && (
                      <fieldset>
                        <legend>
                          <FormControlLabel
                            id="custDetails-label1"
                            className="cust-rad global-txt"
                            key={"customerPhoneNumber_" + index}
                            control={
                              <Radio
                                id="custDetails-radio1"
                                key="checkboxPhoneNumber"
                                name="checkboxPhoneNumber"
                                data-testid="checkboxPhoneNumber"
                              />
                            }
                            label={number.number}
                            value={`${number.number}@${index}`}
                          />
                        </legend>
                      </fieldset>
                    )
                  );
                })) : null}
                <fieldset>
                  <legend>
                    <FormControlLabel
                      id="custDetails-label2"
                      className="cust-rad global-txt"
                      control={
                        <Radio
                          id="custDetails-radio2"
                          key="phoneNumberforRepair"
                          name="phoneNumberforRepair"
                          data-testid="phoneNumberforRepair"
                        />
                      }
                      label={isSurveyValue ? t("Survey.Survey_Provide_Number") : radioText}

                      value={radioText}
                    />{" "}
                  </legend>
                </fieldset>
              </RadioGroup>
            </div>

            <div id="custDetails-div1" className="row  pb-4 global-txt">
              <div
                id="custDetails-div2"
                className="col-lg-4 col-sm-12 pt-2 pt-lg-0"
              >
                <input
                  id="custDetails-text1"
                  className="inpt"
                  data-testid="inputPhoneNumber"
                  aria-label="phone number"
                  type="text"
                  disabled={isinputdisabled}
                  value={numberInput}
                  style={{ width: "100%" }}
                  placeholder={t("phone_placeholder_txt")}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </div>
              <div
                id="custDetails-div3"
                className="col-lg-4 col-sm-12 pt-2 pt-lg-0"
              >
                <input
                  id="custDetails-text2"
                  className="inpt"
                  type="text"
                  aria-label="customer name"
                  data-testid="customerName"
                  disabled={isinputdisabled}
                  value={userInput}
                  placeholder="Name"
                  style={{ width: "100%" }}
                  onChange={nameInputChangeHandler}
                />
              </div>

              <div id="custDetails-div4" className="col-lg-4 col-sm-12 pt-2">
                <select
                  data-testid="inputState"
                  style={{ height: 42 }}
                  id="inputState"
                  disabled={isinputdisabled}
                  className="form-select mt-10"
                  value={userChosenRelationSelect ?? inputState}
                  onChange={handleChange}
                  aria-label="Input State"
                >
                  <option
                    id="custDetails-opt1"
                    value={t("Customer_Option") as string}
                  >
                    {t("Customer_Option")}
                  </option>
                  <option
                    id="custDetails-opt2"
                    value={t("Friend_Option") as string}
                  >
                    {t("Friend_Option")}
                  </option>
                  <option
                    id="custDetails-opt3"
                    value={t("Other_Option") as string}
                  >
                    {t("Other_Option")}
                  </option>
                </select>
              </div>
            </div>
          </>
        )}
        {errorfieldsArray.length > 0 && (
          <div
            id="custDetails-div5"
            className="alert alert-warning error-reason mar-left global-txt error-left-padd p-0"
            role="alert"
            placeholder="alert"
          >
            {errorfieldsArray}
          </div>
        )}
      </div>
      <div
        id="custDetails-main-btn"
        className="flex justify-between items-center pb-4 md:pb-8 container"
      >
        <ExitModalAndSave
          id="custDetails-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
        <Button
          id="btn_custmerDetails"
          onClick={callPageHandler}
          className="button-class"
        >
          Continue
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};

export default CustomerDetails;
