import React from "react";
import { useTranslation } from "react-i18next";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { RepairHistory } from "../../models/Repair/Repair";


interface WorkOrderChronologyListProps extends RouteComponentProps<any> {
    repairHistoryDetails: RepairHistory[]
}
const WorkOrderChronologyListComponent: React.FC<WorkOrderChronologyListProps> = (props) => {
    const { t } = useTranslation();   
    return (                              
        <>
            {props.repairHistoryDetails.map(
                        (eachRepairUpdate: RepairHistory, key: any) => {
                            return (
                                <div className="border border-grey bg-grey rounded-lg mb-4">
                                    <div className="bg-tl-light px-4 py-2 rounded-t-lg">
                                        <div className="flex flex-row justify-between items-center">
                                            <p className="my-0 text-base"><strong>{eachRepairUpdate.statusTitle}</strong></p>
                                        </div>
                                    </div>

                                    <div className="py-2 grid grid-cols-1 global-txt">
                                        <div className="px-4 mb-2">
                                            <strong>{t("date")}</strong><br />  {moment(eachRepairUpdate.createDateTime).format(
                                                "DD-MM-YYYY, h:mm:ss A"
                                            )}
                                        </div>
                                        <div className="border-b mb-2 mt-2 border-grey"></div>
                                        <div className="px-4 ">
                                            <strong>{t("comment")}</strong><br />{eachRepairUpdate.comments}
                                        </div>

                                    </div>
                                </div>


                            )
                        }
                    )
                    }
        </>
)
}

export default WorkOrderChronologyListComponent;