import { CreateArrearsDataType } from '../../../models/UCC/UniversalCreditCard';
import * as api from '../../../api';
import {
  LOGIN_STATUS, CUSTOMER_DATA,
  USERCHOSEN_PROPERTY_ID,
  USERCHOSEN_PROPERTY_TYPE,
  USERCHOSEN_PROPERTY_ADDRESS,
  USERCHOSEN_REPAIR_LOCATION,
  USERCHOSEN_HIGH_LEVEL_DESCRIPTION,
  USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  USERCHOSEN_EMERGENCY_QUESTION,
  USERCHOSEN_DUPLICATE_QUESTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT,
  USERCHOSEN_VANDALISM_QUESTION,
  USERCHOSEN_COVID_QUESTION,
  USERCHOSEN_VULNERABILITY_QUESTION,
  USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_DETAILS,
  USERCHOSEN_NOTIFYALL,
  USERCHOSEN_UPRN,
  USERCHOSEN_SOR,
  USERCHOSEN_STATUS,
  USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_NAME,
  USERCHOSEN_CONTACT_PHONE,
  USERCHOSEN_RELATION_DETAILS,
  USERCHOSEN_REPAIR_LOCATION_ID,
  USERCHOSEN_ELIGIBILITY_ERRORCODE,
  LOGIN_TIME,
  REFRESH_TOKEN,
  REFRESS_TOKEN_EXPIRE_TIME,
  ACCESS_TOKEN_EXPIRE_TIME,
  INITIAL_LOGIN_TIME,
  UPDATE_PREV_CONFIRMED_STATE,
  UPDATE_ROUTE_HISTORY,
  UPDATE_USER_CHOICES_WITH_NEW_CHANGE,
  Is_Edit_State,
  USERCHOSEN_BANLANCE_TYPE,
  USERCHOSEN_ACCOUNT_NUMBER,
  USERCHOSEN_BALANCE,
  USERCHOSEN_ACCOUNT_Type,
  USERCHOSEN_Other_BALANCE,
  USER_PROFILE_ETAG,
  USER_PROFILE_DATA,
  Allpay_Conformation_Message,
  USERCHOSEN_ERROR_CODE,
  USERCHOSEN_ISELIGIBLE,
  RESUME_DRAFT_REPAIR,
  USER_REPAIR_WORKORDER,
  USER_RESUME_DATA,
  USER_RESUME_DRAFT_DATA_CACHE,
  USERCHOSEN_ISREPORTER,
  USERCHOSEN_ISERROR,
  Is_Active_Tenancy, GET_ASSET_LIST,
  DEFAULT_ASSET_ID, SEARCH_TEXT_COUNT_VALUE, PREMISES_TYPE_LIST, USERCHOSEN_TENANCY_GROUP, USERCHOSEN_ACCOUNT_List, WORK_ORDER_Data, USER_PROFILE_PERMISSION, USERCHOSEN_SEARCHTYPE, USERCHOSEN_SEARCHTEXT, USERCHOSEN_BACKBUTTON, USERCHOSEN_RAISEDUPTO, USERCHOSEN_RAISEDSINCE, USERCHOSEN_CUSTOMER_ACCOUNT_List, CUSTOMER_LIST,
  ASSET_DATA_STATUS, ASSET_DATA_RESPONSE, WORK_ORDER_RESPONSE, LOCAL_AUTHORITY, KEY_CONTACT, CUSTOMER_ID, USERCHOSEN_BACK_VALUE, USERCHOSEN_ALERT_VALUE, USERCHOSEN_ASSET_HIERARCHY_360, COMPONENT_INFO, TRANSACTION_INFO, HIERARCHY_OF_PREMISES, CUSTOMER_PREFERENCE_DATA,
  ASSET_PROPERTY_ID,
  APP_CONFIG_VANDALISM_VALUE, ASSET_REPAIR_CHECK, REPAIR_ADDRESS_DATA, BACK_TO_REPAIR_ASSET, USERCHOSEN_ASSETVALUE, WORKORDER_LIST, PROPERTY_LIST, USERCHOSEN_ISSEARCH, USERCHOSEN_ISRECALL, USERCHOSEN_CURRENT_VALUE, UCC_TENANCY_MODIFIED_DATA, UCC_RENT_MODIFIED_DATA, ASSETS_BY_ID_DATA, CREATE_ARREARS_RESPONSE, CATALYST_FLAG, ASSET_CHECK_FOR_CATALYST, CUSTOMER_CHECK_FOR_CATALYST, USERCHOSEN_SAVEEXIT, USERCHOSEN_DUPLICATE_ENTITY, USER_CREATED_NEW_REPAIR, LOAD_DUPLICATE_REPAIR_PAGE, USERCHOSEN_RESUME_REPAIR, WORK_ORDER_Data_Original,
  CASE_ASSET_ADDRESS, BACK_TO_CASE_ASSET, CASE_DATA, CASE_LIST, USERCHOSEN_CONTACT_OPTION, USER_PROFILE_CASE_CREATION_PERMISSION, USERCHOSEN_TENANCYCOUNT, BACK_TO_CASE_SELECT_CUSTOMER, USERCHOSEN_REPORTER_ID
 , IS_CHECK_FOR_LANDLORD,CASE_CREATED_BY, ASSOCIATED_ASSETS, No_UNIT, CUSTOMER_ADDITIONAL_ALERT_DATA, IS_CASE_ALLOWED, UPLOADED_MEDIA_ITEMS, CLEAR_MEDIA_FILES, DELETE_MEDIA_ITEM, USERCHOSEN_DELETE_MEDIA, USERCHOSEN_UPLOADED_MEDIA, HAS_MEDIA_ATTACHED, FROM_MEDIA_PAGE, MEDIA_RESUME, IS_CASE_MEDIA_ALLOWED, USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS,IS_REPAIR_MEDIA_ALLOWED,IS_REPAIR_COVID_ALLOWED,
 IS_REPAIR_MEDIA_RESUME,IS_CATALYST_CUSTOMER,CATALYST_STATUS, USERCHOSEN_ISSURVEY,SURVEY_REASON,REPAIR_TYPE,USER_TYPED_REPAIR_REASONS, CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION,WORKORDER_CHRONOLOGY_TAB,IS_P360_REPAIRS_MAINTAINENCE,DEMOBILIZED_CONTRACTOR_SITES,
 USER_SELECTED_TENANCY_GROUP,
 USER_EMAIL
} from "../actionTypes";

export const isRepairMediaResume = (payload: any) => ({
  type: IS_REPAIR_MEDIA_RESUME,
  payload,
});
export const customerAdditionalAlertData = (payload: any) => ({
  type: CUSTOMER_ADDITIONAL_ALERT_DATA,
  payload,
});
export const userChosenReporterId = (payload: any) => ({
  type: USERCHOSEN_REPORTER_ID,
  payload,
});
export const backToCaseSelectCustomer = (payload: any) => ({
  type: BACK_TO_CASE_SELECT_CUSTOMER,
  payload,
});
export const caseCreatedBy = (payload: any) => ({
  type: CASE_CREATED_BY,
  payload,
});
export const userChoiceRepairReasons = (payload: any) => ({
  type: USER_TYPED_REPAIR_REASONS,
  payload,
});
export const userTypedSurveyReason = (payload: any) => ({
  type: SURVEY_REASON,
  payload,
});
export const userChosenSurveyReason = (payload: any) => ({
  type: SURVEY_REASON,
  payload,
});
export const userTypedRepairType = (payload: any) => ({
  type: REPAIR_TYPE,
  payload,
});
export const caseAssetAddress = (payload: any) => ({
      type: CASE_ASSET_ADDRESS,
      payload,
});
export const backToCaseAsset = (payload: any) => ({
      type: BACK_TO_CASE_ASSET,
      payload,
});
export const caseData = (payload: any) => ({
  type: CASE_DATA,
  payload,
});
export const caseList = (payload: any) => ({
  type: CASE_LIST,
  payload,
});
  export const backToRepairAsset = (payload: any) => ({
    type: BACK_TO_REPAIR_ASSET,
    payload,
  });
  export const repairAddressData = (payload: any) => ({
    type: REPAIR_ADDRESS_DATA,
    payload,
  });
  export const repairAsset = (payload: any) => ({
    type: ASSET_REPAIR_CHECK,
    payload,
  });
  export const assetRepairCheck = (payload: any) => ({
    type: ASSET_REPAIR_CHECK,
    payload,
  });
  export const assetPropertyId = (payload: any) => ({
    type: ASSET_PROPERTY_ID,
    payload,
  });
    export const customerPreferenceData = (payload: any) => ({
      type: CUSTOMER_PREFERENCE_DATA,
      payload,
    });
  export const keyContact = (payload: any) => ({
    type: KEY_CONTACT,
    payload,
  });
  export const localAuthority = (payload: any) => ({
    type: LOCAL_AUTHORITY,
    payload,
  });
  export const assetDataResponse = (payload: any) => ({
    type: ASSET_DATA_RESPONSE,
    payload,
  });
  export const assetDataStatus = (payload: any) => ({
    type: ASSET_DATA_STATUS,
    payload,
  });
  export const getWorkOrderObject = (payload: any) => ({
    type: WORK_ORDER_RESPONSE, 
    payload,
  });
  export const customerList = (payload: any) => ({
    type: CUSTOMER_LIST,
    payload,
  });
  export const workList = (payload: any) => ({
    type: WORKORDER_LIST,
    payload,
  });
  export const propertyListData = (payload: any) => ({
    type: PROPERTY_LIST,
    payload,
  });
  export const workOrderData = (payload: any) => ({
    type: WORK_ORDER_Data,
    payload,
  });
  export const originalWorkOrderData = (payload: any) => ({
    type: WORK_ORDER_Data_Original,
    payload,
  });
  export const catalystStatus = (payload: any) => ({
    type: CATALYST_STATUS,
    payload,
  });
  export const premisesTypeList = (payload: any) => ({
    type: PREMISES_TYPE_LIST,
    payload,
  });
  export const searchTextCountValue = (payload: any) => ({
    type: SEARCH_TEXT_COUNT_VALUE,
    payload,
  });
  export const defaultAssetId = (payload: any) => ({
    type: DEFAULT_ASSET_ID,
    payload,
  });

export const getAssetList = (payload: any) => ({
  type: GET_ASSET_LIST,
  payload,
});
export const loginStatus = (payload: any) => ({
  type: LOGIN_STATUS,
  payload,
});

export const customersData = (payload: any) => ({
  type: CUSTOMER_DATA,
  payload,
});

export const userChosenPropertyId = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_ID,
  payload,
});

export const userChosenPropertyType = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_TYPE,
  payload,
});
export const userChosenIsRecall = (payload: any) => ({
  type: USERCHOSEN_ISRECALL,
  payload,
});

export const userChosenPropertyAddress = (payload: any) => ({
  type: USERCHOSEN_PROPERTY_ADDRESS,
  payload,
});

export const userChosenRepairLocation = (payload: any) => ({
  type: USERCHOSEN_REPAIR_LOCATION,
  payload,
});

export const userChosenHighLevelDescription = (payload: any) => ({
  type: USERCHOSEN_HIGH_LEVEL_DESCRIPTION,
  payload,
});

export const userChosenSupplementaryQuestions = (payload: any) => ({
  type: USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  payload,
});

export const userChosenEmergencyQuestion = (payload: any) => ({
  type: USERCHOSEN_EMERGENCY_QUESTION,
  payload,
});

export const userChosenDuplicateQuestion = (payload: any) => ({
  type: USERCHOSEN_DUPLICATE_QUESTION,
  payload,
});

export const userChosenAdditionalDescription = (payload: any) => ({
  type: USERCHOSEN_ADDITIONAL_DESCRIPTION,
  payload,
});

export const userChosenAdditionalDescriptionCOUNT = (payload: any) => ({
  type: USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT,
  payload,
});

export const userChosenVandalismQuestion = (payload: any) => ({
  type: USERCHOSEN_VANDALISM_QUESTION,
  payload,
});
export const userChosenNotifyAll = (payload: any) => ({
  type: USERCHOSEN_NOTIFYALL,
  payload,
});
export const userChosenCustomerID = (payload: any) => ({
  type: CUSTOMER_ID,
  payload,
});
export const userChosenBackValue = (payload: any) => ({
  type: USERCHOSEN_BACK_VALUE,
  payload,
});
export const userChosenCurrentValue = (payload: any) => ({
  type: USERCHOSEN_CURRENT_VALUE,
  payload,
});
export const userChosenAlertValue = (payload: any) => ({
  type: USERCHOSEN_ALERT_VALUE,
  payload,
});
export const userChosenAssetHierarchy360 = (payload: any) => ({
  type: USERCHOSEN_ASSET_HIERARCHY_360,
  payload,
});
export const userChosenSearchType = (payload: any) => ({
  type: USERCHOSEN_SEARCHTYPE,
  payload,
});
export const userChosenRaisedSince = (payload: any) => ({
  type: USERCHOSEN_RAISEDSINCE,
  payload,
});
export const userChosenRaisedUpto = (payload: any) => ({
  type: USERCHOSEN_RAISEDUPTO,
  payload,
});
export const userChosenSearchText = (payload: any) => ({
  type: USERCHOSEN_SEARCHTEXT,
  payload,
});
export const userChosenBackButton = (payload: any) => ({
  type: USERCHOSEN_BACKBUTTON,
  payload,
});
export const userChosenVandalismAdditionalInformation = (payload: any) => ({
  type: USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  payload,
});
export const userChosenCovidQuestion = (payload: any) => ({
  type: USERCHOSEN_COVID_QUESTION,
  payload,
});

export const userChosenVulnerabilityQuestion = (payload: any) => ({
  type: USERCHOSEN_VULNERABILITY_QUESTION,
  payload,
});

export const userChosenVulnerabilityAdditionalInformation = (payload: any) => ({
  type: USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  payload,
});

export const userChosenContactDetails = (payload: any) => ({
  type: USERCHOSEN_CONTACT_DETAILS,
  payload,
});

export const userChosenContactOption = (payload: any) => ({
    type: USERCHOSEN_CONTACT_OPTION,
    payload,
});

export const userChosenContactName = (payload: any) => ({
  type: USERCHOSEN_CONTACT_NAME,
  payload,
});

export const userChosenContactPhone = (payload: any) => ({
  type: USERCHOSEN_CONTACT_PHONE,
  payload,
});

export const userChosenRelationDetails = (payload: any) => ({
  type: USERCHOSEN_RELATION_DETAILS,
  payload,
});

export const userChosenUPRN = (payload: any) => ({
  type: USERCHOSEN_UPRN,
  payload,
});

export const userChosenSOR = (payload: any) => ({
  type: USERCHOSEN_SOR,
  payload,
});

export const userChosenSTATUS = (payload: any) => ({
  type: USERCHOSEN_STATUS,
  payload,
});

export const userChosenEligibilityErrorCode = (payload: any) => ({
  type: USERCHOSEN_ELIGIBILITY_ERRORCODE,
  payload,
});

export const userChosenRepairLocationId = (payload: any) => ({
  type: USERCHOSEN_REPAIR_LOCATION_ID,
  payload,
});
export const loginTime = (payload: any) => ({
  type: LOGIN_TIME,
  payload,
});
export const initialLoginTime = (payload: any) => ({
  type: INITIAL_LOGIN_TIME,
  payload,
});
export const refershToken = (payload: any) => ({
  type: REFRESH_TOKEN,
  payload,
});
export const isSearch = (payload: any) => ({
  type: USERCHOSEN_ISSEARCH,
  payload,
});

export const accessTokenExpirationTime = (payload: any) => ({
  type: ACCESS_TOKEN_EXPIRE_TIME,
  payload,
});
export const refreshTokenExpirationTime = (payload: any) => ({
  type: REFRESS_TOKEN_EXPIRE_TIME,
  payload,
});

export const updatePrevConfirmedState = (payload: any) => ({
  type: UPDATE_PREV_CONFIRMED_STATE,
  payload,
});

export const isEditState = (payload: any) => ({
  type: Is_Edit_State,
  payload,
});
export const isActiveTenancy = (payload: any) => ({
  type: Is_Active_Tenancy,
  payload,
});

export const updateRouteHistory = (payload: any) => ({
  type: UPDATE_ROUTE_HISTORY,
  payload,
});

export const resetUserChoicesWithNewChange = (payload: any) => ({
  type: UPDATE_USER_CHOICES_WITH_NEW_CHANGE,
  payload,
});
export const userChoicesPermissions = (payload: any) => ({
  type: USER_PROFILE_PERMISSION,
  payload,
});
export const createEnhancedHubRepairPermissions = (payload: any) => ({
  type: CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION,
  payload,
});
export const createCasePermissions = (payload: any) => ({
    type: USER_PROFILE_CASE_CREATION_PERMISSION,
    payload,
});
export const componentInformation = (payload: any) => ({
  type: COMPONENT_INFO,
  payload,
});
export const transactionInformation = (payload: any) => ({
  type: TRANSACTION_INFO,
  payload,
});
export const hierarchyOfPremises = (payload: any) => ({
  type: HIERARCHY_OF_PREMISES,
  payload,
});
export const userChosenBalanceType = (payload: any) => ({
  type: USERCHOSEN_BANLANCE_TYPE,
  payload,
});
export const userChosenAccountNumber = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_NUMBER,
  payload,
});
export const userChosenAccountType = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_Type,
  payload,
});
export const userChosentenancyGroup = (payload: any) => ({
  type: USERCHOSEN_TENANCY_GROUP,
  payload,
});
export const userChosenAccountData = (payload: any) => ({
  type: USERCHOSEN_ACCOUNT_List,
  payload,
});
export const userChosenCustomerAccountData = (payload: any) => ({
  type: USERCHOSEN_CUSTOMER_ACCOUNT_List,
  payload,
});
export const userChosenBalance = (payload: any) => ({
  type: USERCHOSEN_BALANCE,
  payload,
});
export const userChosenOtherBalance = (payload: any) => ({
  type: USERCHOSEN_Other_BALANCE,
  payload,
});
export const userProfileETag = (payload: any) => ({
  type: USER_PROFILE_ETAG,
  payload,
});
export const userProfileData = (payload: any) => ({
  type: USER_PROFILE_DATA,
  payload,
});
export const AllpayConformationMessage = (payload: any) => ({
  type: Allpay_Conformation_Message,
  payload,
});
export const userChosenErrorCode = (payload: any) => ({
  type: USERCHOSEN_ERROR_CODE,
  payload,
});
export const userChosenIseligible = (payload: any) => ({
  type: USERCHOSEN_ISELIGIBLE,
  payload,
});
export const resumeDraftRepair = (payload: any) => ({
  type: RESUME_DRAFT_REPAIR,
  payload,
});
export const userRepairWorkOrder = (payload: any) => ({
  type: USER_REPAIR_WORKORDER,
  payload,
});
export const userResumeData = (payload: any) => ({
  type: USER_RESUME_DATA,
  payload,
});
export const userResumeDraftDataCache = (payload: any) => ({
  type: USER_RESUME_DRAFT_DATA_CACHE,
  payload,
});
export const userChosenIsReporter = (payload: any) => ({
  type: USERCHOSEN_ISREPORTER,
  payload,
});
export const userChosenIsError = (payload: any) => ({
  type: USERCHOSEN_ISERROR,
  payload,
});
export const userChosenAssetValue = (payload: any) => ({
  type: USERCHOSEN_ASSETVALUE,
  payload,
});
export const userChosentenancyCount = (payload: any) => ({
  type: USERCHOSEN_TENANCYCOUNT,
  payload,
});
export const appConfigVandalismToggle = (payload: any): any => {
  return { type: APP_CONFIG_VANDALISM_VALUE, payload };
};


export const userChosenResumeRepair = (payload: any) => ({
  type: USERCHOSEN_RESUME_REPAIR,
  payload,
});
export const userChosenAssociatedAssetsList = (payload: any) => ({
  type: ASSOCIATED_ASSETS,
  payload,
});
export const loadDuplicateRepairPage = (payload: any) => ({
  type: LOAD_DUPLICATE_REPAIR_PAGE,
  payload,
});
export const noUnit = (payload: any) => ({
  type: No_UNIT,
  payload,
});
export const userCreatedNewRepair = (payload: any) => ({
  type: USER_CREATED_NEW_REPAIR,
  payload,
});
export const userChosenDuplicateEntity = (payload: any) => ({
  type: USERCHOSEN_DUPLICATE_ENTITY,
  payload,
});

export const userChosenSaveExit = (payload: any) => ({
  type: USERCHOSEN_SAVEEXIT,
  payload,
});

export const uCCTenancyModifiedData = (payload: any) => {
  return { type: UCC_TENANCY_MODIFIED_DATA, payload }
}
export const uCCRentModifiedData = (payload: any) => {
  return { type: UCC_RENT_MODIFIED_DATA, payload }
}
export const assetsByIDData = (payload: any) => {
  return { type: ASSETS_BY_ID_DATA, payload }
}
export const createArrearsResponse = (payload: any) => {
  return { type: CREATE_ARREARS_RESPONSE, payload }
}
export const checkForLandlord = (payload: any) => {
  return {type: IS_CHECK_FOR_LANDLORD, payload}
}
export const isCatalystProperty = (payload: any) => {
  return {type: IS_CATALYST_CUSTOMER, payload}
}
export const isCaseAllowed = (payload: any) => ({
  type: IS_CASE_ALLOWED,
  payload,
});
export const userChosenIsSurvey = (payload: any) => ({
  type: USERCHOSEN_ISSURVEY,
  payload,
});
export const saveUCCCreateArrearsAction = (createArrearsData: any, props: any) => {
  return (dispatch: any) => {
    (async () => {
      await api.postUCCCreateArrearsAction(createArrearsData)
        .then((response) => {
          props.history.push('/LandingView');
        })
        .catch((error) => {
          dispatch(createArrearsResponse(`API Failed!!!`));
        })
    })()
  }
}



export const storeMediaFilesId = (payload: any) => ({
  type: UPLOADED_MEDIA_ITEMS,
  payload,
});

export const clearMediaFiles = (payload: any) => ({
  type: CLEAR_MEDIA_FILES,
  payload,
});

export const deleteMediaItem = (payload: any) => ({
  type: DELETE_MEDIA_ITEM,
  payload,
});

export const userChosenDeletedMedia = (payload: any) => ({
  type: USERCHOSEN_DELETE_MEDIA,
  payload,
});

export const userChosenUploadedMedia = (payload: any) => ({
  type: USERCHOSEN_UPLOADED_MEDIA,
  payload,
});

export const hasMediaAttached = (payload: any) => ({
  type: HAS_MEDIA_ATTACHED,
  payload,
});

export const fromMediaUpload = (payload: any) => ({
  type: FROM_MEDIA_PAGE,
  payload,
});

export const isMediaResume = (payload: any) => ({
  type: MEDIA_RESUME,
  payload,
});

export const isCaseMediaAllowed = (payload: any) => ({
  type: IS_CASE_MEDIA_ALLOWED,
  payload,
});

export const userChosenUploadedMediaIdentifiers = (payload: any) => ({
  type: USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS,
  payload,
});
export const isRepairMediAllowed = (payload: any) => ({
  type: IS_REPAIR_MEDIA_ALLOWED,
  payload,
});
export const isRepairCovidAllowed = (payload: any) => ({
  type: IS_REPAIR_COVID_ALLOWED,
  payload,
});

export const woChronologyTabState = (payload: any) => ({
  type: WORKORDER_CHRONOLOGY_TAB,
  payload,
});
export const isP360RepairsMaintainence = (payload: any): any => {
  return { type: IS_P360_REPAIRS_MAINTAINENCE, payload };
};

export const demobilizedContractorSites = (payload: any): any => {
  return { type: DEMOBILIZED_CONTRACTOR_SITES, payload };
};
export const selectedTenancyGroup = (payload:any) => ({
  type: USER_SELECTED_TENANCY_GROUP,
  payload
});

export const setUserEmail = (payload: string) => ({
  type: USER_EMAIL,
  payload,
});