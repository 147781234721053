import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom';
import { LoginButton, LoginWrapper } from './components'
import { useDispatch } from "react-redux";
import { loginStatus, userChosenBackButton } from '../../ducks/redux/actions/userChoices';
import Button from '../../Button/Button';
import "./index.css"
const urlParams=new URLSearchParams(window.location.search);
interface LoginProps extends RouteComponentProps<any> { 
}

const Login: React.FC<LoginProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
 const RedirectToIDAMLoginPage=()=> {
  const urlParams=new URLSearchParams(window.location.search);
  const adLoginUrl: string = (urlParams.has('redirectUrl') ? `${process.env.REACT_APP_CSS_AD_LOGIN_URL ?? ''}${urlParams.get('redirectUrl')}` : process.env.REACT_APP_CSS_AD_LOGIN_URL ?? '') || '';
  dispatch(userChosenBackButton(false));
  window.location.href = adLoginUrl;
 }
   
  useEffect(() => {
  if(localStorage.getItem('userName'))
   {  
     props.history.push('/LandingView');
   }
   else {
    dispatch(loginStatus(false));   
   }
  });
  

  return (
    <React.Fragment>
      <div className="sm:mx-1 container ctn-fht  pb-12 l-mt wd-div ml-log-inpg">
            <div className="ss" id="repreason-div1">
              <div className="log">
              {t("colleague_digital_Platform")}
              </div>
              <div className="log-in-text mt-2">
              {t("CP129")}
              </div>
            </div>
        
              <Button
                id="Login"
                type="button"
                onClick={RedirectToIDAMLoginPage}
                className= "mt-4">
                 {/* className= {window.innerWidth===896? "": "mt-4"}               */}
                {t('login_button')}
                <i className="fas fa-angle-right arrow"></i>
              </Button>
              </div>
  </React.Fragment>
  )
 }


export default Login