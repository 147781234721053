import { withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MuiCloseIcon from '@material-ui/icons/Close'
import MuiSearchIcon from '@material-ui/icons/Search'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'

const { primaryText } = colors

export const SearchInput = withStyles({
  input: {
    height: '1rem',
    '&::-ms-clear': {
      display: 'none',
    }
  },
  notchedOutline: {
    borderColor: `none`
  }
})(OutlinedInput)

export const SearchIcon = withStyles({
  root: {
    cursor: 'pointer',
    width: '1.5rem !important',
    height: '1.5rem !important',
    color: 'rgba(109, 117, 130, 1) !important'
  },
})(MuiSearchIcon || MuiCloseIcon)

export const CloseIcon = () => (
  <IconButton size="small" className='mn-btn' aria-label="reset search" data-bdd="search-clear">
    <MuiCloseIcon />
  </IconButton>
)

export const CloseWrapper = styled.div`
  width: 2.5rem;
`
