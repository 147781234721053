export class TenancyDataTypes {
  public paymentReference: string = ''
  public tenancyType: string = ''
  public joinTenant: any[] = []
  public tenancyStartDate: string = ''
  public NoOfBedrooms: number = 0
  public untidyTenancy: string = ''
  public NoOfLiveRentAccount: number = 0
  public isTenancyActive: boolean = false
  public postalAddresses: {} = {}
  public assetIdentifier: string = "" 
  public customerIdentifier: string = ""
  public showCreateArrearsReminder: boolean = false
}

export class RentDataTypes {
  public AccountId: string = '-'
  public RentFreeWeeks: string = 'No'
  public RentEffectiveFrom: any = '-'
  public RentAmount: string = '-'
  public RentFrequency: string = '-'
  public ServiceCharges: string = '-'
  public ServiceChargeAmount: string = '-'
  public UCFlagExist: string = '-'
}

export class CreateArrearsDataType {
  public accountId: string = ""
  public payRef: string = ""
  public transactionDate: Date | string = ""
  public noteTypeId: string = "UNIVERSALCREDITCARD" //Change property to "noteTypeId": "UNIVERSALCREDITCARD",
  public transactionAmount: string = ""
  public paymentChannel: string = ""
  public paymentProvider: string = ""
  public customerIdentifier: string = ""
  public text: string = ""
  public propertyAddress: string = "" 
  public assetIdentifier: string = "" 
}

export class UntidyTenancyTypes {
  tenancyId: string = ""
  uprn: string = ""
  noteType: string = ""
}


export interface TenancyType {
  displayUC: boolean
  id: string
  temporaryAccommodation: boolean
  tenancyStatus: string
  tenancyType: string
  tenancyTypeID: string
}

