import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { formatDateWithFromCalendar, isNullOrUndefined, formathourminutes,formatContent,retrievePriority } from "../../utils/formatting";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import {  Pagination, Paginationbox } from "../../compositions/PropertyResult/component";
import InfiniteScroll from "react-infinite-scroll-component"
import { WorksOrder } from "../../models/WorksOrders"
import { WorkOrderSortableFields } from "../../models/WorksOrders/WorkOrderSortableFields"
import { RepairChannel } from "../../models/Repair/RepairChannel";
import { useDispatch, useSelector } from "react-redux";
import { workList, workOrderData } from "../../ducks/redux/actions/userChoices";
import { RepairStatusField } from "../../models/Repair/Repair";
import { SurveyTypes } from "../../models/shared/Enum";
import useBackToTop from '../BackToTop/useBackToTop'
import BackToTopComponent from "../BackToTop";

interface WorkOrderResultProps extends RouteComponentProps<any> {
    searchText?: string;
    searchTextCount?: number;
    handleSort?: any;
    sortValue?: any;
    status?: any;
    priority?: any;
    propertyType?: any;
    dateRange?: any;
    raisedSince?: any;
    raisedUpto?: any;
    setShowFilter?: boolean;
}
const WorkOrderResultComponent: React.FC<WorkOrderResultProps> = (props) => {
    const array: WorksOrder[] = [];
    const WorkOrderData = useSelector(
        (state: any) => state?.userChoicesReducer?.workOrderList
    );
    const IsSearch = useSelector(
        (state: any) => state?.userChoicesReducer?.IsSearch
    );
    const [workOrderList, setworkOrderList] = useState(array);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] = useState(isNullOrUndefined(props.sortValue) ? "raisedDate desc" : props.sortValue);
    const searchTextCountValue = !props.searchTextCount ? 0 : props.searchTextCount;
    const searchTextValue = !props.searchText ? '' : props.searchText;
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const { width, backToTop } = useBackToTop();
    const timeValue = formathourminutes();
    const raisedSinceDate = !props.raisedSince ? '' : props.raisedSince + 'T0' + timeValue[0] + ':' + timeValue[1] + ':00Z';
    const raisedUptoDate = !props.raisedUpto ? '' : props.raisedUpto + 'T' + (23 - timeValue[0]) + ':' + timeValue[2] + ':00Z';

    useEffect(() => {
        (async () => {
            if (!checkSearchCriteria()) {
                setIsLoading(false);
                return;
            }
            if (!props.setShowFilter && IsSearch) {
                setIsLoading(true);
                const queryParams = {
                    propertiesIds: '',
                    limit: 10,
                    skip: 0,
                    searchText: props.searchText,
                    orderBy: defaultSortOrder,
                    repairType: props.status == RepairStatusField.Assignedtosurveyor ? "survey" : '',
                    status: props.status == RepairStatusField.UnderReview ? "" : props.status,
                    priority: props.priority,
                    raisedSince: raisedSinceDate,
                    raisedUpto: raisedUptoDate,
                    assetType: props.propertyType,
                    isTriage: props.status == RepairStatusField.UnderReview ? true : ""
                };

                getWorkOrderData(queryParams);
            }
            else {
                setworkOrderList(WorkOrderData?.worksOrders);
                setTotalCount(WorkOrderData?.totalCount);
                setIsLoading(false);
            }
        })();
    }, []);

    const getWorkOrderData = (queryParams: any) => {
        (async () => {
            try {
                const data = await api.getWorksOrdersApi(queryParams);
                dispatch(workList(data));
                setworkOrderList(data?.worksOrders);
                setTotalCount(data?.totalCount);
                setIsLoading(false);


            } catch (e) {
                setIsLoading(false);
                console.error(e);
                props.history.push("/GenericError");
            }
        })();
    } 

    const updateWorkOrderData = (searchText: any, limit: any, skip: any, orderBy: any) => {
        (async () => {
            setIsLoading(true);
            const queryParams = {
                propertiesIds: '',
                searchText: searchText,
                limit: limit,
                skip: skip,
                orderBy: orderBy,
                status: props.status == RepairStatusField.UnderReview ? "" : props.status,
                priority: props.priority,
                raisedSince: raisedSinceDate,
                raisedUpto: raisedUptoDate,
                assetType: props.propertyType,
                isTriage: props.status == RepairStatusField.UnderReview ? true : "",
            };

            getWorkOrderData(queryParams);
        })();
    }

    const getValidationMessage = () => {
        if (isNullOrUndefined(props.searchText))
            return "blank_search_criteria";

        if ((/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1))))
            return "invalid_search_criteria";

        if (props.searchText?.includes('*')
            && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue)
            return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

        if (searchTextValue?.length < searchTextCountValue)
            return t("minimum_number_of_characters");

        if (props.status === RepairStatusField.Assignedtosurveyor)
            return t('SSR026');

        return "workorder_search_no_results";
    }

    const checkSearchCriteria = () => {
        if (isNullOrUndefined(props.searchText) || (/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1)))
            || (props.searchText?.includes('*') && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue) || searchTextValue?.length < searchTextCountValue)
            return false;

        return true;
    }


    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setCurrentPage(page);
        setSkip(resultsPerPage * page);
        updateWorkOrderData(props.searchText, resultsPerPage, resultsPerPage * page, defaultSortOrder);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setResultsPerPage((e.target as any).value);
        setSkip(0);
        setCurrentPage(0);
        updateWorkOrderData(props.searchText, (e.target as any).value, 0, defaultSortOrder);
    };

    const fetchData = () => {
        let skipResult = 0;
        let totalValue = 0;
        if (totalCount > resultsPerPage) {
            if (skip === 0 && resultsPerPage < 998) {
                skipResult = 0
                totalValue = 999
                setSkip(0);
            }
            else {
                skipResult = resultsPerPage
                totalValue = 999 + skipResult
                setSkip(skipResult);
            }
            setResultsPerPage(totalValue);
            updateWorkOrderData(props.searchText, totalValue, skipResult, defaultSortOrder);
        }
    };

    const callWorkOrder360 = (workOrder: any) => {
        dispatch(workOrderData(workOrder))
        props.history.push(`/WorkOrder360View/${workOrder.identifiers.worksOrderIdentifier}`);
    }

    const displayWorkOrderResult = () => {
        return(
            <div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr global-txt" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto">

                                {!workOrderList?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t(getValidationMessage())}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left ml-2"><strong>{totalCount}</strong> {t("total")}</div>
                                            </div>

                                        </div>

                                        {workOrderList.map((workorder) => (
                                            <div className="w-full desk-view-only py-2">
                                                <div className="border border-grey bg-grey rounded-lg">
                                                    <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <div className="my-0 text-lg">
                                                                {
                                                                    <a data-testid={"WorkOrder360Link" + workorder.identifiers?.worksOrderIdentifier} className="my-0 text-lg text-black cursor-pointer" onClick={() => callWorkOrder360(workorder)}> <strong>{t("workOrder")}  {`${workorder.identifiers?.jobNumber}`}</strong></a>}

                                                            </div>
                                                            <div className="my-0 text-base">
                                                                <strong>{(workorder.job?.repairType && workorder.job?.repairType === SurveyTypes.survey) ? t('Survey.Survey') : (workorder.job?.workProgramme != null && workorder.job?.workProgramme !== "" && workorder.job?.workProgramme !== undefined) ? t(workorder.job?.workProgramme) : t("RESP")}</strong>

                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                        <div className="mb-2">
                                                            <strong>{t("High_Level_Description")}</strong>{" "}
                                                            <br></br>
                                                            {workorder.job?.trade == "Unknown" &&
                                                                workorder.channel != RepairChannel.CustomerSelfService &&
                                                                workorder.channel != RepairChannel.ERGO &&
                                                                workorder.channel != RepairChannel.ColleagueSelfServe
                                                                ? t("RepairDescription_ErrorMessage")
                                                                : (workorder.channel === RepairChannel.CustomerSelfService ||
                                                                    workorder.channel === RepairChannel.ERGO || workorder.channel === RepairChannel.ColleagueSelfServe)
                                                                    ? workorder.job?.description?.split(".")[0]
                                                                    : workorder.job?.trade}

                                                        </div>

                                                        <div className="mb-2">
                                                            <strong>{t("address_txt")}</strong>
                                                            <br></br>
                                                            {`${isNullOrUndefined(workorder?.asset?.addresses?.postalAddresses?.formattedAddress) ? "" : workorder?.asset?.addresses?.postalAddresses?.formattedAddress}  `}
                                                        </div>
                                                        <div className="mb-2">
                                                            <strong>{t("Date_Creation")}</strong>
                                                            <br></br>
                                                            {formatDateWithFromCalendar(workorder.raisedDate || '')}
                                                        </div>

                                                        <div className="mb-2">
                                                            <strong>{t("status_txt")}</strong>
                                                            <br></br>
                                                            {(workorder?.statuses?.status === "In Diagnosis" &&
                    (workorder?.statuses.isPotentialDuplicate === true ||
                        workorder?.statuses?.isVulnerable === true  ||
                        workorder?.job?.sorCode === "N/A" || workorder?.job?.sorCode === "NA") && workorder.statuses.isSubmitted)
                    ? "Under review"
                                                                :workorder.statuses?.status === "In Diagnosis"
                                                                ? t("draft")
                                                                : ((workorder.statuses?.status === "Unappointed" ||
                                                                    workorder.statuses?.status === "Reported to EMA") && workorder.job?.repairType=== SurveyTypes.survey) 
                                                                ? t('assigned_surveyor') 
                                                                : (workorder.statuses?.status === "Unappointed" || workorder.statuses?.status === "Reported to EMA")
                                                                    ? t("assigned_contractor")
                                                                    : workorder.statuses?.status}
                                                        </div>
                                                    </div>

                                                    <div className="px-4 md:flex md:justify-between">
                                                        {/* <div className="mb-4"><a href="#" className="text-teal a-style">{t("view")} {property.assetType}</a></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                         {width>820 && <div className="page-mar">
                                            <Paginationbox>
                                                <label>
                                                    <Pagination
                                                        className=" ml-4 "
                                                        colSpan={7}
                                                        count={totalCount}
                                                        page={currentPage}
                                                        rowsPerPage={resultsPerPage}
                                                        rowsPerPageOptions={[10, 20, 100]}
                                                        onPageChange={onChangePage}
                                                        data-testid="WorkOrderPaginationChange"
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </label>
                                            </Paginationbox>
                                        </div>}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="div-loader">
                                <div className="SpinnerWrapper">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        )}
                    </div>
                    {((backToTop && width<=820) ||(backToTop && resultsPerPage>10 && width>820)) && <BackToTopComponent/>}
                </div>
        )
    }

    return (
        <div> 
            {width<=820? 
                <InfiniteScroll
                        dataLength={workOrderList?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                    {displayWorkOrderResult()}
                </InfiniteScroll>:
                displayWorkOrderResult()
             }    
            
        </div>
    )
}

export default WorkOrderResultComponent;