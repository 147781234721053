import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RepairHistory, RepairNote } from "../../models/Repair/Repair";
import { RepairChannel } from "../../models/Repair/RepairChannel";
import Loader from "react-loader-spinner";
import WorkOrderChronologyList from "../WorkOrderChronologyList";
import WorkOrderNotes from "../WorkOrderNotes";
import { trackEvent } from "../../appInsights/TelemetryService";
import { getAppointmentDateFormattedComment } from "../../utils/formatting";
import RepairViewMedia from "../RepairViewMedia";
import { woChronologyTabState } from "../../ducks/redux/actions/userChoices";
import { useDispatch } from "react-redux";
import { finishJourney } from "../../ducks/redux/actions/FinancialReferral";
interface WorkOrderChronologyProps extends RouteComponentProps<any> {
   
}
const WorkOrderChronologyComponent: React.FC<WorkOrderChronologyProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const workOrderData = useSelector(
        (state: any) => state?.userChoicesReducer?.workOrderData 
    );
    const contractorname = useSelector(
        (state: any) => state?.addresses?.contractorname
      );
      const isRepairMedia = useSelector(
        (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
      );
      const Workordertabopen = useSelector(
        (state: any) => state?.userChoicesReducer?.woChronologyTabState
      );
    const [repairHistoryDetails, setRepairHistoryDetails] = React.useState<RepairHistory[]>([]);
    const dispatch = useDispatch();
    const [repairNotesList, setRepairNotesList] = React.useState<RepairNote[]>([]);
    const [isRepairHistoryVisible, setRepairHistoryVisible] = useState(false);
    const [isRepairNotesVisible, setRepairNotesVisible] = useState(false);
    const [isRepairViewMediaVisible, setRepairViewMediaVisible]=useState(false);
    const contractorNotKnown = workOrderData.identifiers.jobNumber !== null ? t("Unknown"): "-";


    useEffect(() => {
        (async () => {
            trackEvent("WorkOrderChronology");
            setIsLoading(true);
            await SetRepairHistory().then(async () => {
                if (contractorname && contractorname.toLowerCase() !== "mpt") {
                    await SetRepairNotes();
                }
            }).then(() => {
                setIsLoading(false); 
            });            
        })();
       if(Workordertabopen === "Notes"){
        setRepairNotesVisible(true)
       }
       else if (Workordertabopen === "History"){
        setRepairHistoryVisible(true)
       }
    }, []);

    const SetRepairHistory = async () => {
        try {
            const allItems: any = [];
            const repairHistory: any = await api.getRepairHistories(
                workOrderData.identifiers?.worksOrderIdentifier
            );
            Promise.all([repairHistory]).then((results) => {
                results.map((items: any) => {
                    items.map((eachItem: any) => {
                        const emergencyStatus = workOrderData.statuses.isEmergency;
                        const appointmentDateComment = getAppointmentDateFormattedComment(eachItem);  

                        const allItemsObj: any = {
                            Unappointed: {
                                statusTitle: t("Unappointed_Title"),
                                comments: emergencyStatus
                                    ? t("Unappointed_Comment_Emergency")
                                    : t("Unappointed_Comment"),
                            },
                            APCREATE01: {
                                statusTitle: t("APCREATE01_Title"),
                                comments: `${t("APCREATE01_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE02: {
                                statusTitle: t("APCREATE02_Title"),
                                comments: `${t("APCREATE02_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE03: {
                                statusTitle: t("APCREATE03_Title"),
                                comments: `${t("APCREATE03_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE04: {
                                statusTitle: t("APCREATE04_Title"),
                                comments: `${t("APCREATE04_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE05: {
                                statusTitle: t("APCREATE05_Title"),
                                comments: `${t("APCREATE05_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE06: {
                                statusTitle: t("APCREATE06_Title"),
                                comments: `${t("APCREATE06_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE07: {
                                statusTitle: t("APCREATE07_Title"),
                                comments: `${t("APCREATE07_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE08: {
                                statusTitle: t("APCREATE08_Title"),
                                comments: `${t("APCREATE08_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE09: {
                                statusTitle: t("APCREATE09_Title"),
                                comments: `${t("APCREATE09_Comment")} ${appointmentDateComment}.`,
                            },
                            APCREATE10: {
                                statusTitle: t("APCREATE10_Title"),
                                comments: `${t("APCREATE10_Comment1")} ${appointmentDateComment}. ${t("APCREATE10_Comment2")}`,
                            },
                            AUPDATE01: {
                                statusTitle: t("AUPDATE01_Title"),
                                comments: `${t("AUPDATE01_Comment1")} ${appointmentDateComment}. `,
                            },
                            AUPDATE02: {
                                statusTitle: t("AUPDATE02_Title"),
                                comments: `${t("AUPDATE02_Comment1")} ${appointmentDateComment}. `,
                            },
                            AUPDATE03: {
                                statusTitle: t("AUPDATE03_Title"),
                                comments: `${t("AUPDATE03_Comment1")} ${appointmentDateComment}.`,
                            },
                            AUPDATE05: {
                                statusTitle: t("AUPDATE05_Title"),
                                comments: `${t("AUPDATE05_Comment1")} ${appointmentDateComment} ${t(
                                    "AUPDATE05_Comment2"
                                )}`,
                            },
                            AUPDATE06: {
                                statusTitle: t("AUPDATE06_Title"),
                                comments: `${t("AUPDATE06_Comment1")} ${appointmentDateComment}. ${t(
                                    "AUPDATE06_Comment2"
                                )}`,
                            },
                            AUPDATE07: {
                                statusTitle: t("AUPDATE07_Title"),
                                comments: `${t("AUPDATE07_Comment")} ${appointmentDateComment}. `,
                            },
                            NOACCESS: {
                                statusTitle: t("NOACCESS_Title"),
                                comments: t("NOACCESS_Comment"),
                            },
                            ENGOR01:{
                                statusTitle: t("ENGOR01_Title"),
                                comments: t("ENGOR01_Comment"),
                            },
                            ENGOS01: {
                                statusTitle: t("ENGOS01_Title"),
                                comments: t("ENGOS01_Comment"),
                            },
                            ENGOS03: {
                                statusTitle: t("ENGOS03_Title"),
                                comments: t("ENGOS03_Comment"),
                            },
                            NOACC01: {
                                statusTitle: t("NOACC01_Title"),
                                comments: t("NOACC01_Comment"),
                            },
                            ENGOS05: {
                                statusTitle: t("ENGOS05_Title"),
                                comments: t("ENGOS05_Comment"),
                            },
                            ENGOS06: {
                                statusTitle: t("ENGOS06_Title"),
                                comments: t("ENGOS06_Comment"),
                            },
                            ENGOS07: {
                                statusTitle: t("ENGOS07_Title"),
                                comments: t("ENGOS07_Comment"),
                            },
                            Completed: {
                                statusTitle: t("Completed_Title"),
                                comments: t("Completed_Comment"),
                            },
                            Cancelled: {
                                statusTitle: t("Cancelled_Title"),
                                comments: t("Cancelled_Comment"),
                            },
                            Variation: {
                                statusTitle: t("Variation_Title"),
                                comments: t("Variation_Comment"),
                            }
                        };
                        let itemObject = {};
                        if (eachItem.subType && eachItem.subType.indexOf("Variation") > -1) {
                            itemObject = {
                                statusTitle:
                                    allItemsObj[eachItem.subType]?.statusTitle || "",
                                comments: allItemsObj[eachItem.subType]?.comments || "",
                                createDateTime: eachItem.createDateTime,
                            };
                        }
                        else {
                            itemObject = {
                                statusTitle:
                                    allItemsObj[eachItem.identifier]?.statusTitle || "",
                                comments: allItemsObj[eachItem.identifier]?.comments || "",
                                createDateTime: eachItem.createDateTime,
                            };
                        }
                        if (eachItem.subType !== "WorkOrder") {
                            allItems.push(itemObject);
                        }

                    });
                    
                        //We have removed sorting logic from UI p360.
                        //Sorting is implemented at api level for Work order v2 History Items and this is the prod story no
                        //https://dev.azure.com/PeabodyTrust/Digital%20Platform/_workitems/edit/6757
                    
                        const filteredItems = allItems.filter(
                        (item: any) => item.statusTitle !== ""
                    );
                    setRepairHistoryDetails(filteredItems);
                });
            });
        } catch (e) {
            console.log(e);
            props.history.push("/GenericError");
        }
    }

    const SetRepairNotes = async () => {
        try {
            const allNotes: any = [];
            const repairNotes = await api.getRepairNotes(
                workOrderData.identifiers?.jobNumber
            );

            if (repairNotes?.length > 0) {
                const excludedNoteTypes = ["ASBESTOS1", "ASBESTOS2", "ASBESTOS3", "ASBESTOS4", "ASBESTOS5", "ASBESTOS6",
                    "ASBESTOS7", "ASBESTOS8", "ASBESTOS9", "ASBESTOS10", "SEC20"];
                repairNotes.sort((a: any, b: any) =>
                    new Date(b.creationDate).valueOf() -
                    new Date(a.creationDate).valueOf()).map((note: RepairNote) => {
                    const isNotExcludedNoteType = excludedNoteTypes.indexOf(note.type) < 0;
                    if (isNotExcludedNoteType)
                        allNotes.push(note);
                });

                setRepairNotesList(allNotes);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const backButtonHandler = () => {
        dispatch(finishJourney(true));
        dispatch(woChronologyTabState(""));
        props.history.push(`/WorkOrder360View/${workOrderData?.identifiers?.worksOrderIdentifier}`);

    };

    const showChronology = () => {
        setRepairHistoryVisible(true);
        setRepairNotesVisible(false);
        setRepairViewMediaVisible(false);
    };

    const showNotes = () => {
        setRepairHistoryVisible(false);
        setRepairNotesVisible(true);
        setRepairViewMediaVisible(false);
    };
    const showMedia = () => {
        setRepairHistoryVisible(false);
        setRepairNotesVisible(false);
        setRepairViewMediaVisible(true);
    };

    const activeCss = "px-4 lg:px-0 cursor-pointer py-2 bg-tl-light text-black";
    const inActiveCss = "bg-grey px-4 lg:px-0 cursor-pointer py-2 text-grey";

    return (
    <div>
        <div className="border-t border-grey bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
        <div className="container mx-auto">
        <div className="w-full px-2 lg:px-0 ">
                    <div className="mt-4 mb-8 ">
                        <p className="text-teal text-xl mt-4 mb-4 md:mb-12">
                            <a className="back-btn" data-testid="backButtonHandler" onClick={backButtonHandler}><i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>
                    </div>
                    <div className="w-full lg:px-0 global-txt">
                    
                    <div className="border border-grey bg-grey rounded-lg mb-4 mt-8">
                        <div className="bg-teal-dark px-4 py-4 rounded-t-lg">
                        <div className={window.innerWidth<820?" items-center text-white caselist mb-4":"flex flex-row items-center text-white caselist"}>
                                <b>
                                    {t("work_order")}</b>
                                    <div className={window.innerWidth<820?"caselist headingText mb-1":"ml-8 caselist headingText mb-1"}> {workOrderData.identifiers?.jobNumber}</div>
                            </div>
                        </div>
                   
                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-1">
                            <div className="mb-2">
                            <strong>{t("Description_CP067")}</strong><br/>{(workOrderData.job?.trade == null || workOrderData.job?.trade == "Unknown") &&
                              workOrderData.channel != RepairChannel.CustomerSelfService &&
                              workOrderData.channel != RepairChannel.ERGO &&
                              workOrderData.channel != RepairChannel.ColleagueSelfServe
                              ? t("RepairDescription_ErrorMessage")
                              : (workOrderData.channel === RepairChannel.CustomerSelfService ||
                                workOrderData.channel === RepairChannel.ERGO || workOrderData.channel === RepairChannel.ColleagueSelfServe) 
                                ? workOrderData.job?.description?.split(".")[0]
                                : workOrderData.job?.trade} 
                            </div>
                            <div className="mb-2">
                            <strong>{t("Contractor_CP114")}</strong><br/>{contractorname !== "" ? contractorname : contractorNotKnown }
                            </div>
                        </div>
                    </div>
                    
                            {isLoading ? (
                                <Loader
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={70}
                                    width={50}
                                    timeout={5000}
                                />
                                ) : <><div className="bg-grey grid md:grid-cols-3 mb-4 rounded-lg ">
                                <div data-testid="ChronologyTab" className={`${isRepairHistoryVisible ? activeCss : inActiveCss} ${window.innerWidth<820?"rounded-t-lg border-grey border":"rounded-tobo-lg border-grey border"}`} onClick={showChronology}>
                                    <strong>{t("Chronology")}</strong>
                                </div>
                                <div data-testid="NotesTab" 
                                     className={`border ${isRepairNotesVisible ? activeCss : inActiveCss}`} 
                                     onClick={showNotes}>
                                    <strong>{t("Notes")}</strong>
                                </div>
                                {isRepairMedia == 'true' ? <div data-stored="MediaTab"className={`${isRepairViewMediaVisible ? activeCss : inActiveCss} ${window.innerWidth<820?"rounded-b-lg border-grey border":"rounded-tb-rr-lg border-grey border"}`}  onClick={showMedia}>
                                        <strong>{t("Media")}</strong>
                                    </div>: null }
                                </div>
                                    {isRepairHistoryVisible && <WorkOrderChronologyList repairHistoryDetails={repairHistoryDetails} {...props} />}
                                    {isRepairNotesVisible && <WorkOrderNotes repairNotesList={repairNotesList} {...props} />}
                                    {isRepairViewMediaVisible && <RepairViewMedia {...props} />}
                                </>
                                }
                        
           </div>        
        </div>
        </div>
    </div>
    </div>
      
)
}

export default WorkOrderChronologyComponent;