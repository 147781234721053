import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";

import {
  userChosenAdditionalDescription,
  userChosenAdditionalDescriptionCOUNT,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  isEditState,
  resumeDraftRepair,
  backToRepairAsset,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import Loader from "react-loader-spinner";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import "./style.css";
import { BackValue } from "../../models/shared/Enum";
import { RepairPriority } from "../../models/Repair/CreateRepair";

interface RepairAdditionalDetailsProps extends RouteComponentProps<any> { }

const RepairAdditionalDetails: React.FC<RepairAdditionalDetailsProps> = (
  props
) => {
  const { t } = useTranslation();

  const maxLengthLimit = "188";
  const minLengthLimit = "0";
  const format = /[$^*_\{};><*&!]+/;
  const dispatch = useDispatch();
  const history = useHistory();
  const ADDITIONAL_INFO_TEXT_LIMIT = 188;
  const [characterCount, setCharacterCount] = useState(maxLengthLimit);
  const [count, setCount] = useState("0");
  const [value, setValue] = useState("");
  const [isError, setError] = useState("none");
  const [isLoading, setIsLoading] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const userChosenAdditionalDescriptionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenAdditionalDescription
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );

  const userChosenAdditionalDescriptionBackButtonCOUNT = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenAdditionalDescriptionCOUNT
  );
  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );
  const selectedRepairReason = useSelector(
    (state: any) => state.dashboardReducer.formState.selectedRepairReasonObject
  );  
  const assetByIdDataObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);

  const priorityCodeValue = useSelector(
    (state: any) => state.dashboardReducer.formState?.priorityCode
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.history.action === "POP") {
      setValue(userChosenAdditionalDescriptionBackButton);
      setCount(userChosenAdditionalDescriptionBackButtonCOUNT);
    }
    if (userChosenAdditionalDescriptionBackButton) {
      setValue(userChosenAdditionalDescriptionBackButton);
      setCount(userChosenAdditionalDescriptionBackButtonCOUNT);
    }
  }, []);

  useEffect(() => {
    if (value !== "") {
      setError("none");
    }
  }, [value]);

  const onChange = (e: any) => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenVandalismQuestion("No"));
    }

    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }
    const textValue = e.target.value.trim()
    if (textValue.length > 0) {
      if (e.target.value.length < maxLengthLimit + 1) {
        e.target.value.length > ADDITIONAL_INFO_TEXT_LIMIT
          ? setCount(maxLengthLimit)
          : setCount(e.target.value.length);
      } else setCount(maxLengthLimit);

    }
    else {
      setCount("0")
    }
    setCharacterCount(maxLengthLimit);
    setValue(e.target.value.substr(minLengthLimit, maxLengthLimit));

    dispatch(
      userChosenAdditionalDescription(
        e.target.value.substr(minLengthLimit, maxLengthLimit)
      )
    );
    dispatch(userChosenAdditionalDescriptionCOUNT(e.target.value.length));
  };

  const helpTextValue = count + "/" + characterCount;

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? 
       state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );
  
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const getEnhancedHubRepairPermissions = useSelector(
    (state: any) => state?.userChoicesReducer?.createEnhancedHubRepairPermissions
  );

  const UpdateWorkOrder = (field: boolean) => {
    try {
      setIsLoading(true);
      workOrderObject.priority = priorityCodeValue; 
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      woRequestParameters.additionalInformation = value;
      woRequestParameters.isEmergency = priority==RepairPriority.Emergency || priority=="Yes"


      const result = api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      )
      workOrderObject.additionalInformation = value;
      workOrderObject.isEmergency = priority==RepairPriority.Emergency || priority == "Yes"
      dispatch(workOrderRepairObject(workOrderObject));
      if (field) {
        props.history.push(`/WorkOrder360View/${workOrderObject.id}`);
      }


      // Promise.resolve(result).then((results) => {
      //   if (results) {

      // }
      // }).catch((e=>{
      //   console.error(e);
      //   props.history.push("/GenericError");
      // }))

    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);

    } finally {
      setIsLoading(false);
    }
  };

  const backButtonHandler = () => {
    if (!IsRecall) {
      dispatch(userChosenAdditionalDescription(""));
    }
    if (userChosenDuplicateQuestion != "") {
      props.history.push("/DuplicateRepair");
    } else {
      if (
        selectedRepairReason[0]?.additionalData.length > 0 &&
        selectedRepairReason[0]?.additionalData[0]?.description != ""
      ) {
        props.history.replace("/SupplementaryQuestion");
      } else {
        props.history.replace("/RepairLocation");
      }
    }
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    //Implement Save Draft and Exit functionalities here...
    let countValue = count?.toString();
    if (countValue !== "0") {
      setError("none");
      const result = UpdateWorkOrder(true);
    } else {
      setError("block");
      props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);

    }
    setCloseAfterSaving(true);
    dispatch(backToRepairAsset(false))
    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { additionalInformation } = userResumeDraftDataCache;
      if (additionalInformation !== value) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenVandalismQuestion("No"));
        dispatch(userChosenVandalismAdditionalInformation(""));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }
    let countValue = count?.toString();
    if (countValue !== "0") {
      UpdateWorkOrder(false);
      setError("none");
      if (isRepairMedia === "true") {
        setTimeout(() => {
          props.history.push("/RepairUploadmedia");
        }, 1000);
      }
      else {
        if (assetByIdDataObject?.statuses.isInDefects === true && vandalismConfigValue === true) {

          setTimeout(() => {
            props.history.push("/VandalismQuestion");
          }, 1000);

        }
        else {
          setTimeout(() => {
            dispatch(userChosenVandalismQuestion(""));
            props.history.push("/VulnerabilityQuestion");
          }, 1000);
        }
      }
    } else {
      setError("block");
    }
  };

  return (
    <Container id="repAddDet-container" role="main" className="ctn-fht">
      <div id="backmodal-div1" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 md:mb-12">
          <a className="back-btn" onClick={backButtonHandler}>
            <i className="fas fa-angle-left mr-2"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>
      <div id="repAddDet-main-div"
        className="question ml-sm-5 pl-sm-5 repair-text container"
      >
        <div id="repAddDet-div1" className="py-2 h5 mar-repadd">
          <h2 id="repAddDet-h1" className="text-purple-100 text-2xl mb-2 rep-mar global-txt"> {t("Additional_Details_CPR024")}</h2>
        </div>

        {!getEnhancedHubRepairPermissions && <div id="repAddDet-div2" className="mt-1">
          <ul id="repAddDet-ul" className="text-black fw-light">
            <li id="repAddDet-li1" className="py-2 global-txt">
              {t("Additional_Details_CPR025_1")}
            </li>
            <li id="repAddDet-li2" className="py-2 global-txt">
              {t("Additional_Details_CPR025_2")}
            </li>
            <li id="repAddDet-li3" className="py-2 global-txt">
              {t("Additional_Details_CPR025_3")}
            </li>
          </ul>
        </div>}
        <span className="text-gray-700 text-black global-txt fw-light">{t("Additional_Details_CPR026")}</span>
        {getEnhancedHubRepairPermissions && <Typography className="adhesive-container">
          {t('repair_other_info_label_description')}{' '}
          <span className="bold-text">{t('repair_other_info_label_description_supporting_text')}</span>

          <ul style={{ margin: 0, paddingLeft: 0 }}>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_A')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Access')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_D')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Damage')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_H')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_How_long')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_E')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Establish_history')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_S')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Safety_Security')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_I')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Information_Make_Model')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_V')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Vulnerabilities')}
            </li>
            <li>
              <span className="bold-text mr-k">{t('repair_other_info_E2')}</span>
              <span className="mr-2">-</span>
              {t('repair_other_info_Escalation')}
            </li>
          </ul>
        </Typography>}
        <div
          id="repAddDet-div3"
          className="global-txt mt-1"
          data-testid="repairdetails"
         
        >
          <label className="w-full global-txt">
            <TextField aria-invalid="true"
              margin="normal"
              className="textarea-additional-details global-txt pb-4 border-grey"
              helperText={helpTextValue}
              data-testid="Repair_Additional_Details_Placeholder"
              onChange={(e) => onChange(e)}
              id="outlined-textarea"
              value={value}
              placeholder={t("Additional_Details_CPR026a")}
              InputProps={{
                inputComponent: 'textarea', style: { padding: 0, height: 'auto' }
              }}
              size="medium"
              error={count >= maxLengthLimit} />
          </label>
        </div>

        <div className="error mb-8" style={{ display: isError }} id="repAddDet-div4" role="alert">
          <div className="w-full px-2 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">{t("Additional_Details_CPR027")}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="repAddDet-div5"
        className="flex justify-between items-center pb-4 md:pb-8 container"
      >
        <ExitModalAndSave
          id="repAddDet-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
        <Button
          id="btn_additionaldetails"
          onClick={callPageHandler}
          className="button-class"
        >
          {t("continue")}{" "}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
      <span>
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={50}
            timeout={5000}
          />
        ) : (
          <>
            <div></div>
          </>
        )}
      </span>
    </Container>
  );
};
export default RepairAdditionalDetails;