import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Button/Button";
import { useLocation } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  userChosenPropertyId,
  userChosenPropertyType,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenCovidQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  workOrderData,
} from "../../ducks/redux/actions/userChoices";

import { 
	assetByIdDataObject 
} from "../../ducks/redux/actions/dashboardReducer.actions";

import {
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  workOrderRepairObject,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
  saveAvailabilityResponse,
  repairHistoryData
} from "../../ducks/redux/actions/getAddress";
import { formatDateWithFromCalendar } from "../../utils/formatting";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { finishJourney } from "../../ducks/redux/actions/FinancialReferral";

const AppointmentConfirmation: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const [appointmentHeaderMsg, setAppointmentHeaderMsg] = useState("");
  const [appointmentMsg, setAppointmentMsg] = useState("");
  const [eligible, setEligible] = React.useState("none");
  const referenceNumberCode = useSelector(
    (state: any) => state.addresses?.referenceno
  );
  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  const preferenceno = useSelector(
    (state: any) => state.addresses?.preferenceno
  );

  const contractorname = useSelector(
    (state: any) => state.addresses?.contractorname
  );
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  
      
  useEffect(() => {
    window.scrollTo(0,0);
    AppointmentMessages();
  }, []);

  const AppointmentMessages = () => {
    if (preferenceno === true) {
      setAppointmentMsg(t("SSR021"));
      setAppointmentHeaderMsg(t("Preferences_Confirm"));
      setEligible("block");
    } else {
      setAppointmentMsg(t("SSR021"));
      setAppointmentHeaderMsg(t("CPR056"));
      setEligible("none");
    }
  };

  const callPageHandler = () => {
    dispatch(finishJourney(true));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyType(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`);
  };

  const callcreatePageHandler = () => {
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(workOrderRepairObject(null));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(saveAvailabilityResponse(null));
    dispatch(repairHistoryData(null));
    props.history.push("/RepairAsset");
  };

  return (
    <div className="container sm:px-2 ctn-fht">
      <div id="appCon-main-div" className="w-full lg:px-0 py-2 h5 my-4 repair-text">
        <h2 className="text-purple-100 text-2xl mb-2 global-txt">{appointmentHeaderMsg}</h2>

        <div className="border border-grey rounded-lg px-6 mb-6 md:mb-12 section-brder global-txt pl-4">


          <p className="mb-8">
            {t("Appointment_Confirm_Reference")}<br></br> <strong>{referenceNumberCode}{" "}</strong>
          </p>


          <p className="global-txt mb-8 mt-4">
            {appointmentMsg}<br></br>
            <strong> {formatDateWithFromCalendar(new Date(location?.state?.date).toDateString()) } , {location?.state?.availabilityTypeLabel} ({location?.state?.start} to {location?.state?.end}) </strong>

          </p>

          <p>
            {t("Appointment_Confirm_Assign")}<br></br>  <strong>{contractorname}</strong>
          </p>


        </div>
      </div>

      {!preferenceno ? (
        <div className="global-txt">
          <h2>{t("What_Happen_Next")}</h2>
          <p>{t("Let_Our_Contractor_txt")}</p>
          <p>{t("Details_About_Repair")}</p>
        </div>
      ) : (
        <div className="global-txt">
          <h2>{t("What_Happen_Next")}</h2>
          <p className="mb-8">{t("Let_Our_Contractor")}</p>
          <p>{t("Details_About_Repair")}</p>
        </div>
      )}
      <div className="md:flex md:justify-between mb-4">
        <a
          id="btn_finishcreate"
          onClick={callcreatePageHandler}
          data-testid="repFinalConfirm-btn1"
          className="finish-css float-left mt-20"
        >
          <span className="pr-1 mr-2 text-teal exit-btn global-txt cursor-pointer"> {t("finishcreate_button")} {" >"}</span>

        </a>

        <Button
          id="btn_finishrepFinalConfirm"
          onClick={callPageHandler}
          data-testid="repFinalConfirm-btn2"
          className="button-class fin-btn-margin"
        >
          {t("finish_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </div>
  );
};

export default AppointmentConfirmation;
