import { AnyAction } from "redux";

import {
    CASE_CREATION_MASTER_DATA,
    CASE_CREATION_ID,
    CASE_CREATION_ADDITINALINFO,
    CASE_CREATION_DESCRIPTION,
    CASE_SELECTED_ANSWERS,
    CASE_CREATION_QUESTIONS,
    CLEAR_CASE_STATE,
    CASE_QUESTION_ANSWERS_DETAILS,
    CASE_DESCRIPTION_DATA_INFO,
    CASE_PROPERTY_TENANCY_GROUP,
    CASE_IDENTIFIER,
    CASE_SUBTYPE,
    CASE_SUBTYPE_CATEGORY,
    CASE_OWNING_TEAM,
    CASE_EDIT,
    CASE_ELIGIBILITY_RESPONSE,
    CASE_ADDITIONAL_CASE_FIELD,
    CASE_CATEGORY,
    CASE_COUNTER,
    CASE_ASSET_TYPE,
    CASE_OWNER,
    CASE_ASSET_IDENTIFIER,
    CASE_SELECTED_TENANCY,
    CONTACT_INFORMATION_VALUE,
    CASE_CUSTOMER_SEARCH,
    CASE_TENANCY_ID,
    CASE_TENANCY_TYPE,
    EDIT_BUTTON_CLICK,
    CASE_ASSET_CHECK,
    CASE_CUSTOMER_NAME,
    ELIGIBLE_SELECT_CUSTOMER,
    IS_CASE_MEDIA_CHECK,
    CASE_ACTION_DETAIL,
    IS_CASEMANAGEMENT_TOGGLE,
    IS_UPDATEANACTION_TOGGLE,
    CASE_CREATION_FILTERED_QUESTION,
    CASE_CREATION_QUESTIONIDS_ANSWERS,
    
} from "../actionTypes";

const initialState = {
    caseIdentifier: "",
    caseTenancyGroup: "",
    caseSelectedTenancyAddress: [],
    caseCreationMasterData: [],
    caseCreationId: "",
    caseCreationDescription: "",
    caseCreationAdditionalInfo: [],
    caseSelectedAnswers: [],
    caseAdditionalFields: [],
    caseSelectedQuestions: [],
    caseDescriptionDataInfo: [],
    caseQuestionAnswerDetails: {
        caseTypeId: "",
        identifiers: {
            assetIdentifier: "",
            customerIdentifier: "",
            tenancyIdentifier: "",
        },
        additionalInfo: [
            {
                questionId: "",
                responseId: "",
                responseText: "",
            },
        ],
    },
    caseSubType: "",
    caseSubTypeCategory: "",
    caseOwningTeam: "",
    isCaseEdit: false,
    caseCategory: "",
    counter: 0,
    caseAssetType: "",
    caseAssetIdentifier: "",
    contactInformationValue: "",
    caseCustomerId: "",
    caseCustomerSearch: "",
    caseTenancyId: "",
    caseTenancyType: "",
    editButtonClick: false,
    caseAssetCheck: false,
    caseCustomerName: "",
    eligibleSelectCustomer: false,
    isCaseMediaCheck:"",
    caseActionDetail: [],
    caseCreationFilteredQuestion: [],
    caseSelectedQuestionIdsAnswers:[]
};

export const caseCreationReducer = (
    state: any = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case ELIGIBLE_SELECT_CUSTOMER:
            return {
                ...state,
                eligibleSelectCustomer: action.payload,
            };
        case CASE_CUSTOMER_NAME:
            return {
                ...state,
                caseCustomerName: action.payload,
            };
        case CASE_ASSET_CHECK:
            return {
                ...state,
                caseAssetCheck: action.payload,
            };
        case EDIT_BUTTON_CLICK:
            return {
                ...state,
                editButtonClick: action.payload,
            };
        case CASE_TENANCY_TYPE:
            return {
                ...state,
                caseTenancyType: action.payload,
            };
        case CASE_TENANCY_ID:
            return {
                ...state,
                caseTenancyId: action.payload,
            };
        case CASE_CUSTOMER_SEARCH:
            return {
                ...state,
                caseCustomerSearch: action.payload,
            };
        case CONTACT_INFORMATION_VALUE:
            return {
                ...state,
                contactInformationValue: action.payload,
            };
        case CASE_SELECTED_TENANCY:
            return {
                ...state,
                caseSelectedTenancyAddress: action.payload,
            };
        case CASE_ASSET_IDENTIFIER:
            return {
                ...state,
                caseAssetIdentifier: action.payload,
            };
        case CASE_ASSET_TYPE:
            return {
                ...state,
                caseAssetType: action.payload,
            };
        case CASE_COUNTER:
            return {
                ...state,
                counter: action.payload,
            };

        case CASE_CATEGORY:
            return {
                ...state,
                caseCategory: action.payload,
            };
        case CASE_ADDITIONAL_CASE_FIELD:
            return {
                ...state,
                caseAdditionalFields: action.payload,
            };
        case CASE_EDIT:
            return {
                ...state,
                isCaseEdit: action.payload || false,
            };
        case CASE_OWNING_TEAM:
            return {
                ...state,
                caseOwningTeam: action.payload,
            };
        case CASE_SUBTYPE:
            return {
                ...state,
                caseSubType: action.payload,
            };
        case CASE_SUBTYPE_CATEGORY:
            return {
                ...state,
                caseSubTypeCategory: action.payload,
            };
        case CASE_PROPERTY_TENANCY_GROUP:
            return {
                ...state,
                caseTenancyGroup: action.payload,
            };
        case CASE_IDENTIFIER:
            return {
                ...state,
                caseIdentifier: action.payload,
            };
        case CASE_CREATION_MASTER_DATA:
            return {
                ...state,
                caseCreationMasterData: action.payload,
            };
        case CASE_DESCRIPTION_DATA_INFO:
            return {
                ...state,
                caseDescriptionDataInfo: action.payload,
            };
        case CASE_CREATION_ID:
            return {
                ...state,
                caseCreationId: action.payload,
            };
        case CASE_CREATION_DESCRIPTION:
            return {
                ...state,
                caseCreationDescription: action.payload,
            };
        case CASE_CREATION_ADDITINALINFO:
            return {
                ...state,
                caseCreationAdditionalInfo: action.payload,
            };
        case CASE_CREATION_QUESTIONS:
            return {
                ...state,
                caseSelectedQuestions: action.payload,
            };
        case CASE_SELECTED_ANSWERS:
            return {
                ...state,
                caseSelectedAnswers: action.payload,
            };
        case CLEAR_CASE_STATE:
            return {
                ...state,
                caseCreationMasterData: [],
                caseCreationId: "",
                caseCreationDescription: "",
                caseCreationAdditionalInfo: [],
                caseSelectedAnswers: [],
                caseSelectedQuestions: [],
                caseDescriptionDataInfo: [],
                caseOwningTeam: "",
                caseSubType: "",
                caseSubTypeCategory: "",
                isCaseEdit: false,
                caseCategory: "",
                caseAdditionalFields: [],
                counter: 0,
                caseCreationFilteredQuestion:[],
                caseSelectedQuestionIdsAnswers:[]
            };
        case CASE_QUESTION_ANSWERS_DETAILS:
            return {
                ...state,
                caseQuestionAnswerDetails: {
                    ...state.caseQuestionAnswerDetails, // Need to save question answer details
                },
            };
        case CASE_ELIGIBILITY_RESPONSE:
            return {
                ...state,
                caseEligibilityResponseData: action.payload,
            };
        case CASE_OWNER:
            return {
                ...state,
                caseOwnerName: action.payload,
            };
        case IS_CASE_MEDIA_CHECK:
            return {
                    ...state,
                    isCaseMediaCheck: action.payload,
                };
                case CASE_ACTION_DETAIL:
                    return {
                        ...state,
                        caseActionDetail: action.payload,
                    };
                    case IS_CASEMANAGEMENT_TOGGLE:
                        return {
                            ...state,
                            isCaseManagementToggle: action.payload,
                        };
                case IS_UPDATEANACTION_TOGGLE:
                            return {
                                ...state,
                                isUpdateAnActionToggle: action.payload,
                            };
        case CASE_CREATION_FILTERED_QUESTION:
            return {
                ...state,
                caseCreationFilteredQuestion: action.payload,
            };
        case CASE_CREATION_QUESTIONIDS_ANSWERS:
            return {
                ...state,
                caseSelectedQuestionIdsAnswers: action.payload,
            };                     
        default:
            return state;
    }
};

export default caseCreationReducer;
