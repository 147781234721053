import InputAdornment from '@material-ui/core/InputAdornment'
import React from 'react'
import { CloseIcon, CloseWrapper, SearchIcon } from '../components'

type SearchSectionProps = {
    currentValue?: string
    resetSearch?(e: React.MouseEvent): void
    handleSearchClick?(e: React.MouseEvent): void
    searchMade:boolean
}
const SearchSection: React.FC<SearchSectionProps> = ({ currentValue, resetSearch, handleSearchClick, searchMade }) => {
    return (
        <>
            <CloseWrapper>
                {currentValue && (
                    <InputAdornment data-bdd="close-icon" data-testid="close-button" position="end" onClick={resetSearch}>
                     <CloseIcon/> 
                     </InputAdornment>
                )}
                 </CloseWrapper>
                       {currentValue && !searchMade && 
                       <InputAdornment data-bdd="search-icon" data-testid="search-button" position="end" onClick={handleSearchClick}>
                        <div style={{marginLeft:"-3.5rem",marginRight:"1rem"}}><SearchIcon /> </div> 
                </InputAdornment>}
         </>
    )
}
export default SearchSection
