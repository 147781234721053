import React from "react";
import accessibility from "../../assets/img/peabody-logo-white.png";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
type FooterProps = {
  name: string;
};

const Footer: React.FC<FooterProps> = ({ name}) => {
  const { t } = useTranslation();
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  const termsLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions",
      "", "width=auto, height=auto");
  }

  const AccessibilityLink = () => {
    window.open("https://www.peabody.org.uk/accessibility-translations",
      "", "width=auto, height=auto");
  }
  const privacyLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions/privacy",
      "", "width=auto, height=auto");
  }
  const cookiesLink = () => {
    window.open("https://www.peabody.org.uk/terms-conditions/cookies",
      "", "width=auto, height=auto");
  }
  return (

    <footer id="footer" className={loggedInStatus? "footer-bt bg-gradient-to-r from-teal-light to-teal text-white text-sm px-4 py-4 md:py-8  md:px-4 global-txt mt-8":"footer bg-gradient-to-r from-teal-light to-teal text-white text-sm px-4 py-4 md:py-8  md:px-4 global-txt mt-8"}>
      <div className="container mx-auto border-t border-white">
        <ul className="list-none my-4 flex flex-col md:flex-row pl-0 ">
          
          <li>
            <a onClick={termsLink} rel="noopener" className="mb-1 mr-6 md:mb-0 text-white no-underline cursor-pointer">{t("Terms_And_Conditions")}</a>
          </li>
          <li>
            <a onClick={AccessibilityLink} rel="noopener" className="mb-1 mr-6 md:mb-0 text-white no-underline cursor-pointer">{t("Accessibility")}</a>
          </li>
          <li>
            <a onClick={privacyLink} rel="noopener" className="mb-1 mr-6 md:mb-0 text-white no-underline cursor-pointer">{t("Privacy")}</a>
          </li>
          <li>
            <a onClick={cookiesLink} rel="noopener" className="text-white no-underline cursor-pointer">{t("Cookies")}</a>
          </li>
        </ul>
      </div>
      <div className="container mx-auto flex justify-between items-center">
        <span className=" md:mb-0 pl-0 ml-0">{t("Peabody_2022")}</span>
        <img src={accessibility} alt="Peabody Logo - Footer" className="w-1/2 md:max-w-40"></img>
      </div>
    </footer>
  );
};

export default Footer;
