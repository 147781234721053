import React from "react";

interface AlertMessageProps {
  error: string;
  testId?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = ({ error, testId }) => {
  return (
    <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center">
        <p
          data-testid={testId ?? "alertMessage"}
          className="w-full text-left my-2 font-AvantGardeGothic-Md"
        >
          {error}
        </p>
      </div>
    </div>
  );
};

export default AlertMessage;