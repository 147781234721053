import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux"
import "./index.css"
import ExitModalContent from "../Modal/ExitModalContent";
import Button from "../../Button/Button";
import BackModalContent from "../Modal/BackModalContent";
import * as api from "../../api";


interface AssetDetailCardProps extends RouteComponentProps<any> {
  
}

const ComposeSMS: React.FC<AssetDetailCardProps> = (props:any) => {
  var specials = /[&*%!<>]/;
  const { t } = useTranslation();
  const [countCharacter, setCountCharacter] = useState(0);
  let [message, setMessage] =  useState("");
  const [error, setError] = useState("");
  const customerId = localStorage.getItem('agent');
  const assetDataResponse = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataResponse
  );

  const today = new Date();
    const minutes = (today.getMinutes()).toString().padStart(2, "0");
    const hour = (today.getHours()).toString().padStart(2, "0");
    const dd = (today.getDate()).toString().padStart(2, "0");
    const mm = (today.getMonth() + 1).toString().padStart(2, "0");
    const yyyy = today.getFullYear();
    const smsDate = `${dd}/${mm}/${yyyy}`
    const smsTime = `${hour}:${minutes}`

  const backButtonHandler = () => {
    props.history.goBack();   
  }

  const messageSendHandler = async() => {
    message = message.replace(/<\/?[^>]+(>|$)/g, "").trim(); // Removing <p> tag from editor
    const smsNotificationParam = {
      "sender": {
        "addresses":
        {
          "digitalAddresses": [
            {
              "emailAddresses": [
                {
                  "value": "Peabody360@peabody.org.uk",
                  "id": "email"
                }
              ],
              "telecomsAddresses": [
                {
                  "number": "",
                  "deviceId": "",
                  "dialingCode": "",
                  "countryCode": "",
                  "telecomsAddressType": "mobile"
                }
              ]
            }
          ]
        }
      },
      "communicationTemplateIdentifier": "pb-blockcommunication-template",
      "communicationType": "SMS",
      "content": [
    
        {
          "identifier": "insert_date",
          "value": smsDate
        },
        {
          "identifier": "insert_time",
          "value": smsTime
        },
        {
          "identifier": "sms_body",
          "value": message 
        },
        {
          "identifier":"colleague_id",
          "value": customerId
        }
       
      ]
    }

    
    if(message !== "" ) {
        if(countCharacter<=320 && error === "") {
            try{
              await api.sendSMSNotification(assetDataResponse?.id, smsNotificationParam)
              props.history.push("/SMSConfirmation")
            }catch(e:any){
              setError("SMS_BC015")
            }
        }  
    }else {
        setError("SMS_BC006")
    }
    
  }

  const exitButtonHandler = () => {
    props.history.goBack();
  }

  const validateComposedSMS = (e:any) => {
    if(e.target.value.trim() !== ""){
        setMessage(e.target.value)
    }else {
        setError("SMS_BC006")
    }
    
  }

  const onTextChangeHandler = (e:any) => { 
    setError("")
    let  test = e.target.value.replace(/((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/g,'');
  if (specials.test(test)) {
      setError("SMS_BC009")
      e.target.value = e.target.value.replace(/[&*%!<>]/g,'');
    }else {
        setCountCharacter(e.target.value.length)
        setTimeout(()=>{
            setMessage(e.target.value)
        }, 1000)
    }
  }
  
  return (
    <div className="container ctn-fht px-3">
        {/* Back Button */}
        <BackModalContent id="supp-main-backmodal" back={backButtonHandler} aria-label="backmodal" 
          SMS_back="Leave this page" SMS_msg = "Are you sure you want to go back? If you do your changes will be lost."
          btn_msg="Return to my SMS"
          />
        {/* Main Page Content */}
        <div className="sm:mx-1">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
             <b>{`${t("SMS_Title")} ${assetDataResponse?.addresses?.postalAddresses[0]?.formattedAddress}`}</b>
              </h1>
            </div>
           
        </div>
        <p className="py-2 flex justify-between mt-2">
            {t("SMS_BC003")}
         </p>
      
      
      <div className="contentPosition px-2">
      <textarea name="postSMS" rows={8} cols={40} spellCheck="true"
            data-testid='textarea'
             className="w-alert text-sm md:text-base h-28 bg-grey" aria-label="composeSMS" 
             maxLength={320} placeholder={t("SMS_BC004")}
             onChange={onTextChangeHandler } onBlur={validateComposedSMS} />    
             
        </div>
    
        <div className="characterCount mb-8">{`${countCharacter}/320`}</div>
         {/* Yellow Alert */}
         { (error !== "" || countCharacter >=320) && <div    
                data-testid="alert-warning"
                className="error mb-8 py-4"
                role="alert"
                placeholder="alert-message"
              >
                <div className="w-alert px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                      { countCharacter>= 320 && t("SMS_BC008")}
                      {error && t(`${error}`)}
                      {" "}
                    </p>
                  </div>
                </div>
         </div>}

        {/* Exit and Send SMS button */}
        <div
              className="flex items-center pb-4 md:pb-8 pt-sent"
              id="sms-btn">
              <Button
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={messageSendHandler}
                placeholder="send-sms"
                value="Send"
                className="button-class-send mr-6"
                disabled = { countCharacter > 320 }>
                {t("Send")}
              </Button>

              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg="Return to my SMS"
              />
        </div>
       
    </div>    

  )
};
export default ComposeSMS;