import { AnyAction } from "redux";
import {
  CUSTOMER_DATA, LOGIN_STATUS, USERCHOSEN_PROPERTY_ID, USERCHOSEN_PROPERTY_TYPE, USERCHOSEN_PROPERTY_ADDRESS,
  USERCHOSEN_REPAIR_LOCATION, USERCHOSEN_HIGH_LEVEL_DESCRIPTION, USERCHOSEN_SUPPLEMENTARY_QUESTIONS,
  USERCHOSEN_EMERGENCY_QUESTION, USERCHOSEN_DUPLICATE_QUESTION, USERCHOSEN_ADDITIONAL_DESCRIPTION,
  USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT, USERCHOSEN_VANDALISM_QUESTION, USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION,
  USERCHOSEN_COVID_QUESTION, USERCHOSEN_VULNERABILITY_QUESTION, USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION,
  USERCHOSEN_CONTACT_DETAILS, USERCHOSEN_UPRN, USERCHOSEN_SOR, USERCHOSEN_STATUS, USERCHOSEN_ELIGIBILITY_ERRORCODE,
  USERCHOSEN_CONTACT_NAME, USERCHOSEN_CONTACT_PHONE, USERCHOSEN_RELATION_DETAILS, USERCHOSEN_REPAIR_LOCATION_ID,
  LOGIN_TIME, REFRESH_TOKEN, ACCESS_TOKEN_EXPIRE_TIME, REFRESS_TOKEN_EXPIRE_TIME, INITIAL_LOGIN_TIME,
  UPDATE_PREV_CONFIRMED_STATE, UPDATE_ROUTE_HISTORY, UPDATE_USER_CHOICES_WITH_NEW_CHANGE, Is_Edit_State,
  USERCHOSEN_BANLANCE_TYPE, USERCHOSEN_ACCOUNT_NUMBER, USERCHOSEN_BALANCE, USERCHOSEN_ACCOUNT_Type,
  USERCHOSEN_Other_BALANCE, USER_PROFILE_ETAG, USER_PROFILE_DATA, Allpay_Conformation_Message,
  USERCHOSEN_ERROR_CODE, USERCHOSEN_ISELIGIBLE, RESUME_DRAFT_REPAIR, USER_REPAIR_WORKORDER, USER_RESUME_DATA,
  USER_RESUME_DRAFT_DATA_CACHE, USERCHOSEN_ISREPORTER, USERCHOSEN_ISERROR, Is_Active_Tenancy, GET_ASSET_LIST,
  DEFAULT_ASSET_ID, SEARCH_TEXT_COUNT_VALUE, PREMISES_TYPE_LIST, USERCHOSEN_TENANCY_GROUP, USERCHOSEN_ACCOUNT_List, WORK_ORDER_Data, USER_PROFILE_PERMISSION, USERCHOSEN_SEARCHTYPE, USERCHOSEN_SEARCHTEXT, USERCHOSEN_BACKBUTTON, USERCHOSEN_RAISEDSINCE, USERCHOSEN_RAISEDUPTO, USERCHOSEN_CUSTOMER_ACCOUNT_List, CUSTOMER_LIST,
  ASSET_DATA_STATUS, ASSET_DATA_RESPONSE, WORK_ORDER_RESPONSE, LOCAL_AUTHORITY, KEY_CONTACT, CUSTOMER_ID, USERCHOSEN_BACK_VALUE, USERCHOSEN_ALERT_VALUE, USERCHOSEN_ASSET_HIERARCHY_360, COMPONENT_INFO, TRANSACTION_INFO, HIERARCHY_OF_PREMISES, CUSTOMER_PREFERENCE_DATA,
  ASSET_PROPERTY_ID,
  APP_CONFIG_VANDALISM_VALUE,
  ASSET_REPAIR_CHECK,
  USERCHOSEN_RESUME_REPAIR,
  LOAD_DUPLICATE_REPAIR_PAGE,
  USER_CREATED_NEW_REPAIR,
  USERCHOSEN_DUPLICATE_ENTITY, USERCHOSEN_SAVEEXIT
  , BACK_TO_REPAIR_ASSET, USERCHOSEN_ASSETVALUE, WORKORDER_LIST, PROPERTY_LIST, USERCHOSEN_ISSEARCH, USERCHOSEN_ISRECALL, USERCHOSEN_CURRENT_VALUE, UCC_TENANCY_MODIFIED_DATA, UCC_RENT_MODIFIED_DATA, ASSETS_BY_ID_DATA, CREATE_ARREARS_RESPONSE, REPAIR_ADDRESS_DATA, WORK_ORDER_Data_Original, IS_CHECK_FOR_LANDLORD
  , CASE_LIST, CASE_DATA, USERCHOSEN_REPORTER_ID, BACK_TO_CASE_ASSET, CASE_ASSET_ADDRESS, BACK_TO_CASE_SELECT_CUSTOMER, USERCHOSEN_TENANCYCOUNT, USER_PROFILE_CASE_CREATION_PERMISSION, USERCHOSEN_CONTACT_OPTION, CASE_CREATED_BY, ASSOCIATED_ASSETS, No_UNIT, CUSTOMER_ADDITIONAL_ALERT_DATA,
  IS_CASE_ALLOWED,
  USERCHOSEN_DELETE_MEDIA,
  DELETE_MEDIA_ITEM,
  CLEAR_MEDIA_FILES,
  UPLOADED_MEDIA_ITEMS,
  MEDIA_RESUME,
  USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS,
  USERCHOSEN_UPLOADED_MEDIA,
  HAS_MEDIA_ATTACHED,
  FROM_MEDIA_PAGE,
  IS_CASE_MEDIA_ALLOWED,
  IS_REPAIR_MEDIA_ALLOWED,
  IS_REPAIR_COVID_ALLOWED,
  USERCHOSEN_NOTIFYALL,
  IS_P360_REPAIRS_MAINTAINENCE,
  IS_REPAIR_MEDIA_RESUME,IS_CATALYST_CUSTOMER,CATALYST_STATUS, USER_TYPED_REPAIR_REASONS, SURVEY_REASON, USERCHOSEN_ISSURVEY, CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION,WORKORDER_CHRONOLOGY_TAB,DEMOBILIZED_CONTRACTOR_SITES,
  USER_SELECTED_TENANCY_GROUP,
  USER_EMAIL,
  SAVE_SUPPLIMENTARY_CODES
} from "../actionTypes";
import { demobilizedContractorSites } from "../actions/userChoices";
const initialState = {
  loginStatus: "",
  customersData: {},
  userChosenPropertyId: "",
  userChosenPropertyType: "",
  userChosenPropertyAddress: "",
  userChosenRepairLocationId: 0,
  userChosenRepairLocation: "",
  userChosenHighLevelDescription: "",
  userChosenSupplementaryQuestions: "",
  userChosenEmergencyQuestion: "",
  userChosenDuplicateQuestion: "",
  userChosenAdditionalDescription: "",
  userChosenVandalismQuestion: "No",
  userChosenVandalismAdditionalInformation: "",
  userChosenNotifyAll: "No",
  userChosenCovidQuestion: "",
  userChosenVulnerabilityQuestion: "",
  userChosenVulnerabilityAdditionalInformation: "",
  userChosenContactDetails: "",
  userChosenUPRN: "",
  userChosenSOR: "",
  userChosenSTATUS: "",
  userChosenEligibilityErrorCode: "",
  loginTime: "",
  refershToken: "",
  refreshTokenExpirationTime: "",
  accessTokenExpirationTime: "",
  initialLoginTime: "",
  previousConfirmedState: {},
  routeHistory: [],
  banlancetype: "",
  accountnumber: "",
  balance: "",
  accountType: "",
  OtherBalance: "",
  userProfileETag: "",
  userProfileData: {},
  conformationMessage: "",
  errorCode: "",
  isEligible: false,
  resumeDraftRepair: false,
  userRepairWorkOrder: {},
  userResumeData: {},
  userResumeDraftDataCache: {},
  userChosenIsReporter: false,
  userIsError: false,
  getAssetList: [],
  defaultAssetId: "",
  searchTextCountValue: "",
  premisesTypeList: [],
  workOrderData: {},
  originalWorkOrderData : {},
  customerList: [],
  assetDataStatus: "",
  assetDataResponse: {},
  localAuthority: "",
  keyContact: {},
  backValue: "",
  customerPreferenceData: {},
  assetPropertyId: "",
  assetRepairCheck: false,
  repairAddressData: [],
  backToRepairAsset: false,
  userChosenResumeRepair: false,
  loadDuplicateRepairPage: false,
  userCreatedNewRepair: false,
  userChosenSaveExit: false,
  uCCTenancyModifiedData: {},
  uCCRentModifiedData: {},
  assetsByIDData: {},
  customerAccountData: {},
  caseList: {},
  caseData: {},
  backToCaseAsset: false,
  caseAssetAddress: "",
  backToCaseSelectCustomer:false,
  createArrearsResponse: "",
  landlordCheck: false,
  CASE_CREATED_BY: "",
  customerAdditionalAlertData: {},
  mediaItems: [],
  userChosenUploadedMedia: [],
  userChosenUploadedMediaIdentifiers:[],
  isCaseMediaAllowed: false,
  isCatalystProperty : false,
  catalystStatus: "",
  isSurvey:false,
  userChoiceRepairReasons: "",
  userChosenSurveyReason:"",
  mediaPageCrossed: false,
  woChronologyTabState: "",
  isP360RepairsMaintainenceValue: false,
  selectedTenancyGroup : "",
  email : "",
  supplimentaryCodes: "",
};
export const userChoices = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case CUSTOMER_ADDITIONAL_ALERT_DATA:
      return {
        ...state,
        customerAdditionalAlertData: action.payload,
      };
    case CASE_ASSET_ADDRESS:
      return {
        ...state,
        caseAssetAddress: action.payload,
      };
    case BACK_TO_CASE_ASSET:
      return {
        ...state,
        backToCaseAsset: action.payload,
      };    
    case CASE_CREATED_BY: return {
        ...state,
        caseCreatedBy: action.payload,
    };    
    case CASE_DATA:
      return {
        ...state,
        caseData: action.payload,
      };
    case CASE_LIST:
      return {
        ...state,
        caseList: action.payload,
      };
    case BACK_TO_REPAIR_ASSET:
      return {
        ...state,
        backToRepairAsset: action.payload,
      };
    case REPAIR_ADDRESS_DATA:
      return {
        ...state,
        repairAddressData: action.payload,
      };
    case ASSET_REPAIR_CHECK:
      return {
        ...state,
        assetRepairCheck: action.payload,
      };
    case ASSET_PROPERTY_ID:
      return {
        ...state,
        assetPropertyId: action.payload,
      };
    case CUSTOMER_PREFERENCE_DATA:
      return {
        ...state,
        customerPreferenceData: action.payload,
      };
    case KEY_CONTACT:
      return {
        ...state,
        keyContacts: action.payload,
      };
    case LOCAL_AUTHORITY:
      return {
        ...state,
        localAuthority: action.payload,
      };
    case USERCHOSEN_BACK_VALUE:
      return {
        ...state,
        backValue: action.payload,
      };
    case USERCHOSEN_CURRENT_VALUE:
      return {
        ...state,
        currentValue: action.payload,
      };
    case USERCHOSEN_ALERT_VALUE:
      return {
        ...state,
        alertValue: action.payload,
      };
    case USERCHOSEN_ASSET_HIERARCHY_360:
      return {
        ...state,
        assetHierarchy360: action.payload,
      };
    case ASSET_DATA_RESPONSE:
      return {
        ...state,
        assetDataResponse: action.payload,
      };
    case CUSTOMER_ID:
      return {
        ...state,
        custommerID: action.payload,
      };
    case ASSET_DATA_STATUS:
      return {
        ...state,
        assetDataStatus: action.payload,
      };
    case WORK_ORDER_RESPONSE:
      return {
        ...state,
        workorderresponse: action.payload,
      };
    case CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload,
      };
    case WORKORDER_LIST:
      return {
        ...state,
        workOrderList: action.payload,
      };
    case PROPERTY_LIST:
      return {
        ...state,
        propertyList: action.payload,
      };
    case WORK_ORDER_Data:
      return {
        ...state,
        workOrderData: action.payload,
      };
    case WORK_ORDER_Data_Original :
        return {
          ...state,
          originalWorkOrderData : action.payload,
        };
    case PREMISES_TYPE_LIST:
      return {
        ...state,
        premisesTypeList: action.payload,
      };
    case SEARCH_TEXT_COUNT_VALUE:
      return {
        ...state,
        searchTextCountValue: action.payload,
      };
    case DEFAULT_ASSET_ID:
      return {
        ...state,
        defaultAssetId: action.payload,
      };
    case GET_ASSET_LIST:
      return {
        ...state,
        getAssetList: action.payload,
      };
    case LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };
    case CUSTOMER_DATA:
      return {
        ...state,
        customersData: action.payload,
      };
    case USERCHOSEN_PROPERTY_ID:
      return {
        ...state,
        userChosenPropertyId: action.payload,
      };
    case USERCHOSEN_PROPERTY_TYPE:
      return {
        ...state,
        userChosenPropertyType: action.payload,
      };
    case USERCHOSEN_PROPERTY_ADDRESS:
      return {
        ...state,
        userChosenPropertyAddress: action.payload,
      };
    case USERCHOSEN_REPAIR_LOCATION:
      return {
        ...state,
        userChosenRepairLocation: action.payload,
      };
    case USERCHOSEN_HIGH_LEVEL_DESCRIPTION:
      return {
        ...state,
        userChosenHighLevelDescription: action.payload,
      };
    case USERCHOSEN_SUPPLEMENTARY_QUESTIONS:
      return {
        ...state,
        userChosenSupplementaryQuestions: action.payload,
      };
    case USERCHOSEN_EMERGENCY_QUESTION:
      return {
        ...state,
        userChosenEmergencyQuestion: action.payload,
      };
    case USERCHOSEN_DUPLICATE_QUESTION:
      return {
        ...state,
        userChosenDuplicateQuestion: action.payload,
      };
    case USERCHOSEN_ADDITIONAL_DESCRIPTION:
      return {
        ...state,
        userChosenAdditionalDescription: action.payload,
      };
    case USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT:
      return {
        ...state,
        userChosenAdditionalDescriptionCOUNT: action.payload,
      };
    case USERCHOSEN_VANDALISM_QUESTION:
      return {
        ...state,
        userChosenVandalismQuestion: action.payload,
      };
    case USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION:
      return {
        ...state,
        userChosenVandalismAdditionalInformation: action.payload,
      };
    case USERCHOSEN_COVID_QUESTION:
      return {
        ...state,
        userChosenCovidQuestion: action.payload,
      };
    case USERCHOSEN_NOTIFYALL:
      return {
        ...state,
        userChosenNotifyAll: action.payload,
      };
    case USERCHOSEN_VULNERABILITY_QUESTION:
      return {
        ...state,
        userChosenVulnerabilityQuestion: action.payload,
      };
    case USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION:
      return {
        ...state,
        userChosenVulnerabilityAdditionalInformation: action.payload,
      };
    case USERCHOSEN_CONTACT_DETAILS:
      return {
        ...state,
        userChosenContactDetails: action.payload,
      };
    case USERCHOSEN_CONTACT_OPTION:
      return {
        ...state,
        userChosenContactOption: action.payload,
      };
    case USERCHOSEN_CONTACT_NAME:
      return {
        ...state,
        userChosenContactName: action.payload,
      };
    case USERCHOSEN_CONTACT_PHONE:
      return {
        ...state,
        userChosenContactPhone: action.payload,
      };
    case USERCHOSEN_RELATION_DETAILS:
      return {
        ...state,
        userChosenRelationDetails: action.payload,
      };
    case USERCHOSEN_ISREPORTER:
      return {
        ...state,
        userChosenIsReporter: action.payload,
      };
    case USERCHOSEN_UPRN:
      return {
        ...state,
        userChosenUPRN: action.payload,
      };
    case USERCHOSEN_SOR:
      return {
        ...state,
        userChosenSOR: action.payload,
      };
    case USERCHOSEN_STATUS:
      return {
        ...state,
        userChosenSTATUS: action.payload,
      };
    case USERCHOSEN_ELIGIBILITY_ERRORCODE:
      return {
        ...state,
        userChosenEligibilityErrorCode: action.payload,
      };
    case USERCHOSEN_REPAIR_LOCATION_ID:
      return {
        ...state,
        userChosenRepairLocationId: action.payload,
      };
    case LOGIN_TIME:
      return {
        ...state,
        loginTime: action.payload,
      };
    case INITIAL_LOGIN_TIME:
      return {
        ...state,
        initialLoginTime: action.payload,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        refershToken: action.payload,
      };
    case ACCESS_TOKEN_EXPIRE_TIME:
      return {
        ...state,
        accessTokenExpirationTime: action.payload,
      };
    case REFRESS_TOKEN_EXPIRE_TIME:
      return {
        ...state,
        refreshTokenExpirationTime: action.payload,
      };
    case UPDATE_PREV_CONFIRMED_STATE:
      return {
        ...state,
        previousConfirmedState: action.payload,
      };
    case Is_Edit_State:
      return {
        ...state,
        isEditState: action.payload,
      };
    case USERCHOSEN_BANLANCE_TYPE:
      return {
        ...state,
        banlancetype: action.payload,
      };
    case USERCHOSEN_ACCOUNT_NUMBER:
      return {
        ...state,
        accountnumber: action.payload,
      };
    case USERCHOSEN_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
    case USERCHOSEN_Other_BALANCE:
      return {
        ...state,
        OtherBalance: action.payload,
      };
    case USER_PROFILE_ETAG:
      return {
        ...state,
        userProfileETag: action.payload,
      };
    case USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case USER_PROFILE_PERMISSION:
      return {
        ...state,
        userProfilePermissions: action.payload,
      };
    case CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION:
        return {
          ...state,
          createEnhancedHubRepairPermissions: action.payload,
        };      
    case USER_PROFILE_CASE_CREATION_PERMISSION:
      return {
        ...state,
        createCasePermissions: action.payload,
      };
    case COMPONENT_INFO:
      return {
        ...state,
        componentInformation: action.payload,
      };
    case TRANSACTION_INFO:
      return {
        ...state,
        transactionInformation: action.payload,
      };
    case HIERARCHY_OF_PREMISES:
      return {
        ...state,
        hierarchyOfPremises: action.payload,
      };
    case USERCHOSEN_ACCOUNT_Type:
      return {
        ...state,
        accountType: action.payload,
      };
    case USERCHOSEN_ACCOUNT_List:
      return {
        ...state,
        accountData: action.payload,
      };
    case USERCHOSEN_CUSTOMER_ACCOUNT_List:
      return {
        ...state,
        customerAccountData: action.payload,
      };
    case USERCHOSEN_TENANCY_GROUP:
      return {
        ...state,
        tenancyGroup: action.payload,
      };
    case UPDATE_ROUTE_HISTORY:
      return {
        ...state,
        routeHistory: [...state.routeHistory, action.payload],
      };
    case Allpay_Conformation_Message:
      return {
        ...state,
        conformationMessage: action.payload,
      };
    case USERCHOSEN_ERROR_CODE:
      return {
        ...state,
        errorCode: action.payload,
      };
    case USERCHOSEN_ISELIGIBLE:
      return {
        ...state,
        isEligible: action.payload,
      };
    case RESUME_DRAFT_REPAIR:
      return {
        ...state,
        resumeDraftRepair: action.payload,
      };
    case USER_REPAIR_WORKORDER:
      return {
        ...state,
        userRepairWorkOrder: action.payload,
      };
    case USER_RESUME_DATA:
      return {
        ...state,
        userResumeData: action.payload,
      };
    case USER_RESUME_DRAFT_DATA_CACHE:
      return {
        ...state,
        userResumeDraftDataCache: action.payload,
      };
    case USERCHOSEN_ISERROR:
      return {
        ...state,
        userIsError: action.payload,
      };
    case Is_Active_Tenancy:
      return {
        ...state,
        isActiveTenancy: action.payload,
      };
    case USERCHOSEN_SEARCHTYPE:
      return {
        ...state,
        SearchType: action.payload,
      };
    case USERCHOSEN_SEARCHTEXT:
      return {
        ...state,
        SearchText: action.payload,
      };
    case USERCHOSEN_RAISEDSINCE:
      return {
        ...state,
        RaisedSince: action.payload,
      };
    case USERCHOSEN_RAISEDUPTO:
      return {
        ...state,
        RaisedUpto: action.payload,
      };
    case USERCHOSEN_TENANCYCOUNT:
        return {
          ...state,
          tenancyCount: action.payload,
        };
    case USERCHOSEN_BACKBUTTON:
      return {
        ...state,
        BackButton: action.payload,
      };
    case USERCHOSEN_REPORTER_ID:
      return {
        ...state,
        userChosenReporterId: action.payload,
      };
    case APP_CONFIG_VANDALISM_VALUE:
      return {
        ...state,
        vandalismtoggleValue: action.payload,
      };
    case USERCHOSEN_ASSETVALUE:
      return {
        ...state,
        assetValue: action.payload,
      };
    case USERCHOSEN_ISSEARCH:
      return {
        ...state,
        IsSearch: action.payload,
      };
    case USERCHOSEN_ISRECALL:
      return {
        ...state,
        IsRecall: action.payload,
      };
    case IS_CHECK_FOR_LANDLORD: 
      return {
        ...state,
        landlordCheck: action.payload,
      };
    case BACK_TO_CASE_SELECT_CUSTOMER: return {
        ...state,
        backToCaseSelectCustomer: action.payload,
    };
    case IS_CATALYST_CUSTOMER:
      return {
        ...state,
        isCatalystProperty: action.payload,
      };
    case CATALYST_STATUS :
        return {
           ...state,
         catalystStatus : action.payload,
      };
    case USERCHOSEN_SAVEEXIT:
      return {
        ...state,
        userChosenSaveExit: action.payload,
      };
    case USERCHOSEN_RESUME_REPAIR:
      return {
        ...state,
        userChosenResumeRepair: action.payload,
      };
    case LOAD_DUPLICATE_REPAIR_PAGE:
      return {
        ...state,
        loadDuplicateRepairPage: action.payload,
      };
    case USERCHOSEN_DUPLICATE_ENTITY:
      return {
        ...state,
        userChosenDuplicateEntity: action.payload,
      };
    case ASSOCIATED_ASSETS:
    return {
      ...state,
      userChosenAssociatedAssets: action.payload,
    };
    case No_UNIT:
      return {
        ...state,
        userChosenNoUnit: action.payload,
      };
      case No_UNIT:
        return {
          ...state,
          userChosenNoUnit: action.payload,
        };
    case USER_CREATED_NEW_REPAIR:
      return {
        ...state,
        userCreatedNewRepair: action.payload,
      };
      
    case BACK_TO_CASE_SELECT_CUSTOMER: return {
        ...state,
        backToCaseSelectCustomer: action.payload,
    };
    case UPDATE_USER_CHOICES_WITH_NEW_CHANGE:
      const presentUrlIndex = state.routeHistory.findIndex((e: any) => {
        return e === action.payload;
      });

     

      let newChanges: any = {};

      for (let i = presentUrlIndex + 1; i < state.routeHistory.length; i++) {
        Object.values(
          state.previousConfirmedState[
          state.routeHistory[i] != "RepairAdditionalDetails"
            ? state.routeHistory[i]
            : "DuplicateRepair"
          ]
        ).map((ref: any) => {
          if (ref.key) {
            if (ref.key === "userChosenVandalismQuestion") {
              newChanges[ref.key] = "No";
            } else {
              newChanges[ref.key] = "";
            }
          }
          return;
        });
      }      

      return {
        ...state,
        ...newChanges,
          };
    case UCC_TENANCY_MODIFIED_DATA:
      return {
        ...state,
        uCCTenancyModifiedData: action.payload,
      };
    case UCC_RENT_MODIFIED_DATA:
      return {
        ...state,
        uCCRentModifiedData: action.payload,
      };
    case ASSETS_BY_ID_DATA:
      return {
        ...state,
        assetsByIDData: action.payload,
      };
    case CREATE_ARREARS_RESPONSE:
      return {
        ...state,
        createArrearsResponse: action.payload,
      };
    case IS_CASE_ALLOWED:
            return {
              ...state,
              isCaseAllowed: action.payload,
            };
    case MEDIA_RESUME:
              return {
                ...state,
                isMediaResumed: action.payload,
          };
    case UPLOADED_MEDIA_ITEMS:
            return {
              ...state,
              mediaItems: [...state.mediaItems, action.payload],
            };
    case CLEAR_MEDIA_FILES:
              return {
                ...state,
                mediaItems: [],
                userChosenUploadedMedia: []
            };
    case DELETE_MEDIA_ITEM:
              return {
                ...state,
                mediaItems: [
                ...state.mediaItems.filter((item: any) => item !== action.payload),
            ],
          };
    case USERCHOSEN_DELETE_MEDIA:
            return {
              ...state,
              userChosenUploadedMedia: action.payload,
            };
    case USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS:
              return {
                ...state,
                userChosenUploadedMediaIdentifiers: action.payload
            };
    case UPLOADED_MEDIA_ITEMS:
              return {
                ...state,
                mediaItems: [...state.mediaItems, action.payload],
              };
    case CLEAR_MEDIA_FILES:
      return {
        ...state,
        mediaItems: [],
        userChosenUploadedMedia: []
    };
    case DELETE_MEDIA_ITEM:
                return {
                  ...state,
                  mediaItems: [
                  ...state.mediaItems.filter((item: any) => item !== action.payload),
              ],
            };
    case USERCHOSEN_DELETE_MEDIA:
      return {
        ...state,
        userChosenUploadedMedia: action.payload,
    };
    case USERCHOSEN_UPLOADED_MEDIA:
      return {
        ...state,
        userChosenUploadedMedia: [
          ...(state.userChosenUploadedMedia || []),
          action.payload,
        ],
    };
    case USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS:
      return {
        ...state,
        userChosenUploadedMediaIdentifiers: action.payload
    };
    case HAS_MEDIA_ATTACHED:
              return {
                ...state,
                mediaAttached: action.payload,
              };
    case FROM_MEDIA_PAGE:
      return {
        ...state,
        isFromMediaPage: action.payload,
      };
    case IS_CASE_MEDIA_ALLOWED:
      return {
        ...state,
        isCaseMediaAllowed: action.payload,
      };
    case IS_REPAIR_MEDIA_ALLOWED:
        return {
          ...state,
          isRepairMediaAllowed: action.payload,
        };
    case IS_REPAIR_COVID_ALLOWED:
      return {
        ...state,
        isRepairCovidAllowed: action.payload,
      };
    case USER_TYPED_REPAIR_REASONS:
          return {
            ...state,
            userChoiceRepairReasons: action.payload,
          }  ;
    case SURVEY_REASON:
            return {
              ...state,
              userTypedSurveyReason: action.payload,
            };   
    case USERCHOSEN_ISSURVEY:
                    return {
                      ...state,
                      isSurvey: action.payload,
                };          
    case IS_REPAIR_MEDIA_RESUME:
            return {
              ...state,
              isRepairMediaResume: action.payload,
            };
    case WORKORDER_CHRONOLOGY_TAB:
      return {
        ...state,
        woChronologyTabState: action.payload,
      };
    case IS_P360_REPAIRS_MAINTAINENCE:
      return {
        ...state,
        isP360RepairsMaintainenceValue: action.payload,
      };
    case DEMOBILIZED_CONTRACTOR_SITES:
      return {
        ...state,
        demobilizedContractorSites: action.payload,
      };
    case USER_SELECTED_TENANCY_GROUP:
      return{
        ...state,
        selectedTenancyGroup:action.payload
      };
      case USER_EMAIL:
        return{
          ...state,
          email: action.payload

        };
    case SAVE_SUPPLIMENTARY_CODES :
      return{
          ...state,
          supplimentaryCodes: action.payload
        };                
    default:
      return state;
  }
};
export default userChoices;