import { useState, useEffect } from 'react';

export  default function useBackToTop() {
  const [width, setWidth] = useState(window.innerWidth);
  const [backToTop, setBackToTop] = useState(false);
  

const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
 }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
        return () => {
          window.removeEventListener("resize", handleWindowSizeChange);
        };
  }, [width]); 

//istanbul ignore next 
const toggleVisibility = () => {
    if (window.scrollY > window.innerHeight) {
        setBackToTop(true)
    }else {
        setBackToTop(false)
    }
}

useEffect(()=>{
    window.addEventListener("scroll", toggleVisibility);
    return () => {
        window.removeEventListener('scroll',toggleVisibility);
    }
},[])

  return { width, backToTop };
}