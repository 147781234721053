import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import * as api from "../../api";
import {
  assetDataStatus,
  customersData,
  userChosenPropertyAddress,
  userChosenPropertyId,
  assetDataResponse,
  localAuthority,
  keyContact,
  userChosenBackValue,
  userChosenUPRN,
  assetPropertyId,
  customerPreferenceData,
  userChosenAlertValue,
  userChosenAssetHierarchy360,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenVandalismQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenDuplicateQuestion,
  userChosenEmergencyQuestion,
  userChosenRepairLocationId,
  userChosenHighLevelDescription,
  userChosenRepairLocation,
  userChosenSupplementaryQuestions,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenIsReporter,
  isActiveTenancy,
  userChosenIsRecall,
  loadDuplicateRepairPage,
  userChosenResumeRepair,
  userChosenDuplicateEntity,
  userChosenCurrentValue,
  backToCaseAsset,
  userChosenAssetValue,
  userChosentenancyGroup,
  caseAssetAddress,
  userChosenReporterId,
  userChosentenancyCount,
  caseCreatedBy,
  noUnit,
  customerAdditionalAlertData,
  clearMediaFiles,
  userChosenUploadedMediaIdentifiers, isCatalystProperty,userChosenIsSurvey, userChosenNotifyAll,
  selectedTenancyGroup,
  premisesTypeList
} from "../../ducks/redux/actions/userChoices";
import { PermissionsList } from "../../models/Permissions";
import AssetDataResponse from "../../models/Property/AssetDataResponse";
import { addressToStringDetails, formatDate, isNullOrUndefined } from "../../utils/formatting";
import AssetCardAsset360 from "../AssetCardAsset360";
import CustomerAssetCard from "../CustomerAssetCard";
import WorkSummaryCard from "../WorkSummaryCard";
import { trackEvent } from "../../appInsights/TelemetryService";

import {
  getCustomerId,
  saveCustomerDetails,
  saveCustomerphoneNumber,
  saveTenancySummaryList,
  selectedTenacyType,
  workOrderRepairObject,
  assetData,
  saveAssociatedAssests,
  getReferenceNumber,
  saveCustomerTenacies,
  getHeatingHotWaterCase,
  getMaintResp,
  getRepairResp,
  getContractorName,
  getDeterminedContractorDetails,
  getContractorPayload,
  saveSupplimentaryCodes
} from "../../ducks/redux/actions/getAddress";
import AsssetDetailCard from "../AssetDetailCard";
import KeyContactAssetCard from "../KeyContactAssetCard";
import { AlertValue, BackValue, CustomerOrProperty } from "../../models/shared/Enum";
import CustomerCardDetails from "../../models/Customer/CustomerCardDetail";
import CautionAlert from "../CautionAlert";
import PropertyAlert from "../PropertyAlert"
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { userTypedRepairReasons, updateDashboardForm, assetByIdDataObject, priorityCode } from "../../ducks/redux/actions/dashboardReducer.actions";
import { Assets } from "../../models/Property/PropertyResponse";
import Preference from "../../models/Customer/Preference";
import { CASE_ASSET_CHECK, CASE_ASSET_TYPE, CASE_CUSTOMER_NAME, CASE_CUSTOMER_SEARCH, CASE_SELECTED_ANSWERS, CASE_TENANCY_ID, CASE_TENANCY_TYPE, CLEAR_CASE_STATE, CONTACT_INFORMATION_VALUE, EDIT_BUTTON_CLICK, ELIGIBLE_SELECT_CUSTOMER, USERCHOSEN_PROPERTY_ID } from "../../ducks/redux/actionTypes";
import TenancySummary from "../../models/Customer/TenancySummary";
import Tenancies from "../../models/Customer/Tenancies";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import CaseCard from "../Cases/CaseCard";
import AdditionalNeedAlert from "../AdditionalNeedAlert";
import { getDeepLink } from '../../utils/formatting';
import openNewWindow from "../../assets/icons/Open-new-window-icon.png";
import { releaseAssetArrayDispatch, assetBackCouterDispatch, removeAssetArrayDispatch, assetArrayDispatch } from "../../ducks/redux/actions/FinancialReferral";
import BackToTopComponent from "../BackToTop";
import useBackToTop from "../BackToTop/useBackToTop";
import GasBoilerCard from "../GasBoilerCard";
import EICRCard from "../EICRCard";
import RedAlert from "../RedAlert";
import { getserviceCardData } from "../ServiceCard/serviceCard";
import { PremisesType } from "../../models/Customer/Premises";
import { RelatedPremisesType } from "../../models/Property/AdminUnits";


interface Asset360ViewProps extends RouteComponentProps<any> { }
const Asset360View: React.FC<Asset360ViewProps> = (props) => {
  const isCheckForLandlord = process.env.REACT_APP_ISCHECKFORLANDLORD;
  const dispatch = useDispatch();
  const {backToTop, width} = useBackToTop();
  const [totalCount, setTotalCount] = useState(0);
  const array: AssetDataResponse[] = [];
  const [isLoading, setIsLoading] = useState(true);
  const [propertyList, setPropertyList] = useState(array);
  const [propertyAddress, setPropertyAddress] = useState("");
  const [isWorkCard, setIsWorkCard] = useState(false);
  const [isAssetCard, setIsAssetCard] = useState(false);
  const [isCustomerCard, setIsCustomerCard] = useState(false);
  const [isRepair, setIsRepair] = useState(false);
  const [assetStatus, setAssetStatus] = useState("");
  const [isCustomerComponent, setIsCustomerComponent] = useState(false);
  const [isKeyContactCard, setIsKeyContactCard] = useState(false);
  let customerId: CustomerCardDetails[] = [];
  let tenancyData: TenancySummary[] = [];
  const EICRCardToggle = "isshowp360eicrcard";
  const GASCardToggle = "isshowp360gasboilercard";
  const [GASCardToggleValue, setGASCardToggleValue] = useState("false");
  const [EICRCardToggleValue, setEICRCardToggleValue] = useState("false");

  const [tenancyActiveList, setTenancyActiveList] = useState(tenancyData);
  const [customerIdList, setIsCustomerIdList] = useState(customerId);
  const [custId, setCustId] = useState("");
  const [isAssetDetailCard, setIsAssetDetailCard] = useState(false);
  const [userAssetId, setUserAssetId] = useState("");
  const [isPropertyBanner, setIsProperty] = useState(false);
  const [preferenceCheck, setPreferenceCheck] = useState(false);
  const [propertyBannerCheck, setPropertyBannerCheck] = useState(false);
  const [assetType, setAssetType] = useState("");
  const [isCautionBanner, setIsCaution] = useState(false);
  const [isTenancyActiveFlag, setIsTenancyActive] = useState(false);
  const [isEligibleForSelectCustomer, setIsEligibleForSelectCustomer] = useState(false);

  const [landlordValues, setLandlordValues] = useState([""]);
  const [identifiedCatalystProperty, setIsIdentifiedCatalystProperty] = useState(false);
  const [catalystBannerMessageA, setCatalystBannerMessageA] = useState("");
  const [catalystBannerMessageB, setCatalystBannerMessageB] = useState("");
  const [isCaseCard, setIsCaseCard] = useState(false);
  const [showVantage, setShowVantage] = useState(false);
  const [EICRserviceAlert, setEICRServiceAlert] = useState("");
  const [GASserviceAlert, setGASServiceAlert] = useState("");
  let [EICRPropsData, setEICRPropsData] = useState<any>();
  let [GASPropsData, setGASPropsData] = useState<any>();
  
  let assetRegionValue: string = "";
  const history = useHistory()

  enum LandlordAppConfigValues {
    IsCheckForLandlord = "IsCheckForLandlordP360",
    LandlordValues = "LandlordValues",
    CatalystRepairDisabledAreas = "CatalystRepairDisabledAreasP360",
    CatalystRegistrationAreas = "CatalystRegistrationAreas",
    isCataLystCaseCard= "isCataLystCaseCard"
  };

  enum AssetTypes {
    Property = "Property",
    Block = "Block",
    Estate = "Estate",
    DividedBlock = "Divided Block"
  };
  const serviceData = useSelector((state:any) => state?.financialReferralReducer?.serviceCardData);
  const gasServiceData = useSelector((state:any) => state?.financialReferralReducer?.gasCardData);
  const [serviceAlert, setServiceAlert] = useState("");

  const finishJourney = useSelector((state:any) => state?.financialReferralReducer?.isJourneyFinish);
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );
  
  const isCaseCheck = useSelector(
    (state: any) => state?.userChoicesReducer?.isCaseAllowed
  );
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const [dropDown, setDropDown] = useState("none");
  const { t } = useTranslation();
  let counter = useSelector((state:any) => state?.financialReferralReducer?.assetBackCounter);
  const storeAssetData = useSelector((state:any) => state?.financialReferralReducer?.assetArrayData);
  let assetDataDetails = useSelector((state: any) => state?.addresses?.assetData);


  // Checking if back is clicked
  useEffect(() => {
    if (storeAssetData?.length > 0) {
      assetDataDetails = storeAssetData[storeAssetData?.length - 1];
    }
  }, [storeAssetData]);

  const backButtonHandler = () => {
    if (storeAssetData === undefined || storeAssetData.length <= 1 ){
      dispatch(assetPropertyId(""))
      dispatch(releaseAssetArrayDispatch(null))
      dispatch(assetBackCouterDispatch(0))
      
      if(finishJourney){
        props.history.push("/LandingView")
      }
      else if(props.location.pathname === "/Asset360View")
      {
        history.push("/LandingView");
      }
      else{history.goBack()}
      
    }else {
      dispatch(assetBackCouterDispatch(counter-1))
      dispatch(removeAssetArrayDispatch(storeAssetData[storeAssetData?.length]))
      window.location.reload()
    }
  };


  let customerResponse: CustomerResponseDetails;
  let customerResponseSecondData: CustomerResponseDetails;
  const clear = () => {
    dispatch(userChosenIsSurvey(false));
    dispatch(userChosenNotifyAll(null)); 
    dispatch(userChosentenancyCount(0))
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(getReferenceNumber(null))
    dispatch(userTypedRepairReasons(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenDuplicateQuestion(""));
    dispatch(userChosenDuplicateEntity(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenVandalismQuestion("No"));
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenVulnerabilityQuestion(""));
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenSOR(""));
    dispatch(getRepairResp(""));
    dispatch(getHeatingHotWaterCase(""));
    dispatch(getMaintResp(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    dispatch(selectedTenacyType(""));
    dispatch(workOrderRepairObject(null));
    dispatch(getDeterminedContractorDetails(""));
    dispatch(getContractorName(""));
    dispatch(getContractorPayload(""));
    dispatch(userChosenIsReporter(false));
    dispatch(loadDuplicateRepairPage(false));
    dispatch(userChosenResumeRepair(false));
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS });
    dispatch({ type: CASE_CUSTOMER_SEARCH })
    dispatch({ type: CASE_TENANCY_ID })
    dispatch({ type: CONTACT_INFORMATION_VALUE })
    dispatch({ type: CASE_TENANCY_TYPE })
    dispatch(saveCustomerphoneNumber(null))
    dispatch(caseAssetAddress(""))
    dispatch(userChosenRelationDetails(''))
    dispatch(userChosenReporterId(''))
    dispatch({ type: EDIT_BUTTON_CLICK, payload: false })
    dispatch(getCustomerId(''));
    dispatch({ type: CASE_CUSTOMER_SEARCH, payload: '' })
    dispatch(caseCreatedBy(""));
    dispatch({ type: CASE_CUSTOMER_NAME, payload: '' })
    dispatch({ type: CASE_ASSET_CHECK, payload: false })
    dispatch({ type: ELIGIBLE_SELECT_CUSTOMER, payload: false })
    dispatch(customerPreferenceData({}))
    dispatch(customerAdditionalAlertData({}))
    dispatch(clearMediaFiles([]));
    dispatch(userChosenUploadedMediaIdentifiers([]));
    dispatch(assetByIdDataObject(null));
    dispatch(selectedTenancyGroup(''));
    dispatch(saveSupplimentaryCodes(''));
  }

  const getAssetsListforLandlordCheck = async (associatedAssetsData : any) => {
    let assetList: AssetDataResponse[] = [];
    try {
      const associatedAssetsData:any = await api.getAssociatedAssets(
        assetDataDetails.id
      );
      let associatedAssetData;
      if (assetDataDetails?.assetType == AssetTypes.Estate) {
        associatedAssetData = associatedAssetsData.filter(
          (x:any) => x.assetType === AssetTypes.Block || x.assetType === AssetTypes.Property
        );
      } else if ((assetDataDetails?.assetType == AssetTypes.Block)) {
        associatedAssetData = associatedAssetsData.filter(
          (x:any) =>
            x.assetType === AssetTypes.Property || x.assetType === AssetTypes.DividedBlock
        );
      } else if ((assetDataDetails?.assetType == AssetTypes.DividedBlock)) {
        associatedAssetData = associatedAssetsData.filter(
          (x:any) =>
            x.assetType === AssetTypes.Property
        );
      }
      else associatedAssetData = associatedAssetsData;
      if (associatedAssetData != undefined) {
        associatedAssetData.forEach((element :any) => {
          const assetEntry: AssetDataResponse = new AssetDataResponse();
          assetEntry.id = element.id;
          assetEntry.address = addressToStringDetails(
            element?.addresses?.postalAddresses[0]
          );
          assetEntry.assetType = element.assetType;
          assetEntry.uprn = element.uprn;
          assetEntry.addresses = element?.addresses
          if (assetDataDetails.id != assetEntry.id) {
            assetList.push(assetEntry);
          }
        });
      }
      return assetList;

    } catch (e) {
      console.error(e);
      setIsLoading(false)
      props.history.push("/GenericError");
    }
  }

  const getIsShowP360AssetComplianceDataToggle = async()=> {
    try {
      const EICRCardToggleResponse = await api.GetAppConfigToggleValue(EICRCardToggle);
      const GASCardToggleResponse = await api.GetAppConfigToggleValue(GASCardToggle);
    
      if (EICRCardToggleResponse) {
        setEICRCardToggleValue(EICRCardToggleResponse.value);
      }
    
      if (GASCardToggleResponse) {
        setGASCardToggleValue(GASCardToggleResponse.value);
      }
    } catch (error) {
      console.error("Error fetching toggle values:", error);
    }
  }
  
  useEffect(() => {
     // Adding a toggle to display/hide service card and alerts
     getIsShowP360AssetComplianceDataToggle()
    window.scrollTo(0, 0);
    setIsLoading(true)
    dispatch(saveAssociatedAssests(null));
    dispatch(userChosenIsRecall(false))
    dispatch(userChosenAssetHierarchy360(BackValue.Asset360View));
    (async () => {
      clear();
      trackEvent("Asset360Page");
      if (isUndefinedNullOrEmpty(assetDataDetails)) {
        props.history.push("/LandingView");
        return;
      }
      dispatch(userChosenBackValue(BackValue.Asset360View));
      dispatch(userChosenCurrentValue(BackValue.Asset360View));
      let assetList: AssetDataResponse[] = [];

      const showWork = getPermissionsData?.indexOf(
        PermissionsList.view_asset360_wo_summary_card
      );
      if (showWork != -1) {
        setIsWorkCard(true);
      } else {
        setIsWorkCard(false);
      }
      const showAsset = getPermissionsData?.indexOf(
        PermissionsList.view_asset360_asset_hierachy_summary_card
      );
      if (showAsset != -1) {
        setIsAssetCard(true);
      } else {
        setIsAssetCard(false);
      }
      const showCustomer = getPermissionsData?.indexOf(
        PermissionsList.view_asset360_customer_summary_card
      );
      if (showCustomer != -1) {
        setIsCustomerCard(true);
      } else {
        setIsCustomerCard(false);
      }
      const showRepair = getPermissionsData?.indexOf(
        PermissionsList.create_asset360_asset_property_raise_a_repair
      );
      if (showRepair != -1) {
        setIsRepair(true);
      } else {
        setIsRepair(false);
      }

      const showProperty = getPermissionsData?.indexOf(
        PermissionsList.view_asset360_property_alerts_banner
      );
      setIsProperty(showProperty != -1);
      const showOpenVantage = getPermissionsData?.indexOf(PermissionsList.view_open_crm);
      setShowVantage(showOpenVantage !=-1)
      const showCaution = getPermissionsData?.indexOf(PermissionsList.view_asset360_caution_alerts_banner);
      if (showCaution != -1) {
        setIsCaution(true)
      }
      else {
        setIsCaution(false)
      }
      dispatch(isActiveTenancy(false))
      setIsKeyContactCard(false);
      setIsCustomerComponent(false)
      setPreferenceCheck(false);
      const associatedAssetsData:any = await api.getAssociatedAssets(assetDataDetails.id);    
     
      const premisesList: RelatedPremisesType[] = [];
      associatedAssetsData.forEach((element:any) => {
        const premisesEntry: RelatedPremisesType = new RelatedPremisesType();
        premisesEntry.premisesType = element.assetType as PremisesType;
        premisesEntry.premisesTypeId = element.id;
        premisesList.push(premisesEntry);
      });
      dispatch(premisesTypeList(premisesList))
      callAlertApi(assetDataDetails?.id);
      customerCallApi(assetDataDetails?.uprn, assetDataDetails?.assetType, assetDataDetails?.id);
      setAssetType(assetDataDetails?.assetType);
      dispatch(selectedTenacyType(assetDataDetails?.assetType));     
      callGetAssetByIdApi(assetDataDetails?.id, associatedAssetsData);
      dispatch(
        userChosenPropertyAddress(
          addressToStringDetails(assetDataDetails?.addresses?.postalAddresses[0])
        )
      );
      try {
        if (assetDataDetails?.assetType != "Property") {        
          let i = 0;
          dispatch(noUnit(i));
          let associatedAssetData;
          if (assetDataDetails?.assetType == "Estate") {
            associatedAssetData = associatedAssetsData?.filter(
              (x:any) => x.assetType === "Block"
            );
          } else if ((assetDataDetails?.assetType == "Block")) {
            associatedAssetData = associatedAssetsData?.filter(
              (x:any) =>
                x.assetType === "Property" || x.assetType === "Divided Block"
            );
          } else if ((assetDataDetails?.assetType == "Divided Block")) {
            associatedAssetData = associatedAssetsData?.filter(
              (x:any) =>
                x.assetType === "Property"
            );
          }
          else associatedAssetData = associatedAssetsData;
          if (associatedAssetData != undefined) {

            associatedAssetData.forEach((element:any) => {
              if (assetDataDetails?.assetType == "Block") {
                i = i + 1;
              }

              const assetEntry: AssetDataResponse = new AssetDataResponse();
              assetEntry.id = element.id;
              assetEntry.address = addressToStringDetails(
                element?.addresses?.postalAddresses[0]
              );
              assetEntry.assetType = element.assetType;
              assetEntry.uprn = element.uprn;
              assetEntry.addresses = element?.addresses
              if (assetDataDetails.id != assetEntry.id) {
                assetList.push(assetEntry);
                setTotalCount(assetList?.length);
              }
            });
            dispatch(noUnit(i));
            setPropertyList(assetList);
          }
        }

      } catch (e) {
        console.log(e);
        setIsLoading(false)
        props.history.push("/GenericError");
      }
    })();
  }, []);

  const getGasBoilerData = async(assetId:string)=> {
    const complianceType = "Annual Domestic Gas Servicing"
    try {
      const [res, res1] = await Promise.all([
        getserviceCardData(assetId, "EICR 5Y"),
        getserviceCardData(assetId, complianceType)
      ]);
      
      const hasOverdueService = (data: any) => {
        const currDate = new Date().toISOString();
        return data?.visitDue < currDate && data?.dateDecommissioned === null;
      };
      if (res?.totalCount > 0 || res1?.totalCount > 0) { 
            EICRPropsData = res?.compliances[0];
            GASPropsData = res1?.compliances[0];
          if (hasOverdueService(EICRPropsData) ) {
            setEICRPropsData(res?.compliances[0]);
            setEICRServiceAlert(`There is an Electrical service overdue for this property. Please contact ${EICRPropsData?.contractor?.contractorName || "-"} on ${EICRPropsData?.contractor?.contractorNumber ||"-"} to schedule a service visit.`);
          } 
          if( hasOverdueService(GASPropsData)){
            setGASPropsData(res1?.compliances[0])
            setGASServiceAlert(`There is a Gas boiler service overdue for this property. Please contact ${GASPropsData?.contractor?.contractorName ||"-"} on ${GASPropsData?.contractor?.contractorNumber ||"-"} to schedule a service visit.`);
          }
      }
    }catch(e:any){
      console.log(e)
    }
  }


  useEffect(()=> {
    getGasBoilerData(assetDataDetails?.id)
  },[assetDataDetails?.id])
  
  const callGetAssetByIdApi = (id: string, associatedAssetsData : any) => {
    (async () => {
      try {

        if (id != undefined && id != null) {
          const assetResponse = await api.getAssetsByid(id);
          dispatch(assetByIdDataObject(assetResponse));
          dispatch({ type: CASE_ASSET_TYPE, payload: assetResponse?.assetType });
          dispatch(assetDataResponse(assetResponse));
          dispatch(
            updateDashboardForm({
              assetId: assetResponse?.id
            })
          )
          dispatch(caseCreatedBy(assetResponse?.assetType));
          setAssetStatus(assetResponse?.assetStatus);
          assetRegionValue = assetResponse?.region;
          dispatch(assetDataStatus(assetResponse?.assetStatus));
          const data = await api.GetPropertyAssociates(id);
          dispatch(userChosenAssetValue(assetResponse?.assetType));
          if (assetResponse.assetType == "Property") {
            const keyContactDetails = {
              regionalHousingManager:
                data.associates && data.associates.regionalHousingManager
                  ? data.associates.regionalHousingManager.person.name.givenName +
                  " " +
                  data.associates.regionalHousingManager.person.name.surname
                  : "",
              areaHousingManager:
                data.associates && data.associates.areaHousingManager
                  ? data.associates.areaHousingManager.person.name.givenName +
                  " " +
                  data.associates.areaHousingManager.person.name.surname
                  : "",
              assistantHeadOfNeighbourhoods:
                data.associates && data.associates.assistantHeadOfNeighbourhoods
                  ? data.associates.assistantHeadOfNeighbourhoods.person.name
                    .givenName +
                  " " +
                  data.associates.assistantHeadOfNeighbourhoods.person.name
                    .surname
                  : "",
              neighbourhoodManager:
                data.associates && data.associates.neighbourhoodManager
                  ? data.associates.neighbourhoodManager.person.name.givenName +
                  " " +
                  data.associates.neighbourhoodManager.person.name.surname
                  : "",
            };
            dispatch(keyContact(keyContactDetails));
            if (data != undefined) {
              setIsKeyContactCard(true);
            }
          }

          checkIsCatalystProperty(assetRegionValue, assetDataDetails.assetType, assetDataDetails.id, associatedAssetsData)

          dispatch(localAuthority(data.associates.localAuthority.person.name.givenName));
          if (assetResponse != undefined && data != undefined) {
            setIsAssetDetailCard(true);
          }
        }
        else {
          console.error("Asset id is null");
          props.history.push("/GenericError");
        }
        setIsLoading(false)
      } catch (e) {
        console.error(e);
        setIsLoading(false)
        props.history.push("/GenericError");
      }
    })();
  };
  
  useEffect(()=> {
   
      const currDate = new Date().toISOString()
      const isServiceOverdue = (data:any) =>data?.visitDue < currDate;
      if (isServiceOverdue(serviceData) ) {
        setServiceAlert(`There is an overdue service for this property. Please contact ${serviceData?.contractor?.contractorName || "-"} on ${serviceData?.contractor?.contractorNumber || "-"} to schedule a service visit.`);
      }else if( isServiceOverdue(gasServiceData)){
        setServiceAlert(`There is an overdue service for this property. Please contact ${gasServiceData?.contractor?.contractorName || "-"} on ${gasServiceData?.contractor?.contractorNumber || "-"} to schedule a service visit.`);
      }
   
  },[gasServiceData, serviceData])
  

  const checkIsCatalystProperty = (assetRegionValue: string, assetDataDetailsAssetType: string, assetDataDetailsId:string,associatedAssetsData:any) => {
    (async () => {

    const appCatalystRegistrationAreasValues = await api.GetAppConfigToggleValue(
      LandlordAppConfigValues.CatalystRegistrationAreas
    );
    const appCatalystRepairDisabledAreasValues = await api.GetAppConfigToggleValue(
      LandlordAppConfigValues.CatalystRepairDisabledAreas
    );

    const isCatalystRegistrationAreas = !isUndefinedNullOrEmpty(assetRegionValue) && appCatalystRegistrationAreasValues.value.toLowerCase().includes(assetRegionValue.toLowerCase())
    const isCatalystDisabledArea = !isUndefinedNullOrEmpty(assetRegionValue) && appCatalystRepairDisabledAreasValues.value.toLowerCase().includes(assetRegionValue.toLowerCase());
    let isEnabledCatalystProperty = isCatalystRegistrationAreas && !isCatalystDisabledArea

    if (isCheckForLandlord === "true") {
      const isCataLystCaseCardValue = await api.GetAppConfigToggleValue(
        LandlordAppConfigValues.isCataLystCaseCard
      );
      const appConfigIsCheckForLandlord = await api.GetAppConfigToggleValue(
        LandlordAppConfigValues.IsCheckForLandlord
      );

      if (appConfigIsCheckForLandlord?.value == "true") {
        let propertyId: any;
        if (assetDataDetailsAssetType != AssetTypes.Property) {
          let assetIdsforLandlordCheck = await getAssetsListforLandlordCheck(associatedAssetsData);
          if (assetIdsforLandlordCheck != undefined) {
            for (let asset of assetIdsforLandlordCheck) {
              if (asset?.assetType === AssetTypes.Property) {
                propertyId = asset?.id;
                break;
              }
            }
            if (propertyId === undefined || propertyId === null) {
              propertyId = assetDataDetailsId;
            }
          }
        } else {
          propertyId = assetDataDetailsId;
        }

        
        const appConfigLandlordValues = await api.GetAppConfigToggleValue(
          LandlordAppConfigValues.LandlordValues
        );

        let appconfigLandlordValues = appConfigLandlordValues?.value;
        let landlordsList = appconfigLandlordValues.split(",");
        setLandlordValues(landlordsList);

        const associatesdata = await api.GetPropertyAssociates(propertyId);

        let currentLandlord = associatesdata?.associates?.landLord?.organisation?.name;
        let previousLandLord = associatesdata?.associates?.previousLandLord?.organisation?.name;
        const landlordValuesExist = currentLandlord?.length > 0 || previousLandLord?.length > 0
        if (landlordValuesExist) {
          const isCatalystLandlord = 
            currentLandlord?.length > 0 && appconfigLandlordValues.includes(currentLandlord) || 
            previousLandLord?.length > 0 && appconfigLandlordValues.includes(previousLandLord)
          const identifiedCatalystProperty = isCatalystRegistrationAreas || isCatalystLandlord

          isEnabledCatalystProperty = isCatalystLandlord && isEnabledCatalystProperty
          dispatch(isCatalystProperty(identifiedCatalystProperty))
          setIsWorkCard(!isCatalystRegistrationAreas || isEnabledCatalystProperty);
          setIsIdentifiedCatalystProperty(isCatalystRegistrationAreas);
          if(isCataLystCaseCardValue.value === "true"){
            setIsCaseCard(false);
          }
          else{
            setIsCaseCard(true);
          }
        }
        else {
          dispatch(isCatalystProperty(isCatalystRegistrationAreas))
        }
      }
      else {
        dispatch(isCatalystProperty(isCatalystRegistrationAreas))
      }
    }
    else {
      dispatch(isCatalystProperty(isCatalystRegistrationAreas))
    }

    switch (assetRegionValue) {
      case 'HUB1-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub1a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB2-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub2a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB3-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub3a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      case 'HUB4-REP':
        setCatalystBannerMessageA(t("CP126EnabledHub4a"));
        setCatalystBannerMessageB(t("CP126EnabledHubb"));
        break;
      default:
        // For Non Catalyst Properties, isIdentifiedCatalystProperty will be false, message wont be visible
        // For Catalyst properties where landlord is catalyst but fron a diff asset region
        setCatalystBannerMessageA(t("CP125a"));
        setCatalystBannerMessageB(t("CP125b"));
        break;
    }

    if(isCatalystDisabledArea) {
      setCatalystBannerMessageA(t("CP125a"));
      setCatalystBannerMessageB(t("CP125b"));
    }
  })();
}

  const handleChangePropertyAddress = (e: { target: { value: any } }) => {
    setIsIdentifiedCatalystProperty(false)
    setCatalystBannerMessageA("");
    setCatalystBannerMessageB("");
    dispatch(isCatalystProperty(false))
    if (e.target.value != "Select") {
      const propertySelectedData = propertyList.filter((x) => x.id == e.target.value);
      const assetEntry: Assets = new Assets();
      assetEntry.assetType = propertySelectedData[0].assetType
      assetEntry.uprn = propertySelectedData[0].uprn
      assetEntry.id = propertySelectedData[0].id
      assetEntry.addresses = propertySelectedData[0].addresses

      dispatch(assetArrayDispatch(assetEntry))
      dispatch(assetData(assetEntry))
      dispatch(caseCreatedBy(assetEntry?.assetType));
      dispatch(userChosenAssetValue(assetEntry.assetType));
      window.location.reload();
    }

  };
  const callAlertApi = (assetId: string) => {
    (async () => {
      try {
        const alertApiResponse = await api.getAlert(assetId);
        let finalAlertData: any[] = [];
        if (alertApiResponse) {
          alertApiResponse.map((alertData: any) => {
            if (alertData?.type === AlertValue.Rapid) {
              finalAlertData.push(alertData);
              setPropertyBannerCheck(true);
              dispatch(userChosenAlertValue(finalAlertData));
            }
          })
        }
        else {
          setPropertyBannerCheck(false);
        }

      } catch (e) {
        console.error(e);
        setIsLoading(false)
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      }
    })()
  };
  const customerCallApi = (assetDataUPRN: string, assetType: string, assetId: string) => {
    (async () => {
      try {
        setIsLoading(true)
        const queryParams = {
          searchText: "",
          skip: 0,
          customerType: "Active Customer",
          orderBy: "",
          tenancyType: "",
          uprn: assetDataUPRN,
        };

        const customerSearchApi = await api.getCustomerData(queryParams);
        let  customerCallResponse = customerSearchApi?.models;

        if(customerCallResponse.length > 2) {
          customerCallResponse = customerCallResponse.filter(
            customer => 
              customer.tenancyModels.some((tenency: any) => tenency.uprn == assetDataUPRN && tenency.isMainTenant)
          )
        }
        
        if (customerCallResponse?.length === 0) {
         // dispatch(userChosenUPRN(assetDataDetails.uprn));
        } else {
          if (
            customerCallResponse[0]?.id !== undefined &&
            customerCallResponse[0]?.id !== ""
          ) {
            setCustId(customerCallResponse[0]?.id);
            dispatch(getCustomerId(customerCallResponse[0]?.id));
            let customerPreferenceDataObj: Preference[] = [];
            let custResponse: CustomerResponseDetails;
            if (assetType == "Property") {
              setIsCustomerComponent(true);
              setIsCustomerIdList(customerCallResponse);
              customerCallResponse.map(async (element: CustomerCardDetails) => {
                if (element.id !== "" && element.id !== undefined && element.id !== null) {
                  try {
                    custResponse = await api.getCustomer(element.id);
                    if (customerCallResponse[0]?.id === element.id) {
                      customerResponse = custResponse;
                      const tenancyActive = customerResponse?.tenanciesSummaries.filter(
                        (ts) =>
                          ts.tenancySummary.statuses.find(
                            (s) => s.type === "isTenancyActive"
                          )?.value === true
                      );
                      dispatch(saveCustomerTenacies(tenancyActive));
                      if (tenancyActive?.length > 0) {
                        const tenancyActiveList = tenancyActive.filter(x => x.tenancySummary.premisesIds[0] === assetId)
                        dispatch(userChosentenancyCount(tenancyActiveList.length))
                        setTenancyActiveList(tenancyActiveList)
                        setIsTenancyActive(true);
                        dispatch(isActiveTenancy(true));
                      }

                      dispatch(saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses));
                      dispatch(saveCustomerDetails(customerResponse));
                      dispatch(customersData(customerResponse));
                      dispatch(saveTenancySummaryList(customerResponse?.tenanciesSummaries));
                      if (customerCallResponse?.length > 1) {
                        let activeTenancyCount = 0;
                        customerCallResponse.map((customer) => {
                          const activeTenant = customer.tenancyModels?.length > 0 ? customer.tenancyModels.filter(
                            (ts: any) => { return ts.isTenancyActive == true; }
                          ) : null;
                          if (activeTenant != null && activeTenant != undefined) {
                            activeTenancyCount++;
                          }
                        });
                        if (activeTenancyCount > 1) {
                          setIsEligibleForSelectCustomer(true);
                          dispatch({ type: ELIGIBLE_SELECT_CUSTOMER, payload: true })
                        }
                      }
                    }

                  }
                  catch (e) {
                    console.error(e);
                    props.history.push("/GenericError");
                  }
                  if (custResponse.preferences.length > 0) {
                    customerPreferenceDataObj = customerPreferenceDataObj.concat(custResponse.preferences)
                    let customerAdditionalAlertDataObj: Preference[] = [];
                    let customerPreferenceAlertObj: Preference[] = [];
                    customerAdditionalAlertDataObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type == 'additionalNeeds');
                    customerPreferenceAlertObj = customerPreferenceDataObj?.filter((additionalNeedAlert: any) => additionalNeedAlert.preference.type != 'additionalNeeds');
                    if (customerPreferenceAlertObj.length > 0) {
                      setPreferenceCheck(true);
                      dispatch(customerPreferenceData(customerPreferenceAlertObj))
                    }
                    if (customerAdditionalAlertDataObj.length > 0) {
                      dispatch(customerAdditionalAlertData(customerAdditionalAlertDataObj))
                    }
                  }
                }
              });
            }
          }
        }
      } catch (e) {
        setIsLoading(false)
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  };
  const dropDownFunction = () => {
    if (dropDown === "none") setDropDown("block");
    else setDropDown("none");
  };
  const callPageHandler = () => {
    if(!isP360Maintainence){
    dispatch(getCustomerId(custId));
    dispatch(releaseAssetArrayDispatch(null));
    dispatch(priorityCode(''));
    props.history.push("/RepairAsset");
    }
  };
  const callVantageOnline = () => {
    const vatageURL = getDeepLink(CustomerOrProperty.Property, assetDataDetails?.addresses?.postalAddresses[0]?.id)  
    window.open(vatageURL, '_blank')
  }
  const callCaseHandler = async () => {
    backToCaseAsset(false)
    if (assetDataDetails?.assetType == "Property" && isEligibleForSelectCustomer == true) {
      dispatch({ type: USERCHOSEN_PROPERTY_ID, payload: assetDataDetails.id });
      props.history.push("/SelectCustomer");
      //multiple customer
    }
    else {
      let tenancyGroup = "ALL";
      if (customerIdList?.length > 0 && assetDataDetails?.assetType == "Property") {
        let id = customerIdList[0].id
        if (id != undefined) {
          const tenancyResponse = await api.getTenancies(id);
          tenancyGroup = tenancyResponse[0].tenancyGroup
        }
      }
      if (tenancyActiveList.length > 1) {
        props.history.push("/SelectTenancy");
        //multiple tenancy
      }
      else if ((tenancyActiveList.length === 1 || tenancyActiveList.length === 0) && assetDataDetails?.assetType === "Property") {
        dispatch(userChosentenancyGroup(tenancyGroup));
        props.history.push("/CaseType");

        //single tenancy && single Customer
      }

      else if (assetDataDetails?.assetType !== "Property") {
        dispatch(userChosentenancyGroup("All"));
        props.history.push("/CaseType");
        //communal
      }

    }
  }
  const isRepairEnabled = assetType === "Property"
  ? isTenancyActiveFlag || (assetStatus === "OCCP" || assetStatus === "VOID")
  : true;


  return (
    <div className="bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed px-4s">
      <div
        id="backmodal-div1"
        className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0">
        <div id="backmodal-p" className="text-teal text-xl mt-4 mb-4 sm-pl-1">
          <a
            data-testid="custBackBtn"
            className="back-btn mt-2"
            onClick={backButtonHandler}
          >
            <i className="fas fa-angle-left mr-2"></i>
            <span>{t("back")}</span>
          </a>
          {isRepairEnabled && isRepair && ( <div className="menu-nav ms-auto bd-highlight float-right">
                <div className="dropdown-container-h btn-group dropdownNegativeMargin">
                  <div
                    data-testid="dotsmenu"
                    className=" kebabBtn btn-sm mr-1 three-dots-ast mt-2 btn"
                    data-bs-toggle="dropdown"
                    onClick={dropDownFunction}
                  ></div>
                  <div
                    className="dropdown-ast px-2 dropdown-menu mt-45"
                    style={{ display: dropDown }}
                  >{//Logic for showing Raire Repair/Raise Communal repair Option in Kebab menu
                    isRepairEnabled ? (
                          <a
                            data-testid="callpage"
                            className="text-black no-underline dropdown-item global-txt"
                            onClick={callPageHandler}
                          >
                            <div className={"cursor-pointer text-base dropdown-item pl-0 " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>
                              {t("Create_repair")}
                            </div>
                          </a>) : ((assetType === "Divided Block" || assetType === "Block" || assetType === "Estate") ?
                            (<a
                              data-testid="callpage"
                              className="text-black no-underline dropdown-item  global-txt"
                              onClick={callPageHandler}
                            >
                              <div className={"cursor-pointer text-base dropdown-item pl-0 " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>
                                {t("raise_comm")}
                              </div>
                            </a>) : null)
                    }
                    {(isCaseCheck == 'true' && ((assetType === "Property" && (assetStatus === 'OCCP' || assetStatus === 'VOID')) || (assetType === 'Block' || assetType === 'Divided Block' || assetType === 'Estate'))) ?
                      <a data-testid="casepage" onClick={callCaseHandler} className="text-black text-base no-underline global-txt">
                        <div className="py-2 cursor-pointer text-base dropdown-item">
                          {t("CP104")}

                        </div>
                      </a> : null}
                      
                      {showVantage ? (
                        <a data-testid="vantagepage" onKeyDown={callVantageOnline} onClick={callVantageOnline} className="text-black text-base no-underline global-txt">
                          <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                          {t("open_vantage")}
                          <span className="float-right pt-1"><img
                          src={openNewWindow}
                          alt=""
                          className="h-4 w-4 my-0 text-lg text-teal"
                        ></img></span>
                          
                          </div></a>
                       ) : null}  
                  </div>
                </div>
              </div>)}
        </div>
      </div>
      {!isLoading ? (
        <div className="container mx-auto global-txt">
          <div className="w-full lg:px-0">
            {/* <div className="border-b border-grey py-2"> */}
              <div className="flex flex-row d-flex bd-highlight">
                <div className="bd-highlight ">
                  <h1 className="mpx-4 mt-4">
                    {propertyAddress != ""
                      ? propertyAddress
                      : addressToStringDetails(
                        assetDataDetails?.addresses?.postalAddresses[0]
                      )}
                  </h1>
                </div>
              </div>
              {totalCount > 0 ? (
                <div>
                  {assetDataDetails.assetType == "Divided Block"
                    ? t("DividedBlock")
                    : assetDataDetails.assetType == "Block"
                      ? t("Block")
                      : assetDataDetails.assetType == "Estate"
                        ? t("Estate")
                        : null}{" "}
                  <br />
                  <label className="w-full">
                    <select
                      className="block w-full mt-1 rounded-lg border border-grey sm-mr-1 bg-grey mb-1"
                      data-testid="propertyChangeDropDown" id="propertyDropDown"
                      onChange={handleChangePropertyAddress}
                      value={userAssetId}
                    >
                      <option>Select</option>
                      {propertyList.map((property) => (
                        <option key={property.id} value={property.id}>
                          {property.address}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              ) : null}
            {/* </div> */}
          </div>

          {identifiedCatalystProperty ? (
            <div className="warning-pre-line alert p-0 mb-8">
              <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                <div className="px-4 pr-8">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-purple-100 w-6 h-6">
                    <path d="M480 179.6C498.6 188.4 512 212.1 512 240C512 267.9 498.6 291.6 480 300.4V448C480 460.9 472.2 472.6 460.2 477.6C448.3 482.5 434.5 479.8 425.4 470.6L381.7 426.1C333.7 378.1 268.6 352 200.7 352H192V480C192 497.7 177.7 512 160 512H96C78.33 512 64 497.7 64 480V352C28.65 352 0 323.3 0 288V192C0 156.7 28.65 128 64 128H200.7C268.6 128 333.7 101 381.7 53.02L425.4 9.373C434.5 .2215 448.3-2.516 460.2 2.437C472.2 7.39 480 19.06 480 32V179.6zM200.7 192H192V288H200.7C280.5 288 357.2 317.8 416 371.3V108.7C357.2 162.2 280.5 192 200.7 192V192z"></path></svg>
                </div>
                <div className="flex items-center">
                  <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                    <div>{catalystBannerMessageA}</div><br/><div>{catalystBannerMessageB}</div></p>
                </div>
              </div>
            </div>
          ) : null}

           {/* adding service alert here */}
           {EICRserviceAlert && EICRCardToggleValue === "true" &&(
            <RedAlert Alert={EICRserviceAlert} {...props}/>
            )}
            {GASserviceAlert && GASCardToggleValue === "true" && (
            <RedAlert Alert={GASserviceAlert} {...props}/>
            )}
            {/* end service alert */}

          {preferenceCheck && isCautionBanner ? (
            <div className="pb-2">
              <CautionAlert {...props} />
            </div>
          ) : null}
          {/* Assets view */}
          {
            <div className="pb-2">
              <AdditionalNeedAlert {...props} />
            </div>
          }
          {isPropertyBanner && propertyBannerCheck ? (
            <div className="pb-2">
              <PropertyAlert  {...props} />
            </div>
          ) : null
          }

          <div className="row">
            {isAssetDetailCard ?
              <div className="col-md-6"><AsssetDetailCard {...props} />
              </div> : null}

              {isWorkCard ? (
              <div className="col-md-6 sm:mt-4">
                <div className=" relative border pb-16 border-grey bg-white rounded-lg cust-card">
                  <WorkSummaryCard {...props} />
                </div>
              </div>
            ) : null}


            {isCustomerComponent ? (
              isCustomerCard ? (
                <div className="col-md-6 mt-4">
                  <CustomerAssetCard
                    customerAssetList={customerIdList}
                    {...props} />
                </div>
              ) : null
            ) : null}

             {isAssetCard ? (
              <div className="col-md-6">
                <div className="relative pb-16 border border-grey bg-white  rounded-lg cust-card mt-4">
                  <AssetCardAsset360 {...props} />
                </div>
              </div>
            ) : null}                        


            {isCaseCard === false && isCaseCheck == 'true' ? (
              <div className="col-md-6">
                <CaseCard {...props} />
              </div>
            ) : null}

            {isKeyContactCard ?
              <div className="col-md-6"><KeyContactAssetCard {...props} />
              </div> : null}
            
             {/* Adding EICR and Gas boiler Card here */}
            {EICRCardToggleValue === "true" && EICRPropsData &&<div className="col-md-6">
                 <EICRCard EICRPropsData = {EICRPropsData}{...props}/>
            </div>}
           {GASCardToggleValue === "true" && GASPropsData && <div className="col-md-6">
                <GasBoilerCard GASPropsData = {GASPropsData}{...props}/>
            </div>}
            {/* end both card */}  
          </div>
         <div className="mt-4 mb-8 px-2">
                          {(backToTop && width<=820)  && <BackToTopComponent/>}    
                      </div>  

        </div>
      ) :
        (
          <div className="div-loader">
            <div className="SpinnerWrapper">
              <LoadingSpinner />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Asset360View;
