import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import CustomerResponseDetails from "../../models/Customer/CustomerResponseDetails";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useSelector } from "react-redux"
import kewContactImg from '../../assets/icons/keyContacts.png';
import '../../utils/commonCss/icons.css'


interface KeyContactAssetCardProps extends RouteComponentProps<any> {
  customerAssetList?: CustomerResponseDetails[];
}
const KeyContactAssetCard: React.FC<KeyContactAssetCardProps> = (props) => {
  const { t } = useTranslation();
  const [regionalHousingManager, setRegionalHousingManager] = useState("");
  const [areaHousingManager, setAreaHousingManager] = useState("");
  const [assistantHeadOfNeighbourhoods, setAssistantHeadOfNeighbourhoods] = useState("");
  const [neighbourhoodManager, setNeighbourhoodManager] = useState("");

  const keyContact = useSelector(
    (state: any) => state?.userChoicesReducer?.keyContacts
  );

  useEffect(() => {
    trackEvent("KeyContactAssetCard");
    setRegionalHousingManager(titleCase(keyContact.regionalHousingManager));
    setAreaHousingManager(titleCase(keyContact.areaHousingManager));
    setAssistantHeadOfNeighbourhoods(titleCase(keyContact.assistantHeadOfNeighbourhoods));
    setNeighbourhoodManager(titleCase(keyContact.neighbourhoodManager));
  }, [keyContact]);

  function titleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
}

  return (
    <div className="relative pb-16 border border-grey bg-grey rounded-lg cust-card mt-4">
      <div>

        <div className="px-4 py-2 border-b border-grey bg-teal rounded-t-lg">
          <div className="flex flex-row d-flex bd-highlight items-center">
          <img src={kewContactImg} className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft" alt="" />
            <h2 className="my-0 text-white bd-highlight">{t("Key_contacts")}</h2>
          </div>
        </div>

        <div className="px-4">
          <div className="py-2">
            <div className="pr-4"><b>{t("regionalHousingManager")}</b>
            </div>
            <div className="text-capitalize"> {regionalHousingManager || "-"}
            </div>
          </div>
        
          <div className="py-2">
            <div className="pr-4"><b>{t("areaHousingManager")}</b>
            </div>
            <div className="text-capitalize">  {areaHousingManager || "-"}</div>
          </div>
          <div className="py-2">
            <div className="pr-4"><b>{t("assistantHeadOfNeighbourhoods")}</b>
            </div>
            <div className="text-capitalize">  {assistantHeadOfNeighbourhoods || "-"}</div>
          </div>
          <div className="py-2">
            <div className="pr-4"><b>{t("neighbourhood")}</b>
            </div>
            <div className="text-capitalize">  {neighbourhoodManager || "-"}</div>
          </div>
        </div>

      </div>
    </div>
  )
};
export default KeyContactAssetCard;