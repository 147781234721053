import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import * as api from "../../api";
import AccountDetails, { TransactDeatils } from "../../models/Accounts/AccountDetails";
import { BalanceType } from "../../models/Accounts/BalanceType";
import React from "react";
import { formatDate } from "../../utils/formatting";
import { useDispatch, useSelector } from "react-redux";
import { PermissionsList } from "../../models/Permissions";
import { transactionInformation, userChosenAccountNumber, userChosenAccountType, userChosenBalance, userChosenBalanceType } from "../../ducks/redux/actions/userChoices";
import { getAccountType } from "../../models/Accounts/AccountType";
import { clearReferralInformation, saveReferralAccountBalance } from "../../ducks/redux/actions/FinancialReferral";
import rentAccountImg from '../../assets/icons/Accounts.png';




interface RentCardProps extends RouteComponentProps<any> {
}
const RentCard: React.FC<RentCardProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isAccount, setIsAccount] = React.useState(false);
    const [accountDetails, setAccountDetails] = useState<AccountDetails>();
    const [accountNumber, setAccountNumber] = React.useState(String);
    const accountnumber = (useSelector(
        (state: any) => state.userChoicesReducer?.accountnumber)
    );
    const tenancyGroup = (useSelector(
        (state: any) => state.userChoicesReducer?.tenancyGroup)
    );
    const customerData = useSelector(
        (state: any) => state?.userChoicesReducer?.customersData
    );
    const accountData = (useSelector(
        (state: any) => state.userChoicesReducer?.accountData)
    );
    const getPermissionsData = useSelector(
        (state: any) => state?.userChoicesReducer?.userProfilePermissions
    );

    const catalystCustomer = useSelector(
        (state: any) => state?.userChoicesReducer?.landlordCheck
    );

    const [isTransaction, setIsTransaction] = useState(false)
    const [dropDown, setDropDown] = useState("none");

    const [isAccountTransactions, setIsAccountTransaction] = useState(false)
    const [referralToggle, setReferralToggle] = useState("true");
    const [referralButton, setReferralButton] = useState(false)



    
    useEffect(()=>{
        (async () => {
            try {
                const ReferralToggle = await api.GetAppConfigToggleValue("IsP360CatalystFinancialReferral");
                setReferralToggle(ReferralToggle.value)
            }catch(e:any){
                props.history.push("/GenericError");   
            }
                
        })()

        const showReferral = getPermissionsData?.indexOf(
            PermissionsList.create_customer360_financial_inclusion_referral
          );
          if (showReferral !== -1) {
            setReferralButton(true);
          } else {
            setReferralButton(false);
          }

    },[])


    useEffect(() => {
        trackEvent("RentCard");
        const showTransaction = getPermissionsData?.indexOf(PermissionsList.view_customer360_rent_account_only_view_account);
        if (showTransaction != -1) {
            setIsTransaction(true)
        }
        else {
            setIsTransaction(false)
        }
        getAccountDetails(accountData);
        setAccountNumber(accountnumber)
    }, [accountData]);

    const callPageHandler = () => {
        let LiveBalanceType = ""
        let LiveBalance = ""
        accountData['balance'].forEach((balance: any) => {
            if (balance['balance-type'] == BalanceType.Livebalanced) {
                let liveBalanced = balance['monetary-amount']['value'];
                const accountBalanceValue = parseInt(liveBalanced);
                LiveBalanceType = accountBalanceValue != null ? accountBalanceValue > 0 ? t("In_arrears") : t("In_credit") : "";
                let number = liveBalanced != null ? Math.abs((liveBalanced.toString().replace(/,/g, ''))) : 0.00;
                LiveBalance = number.toFixed(2);
            }
        })
        let LiveBalanceValue = "£" + LiveBalance
        dispatch(userChosenBalanceType(LiveBalanceType));
        dispatch(userChosenAccountNumber(accountData.identifiers['pay-ref']));
        dispatch(userChosenBalance(LiveBalanceValue));
        dispatch(userChosenAccountType(getAccountType(accountData['account-type'], accountData['tenancy-type'])));
        dispatch(transactionInformation("rent"));
        props.history.push("/TransactionDetails");
    };

    const dropDownFunction = () => {
        if (dropDown === "none")
            setDropDown("block");
        else
            setDropDown("none");
    }

    const getAccountDetails = (accountData: any) => {
        (async () => {
            try {
                if (accountData != null && accountData != "") {
                    const accountBalance = accountData;
                    const accountDetails: AccountDetails = new AccountDetails();
                    const transactDetails: TransactDeatils = new TransactDeatils();
                    accountDetails.LastArrearsAction = accountBalance["last-arrears-action"]?.desccription;
                    accountBalance["balance"].forEach((balance: any) => {
                        if (balance["balance-type"] == BalanceType.Livebalanced) {
                            let liveBalanced = balance["monetary-amount"]["value"];
                            const i = parseInt(liveBalanced);

                            accountDetails.LiveBalanceType =
                                i != null ? (i > 0 ? t("In_arrears") : t("In_credit")) : "";

                            let number =
                                liveBalanced != null
                                    ? Math.abs(liveBalanced.toString().replace(/,/g, ""))
                                    : 0.0;
                            accountDetails.LiveBalance = number.toFixed(2);
                            if (liveBalanced == 0) {
                                accountDetails.LiveBalanceType = "";
                            }
                        }
                    });
                    dispatch(saveReferralAccountBalance(accountDetails.LiveBalance? "£" + accountDetails.LiveBalance: ''));
                    const transactions = await api.getTransactionsSearch(
                        accountnumber,
                        3,
                        0,
                        "",
                        "",
                        ""
                    );
                    const getTransactionList = transactions?.transactions;
                    if (getTransactionList.length > 0) {
                        setIsAccountTransaction(true)
                        const firstBalance = getTransactionList[0]?.amount.net.value;
                        const paymentCode = getTransactionList[0]?.["payment-code"];
                        const transactionDate =
                            getTransactionList[0]?.dates["transaction-date"];
                        const secondBalance = getTransactionList[1]?.amount.net.value;
                        const secondPaymentCode = getTransactionList[1]?.["payment-code"];
                        const secondTransactionDate =
                            getTransactionList[1]?.dates["transaction-date"];
                        const thirdBalance = getTransactionList[2]?.amount.net.value;
                        const thirdPaymentCode = getTransactionList[2]?.["payment-code"];
                        const thirdTransactionDate =
                            getTransactionList[2]?.dates["transaction-date"];

                        transactDetails.LiveBalance =
                            firstBalance != null
                                ? firstBalance < 0
                                    ? Math.abs(firstBalance)
                                    : firstBalance
                                : 0.0;
                        transactDetails.transactiondate = transactionDate;
                        transactDetails.TransactionBalanceType =
                            paymentCode != null
                                ? paymentCode != "DR"
                                    ? "Credit"
                                    : "Debit"
                                : "";
                        transactDetails.LiveBalanceType =
                            paymentCode != null
                                ? paymentCode != "DR"
                                    ? "Payment received"
                                    : "Charge applied"
                                : "";
                        if (parseInt(firstBalance) == 0) {
                            transactDetails.TransactionBalanceType = "";
                            transactDetails.LiveBalanceType = "";
                        }
                        accountDetails.transactDeatils.push(transactDetails);
                        const transactDeatils1: TransactDeatils = new TransactDeatils();
                        transactDeatils1.LiveBalance =
                            secondBalance != null
                                ? secondBalance < 0
                                    ? Math.abs(secondBalance)
                                    : secondBalance
                                : 0.0;
                        transactDeatils1.transactiondate = secondTransactionDate;
                        transactDeatils1.TransactionBalanceType =
                            secondPaymentCode != null
                                ? secondPaymentCode != "DR"
                                    ? "Credit"
                                    : "Debit"
                                : "";
                        transactDeatils1.LiveBalanceType =
                            secondPaymentCode != null
                                ? secondPaymentCode != "DR"
                                    ? "Payment received"
                                    : "Charge applied"
                                : "";
                        if (parseInt(secondBalance) == 0) {
                            transactDeatils1.TransactionBalanceType = "";
                            transactDeatils1.LiveBalanceType = "";
                        }
                        accountDetails.transactDeatils.push(transactDeatils1);
                        const transactDeatils2: TransactDeatils = new TransactDeatils();
                        transactDeatils2.LiveBalance =
                            thirdBalance != null
                                ? thirdBalance < 0
                                    ? Math.abs(thirdBalance)
                                    : thirdBalance
                                : 0.0;
                        transactDeatils2.transactiondate = thirdTransactionDate;
                        transactDeatils2.TransactionBalanceType =
                            thirdPaymentCode != null
                                ? thirdPaymentCode != "DR"
                                    ? "Credit"
                                    : "Debit"
                                : "";
                        transactDeatils2.LiveBalanceType =
                            thirdPaymentCode != null
                                ? thirdPaymentCode != "DR"
                                    ? "Payment received"
                                    : "Charge applied"
                                : "";
                        if (parseInt(thirdBalance) == 0) {
                            transactDeatils2.TransactionBalanceType = "";
                            transactDeatils2.LiveBalanceType = "";
                        }
                        accountDetails.transactDeatils.push(transactDeatils2);
                    }
                    else {
                        setIsAccountTransaction(false)
                    }
                    setAccountDetails(accountDetails);
                    setIsAccount(true);
                } else {
                    setIsAccount(false);
                }
            } catch (e) {
                console.error(e);
                const timerId = setTimeout(() => {
                    props.history.push("/GenericError");
                }, 1000);
            }
        })();
    };

    const handleFinancialReferral = () => {
        dispatch(clearReferralInformation({}))
        props.history.push("/CustomerConsent");
     }   

    return (

        <div className="relative pb-16 border border-grey bg-grey rounded-lg cust-card mt-4">

            <div className="py-2 px-4 border-b border-grey bg-teal rounded-t-lg">
                <div className=" flex flex-row d-flex bd-highlight items-center">
                    {/* <i className="fas fa-bullseye text-teal text-2xl mr-6 bd-highlight"></i> */}
                    <img src={rentAccountImg} alt= "rent account img" className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft"/>
                    <h2 className="my-0 text-white-100 bd-highlight">{t("Rent_Account")}</h2>
                    <div className="menu-nav ms-auto bd-highlight">
                            <div className="dropdown-container">
                                <div className="three-dots kebabMenu" data-testid="dots_menu" onClick={dropDownFunction} ></div>
                                    <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                                        {isAccount && isTransaction && isAccountTransactions && <a data-testid="view_trans" onClick={callPageHandler} className="text-black text-base no-underline global-txt">
                                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("View_Transaction")}</div></a>}
                                        {((!catalystCustomer && referralToggle === "true")|| referralToggle === "false") && referralButton && customerData?.statuses[0]?.value && <a data-testid="view_trans" onClick={handleFinancialReferral} className="text-black text-base no-underline global-txt">
                                        <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("Financial_FI001a")}</div></a>}
                                    </div>
                                </div>
                            </div>                     
                    </div>

            </div>
            {isAccount ? (<>
                    <div className="px-4 mb-2 py-2 flex justify-between text-xl darker-grey">
                        <div className="pr-4 ml-1"><strong> £
                            {accountDetails?.LiveBalance == "0"
                                ? "0.00"
                                : accountDetails !== undefined ?
                                    parseFloat(
                                        accountDetails?.LiveBalance
                                    ).toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })
                                    : undefined} </strong>
                            &nbsp;
                            <span className="text-grey-dark">   {accountDetails?.LiveBalanceType}</span>
                        </div>

                    </div>
                 <div className="px-4 mb-2">
                    <div className="px-2 py-2 ">
                        <div className="pr-4"><strong>{t("pay_ref")}</strong></div>
                        <div>{accountNumber}</div>
                    </div>
                    <div className="px-2 py-2 ">
                        <div className="pr-4"><strong>{t("Tenancy")}</strong></div>
                        <div>{tenancyGroup}</div>
                    </div>
                    <div className="px-2 py-2 ">
                        <div className="pr-4"><strong>{t("Last_arrears_action")}</strong></div>
                        <div className="text-grey-dark">{!(accountDetails?.LastArrearsAction) ? '-' : accountDetails?.LastArrearsAction}</div>
                    </div>
                    {!accountDetails?.transactDeatils[0]?.LiveBalance ||
                        !accountDetails?.transactDeatils[0]?.transactiondate ? (
                        <p className="text-grey-dark">
                            {t("CP040")}
                        </p>
                    ) : (
                        <div>
                            {accountDetails?.transactDeatils[0].LiveBalance ? (
                                <div className="border-b border-grey p-2 mt-2">
                                    <div className="flex justify-between">
                                        <div><strong>    {
                                            accountDetails?.transactDeatils[0]
                                                .LiveBalanceType
                                        }</strong></div>
                                        <div className="text-grey-dark text-xs">  {
                                            accountDetails?.transactDeatils[0]
                                                .TransactionBalanceType
                                        }</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="text-grey-dark">{formatDate(
                                            accountDetails?.transactDeatils[0]
                                                .transactiondate || ""
                                        )}</div>
                                        <div>£
                                            {accountDetails?.transactDeatils[0]
                                                .LiveBalance == 0
                                                ? "0.00"
                                                : accountDetails?.transactDeatils[0].LiveBalance.toLocaleString(
                                                    undefined,
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}</div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {accountDetails?.transactDeatils[1].LiveBalance ? (
                                <div className="border-b border-grey p-2">
                                    <div className="flex justify-between">
                                        <div><strong>  {
                                            accountDetails?.transactDeatils[1]
                                                .LiveBalanceType
                                        }</strong></div>
                                        <div className="text-grey-dark text-xs"> {
                                            accountDetails?.transactDeatils[1]
                                                .TransactionBalanceType
                                        }</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="text-grey-dark">   {formatDate(
                                            accountDetails?.transactDeatils[1]
                                                .transactiondate || ""
                                        )}</div>
                                        <div>£
                                            {accountDetails?.transactDeatils[1]
                                                .LiveBalance == 0
                                                ? "0.00"
                                                : accountDetails?.transactDeatils[1].LiveBalance.toLocaleString(
                                                    undefined,
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}</div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {accountDetails?.transactDeatils[2].LiveBalance ? (
                                <div className="border-b border-grey p-2">
                                    <div className="flex justify-between">
                                        <div><strong>  {
                                            accountDetails?.transactDeatils[2]
                                                .LiveBalanceType
                                        }</strong></div>
                                        <div className="text-grey-dark text-xs"> {
                                            accountDetails?.transactDeatils[2]
                                                .TransactionBalanceType
                                        }</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>   {formatDate(
                                            accountDetails?.transactDeatils[2]
                                                .transactiondate || ""
                                        )}</div>
                                        <div>£
                                            {accountDetails?.transactDeatils[2]
                                                .LiveBalance == 0
                                                ? "0.00"
                                                : accountDetails?.transactDeatils[2].LiveBalance.toLocaleString(
                                                    undefined,
                                                    {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}</div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    )}

                </div>
                </> ) : (
                <p className="px-4 mt-2 global-txt man-pro">
                    {t("no_rent_service")}
                </p>
            )}

        </div>

    )
};
export default RentCard;