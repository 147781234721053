import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom';
import { TenancyModel } from '../../models/Customer/CustomerResponse';
import { addressToString } from '../../utils/formatting';
import { PremisesAddress } from '../../models/shared/Address/PremisesAddress';
import { CustomerData } from "../../models/Customer/CustomerResponse";


interface CustomerResultDetailsProps extends RouteComponentProps<any> {
  TenancyModelsChild?: TenancyModel[];
  tenancyCount?: number;
  customer?: any;
}

const CustomerResultDetails: React.FC<CustomerResultDetailsProps> = (props) => {
  const [isActive, setIsActive] = useState(false);
  const arrayArea: TenancyModel[] = [];
  const [tenancyModelList, setTenancyModelList] = useState(arrayArea);
  const { t } = useTranslation()

  useEffect(() => {
    if (props?.TenancyModelsChild)
      setTenancyModelList(props?.TenancyModelsChild);
  });
  const callPageHandler = () => {
    setIsActive(!isActive);
  }

  return (

    <div>
      <div className={`${isActive ? "bg-dl-teal px-4 py-1" : "bg-dl-teal px-4 py-1 rounded-b-lg"}`}>
        <div onClick={(event) => callPageHandler()}
         data-testid="CustomerResultDetails-main-accor1" className="cursor-pointer flex flex-row justify-between items-center">
          <p className="my-0  "><strong>{t("more_addresses")}</strong></p>
          <div className="plus-sign mt-10 cursor-pointer">
            {isActive ? "⨯" : "+"}
          </div>
        </div>
      </div>
      {isActive ? (

        <div>         
          {tenancyModelList.map((tenancyModels: any) => (
            <div className="border-t border-grey flex flex-col md:flex-row justify-between md:mt-1 sm:mt-4">
              <div className="w-full md:w-6/12 lg:w-6/12 flex items-center px-4 mt-2">
                <p>{addressToString((tenancyModels?.premisesAddress as PremisesAddress))}</p>
              </div>
              <div className="w-full md:w-3/12 lg:w-3/12 flex md:ml-2 mt-2">
                <p>{tenancyModels?.customerTenancyTypeGroup!=""?tenancyModels?.customerTenancyTypeGroup:"-"}</p>       
              </div>

              <div className="w-full md:w-3/12 lg:w-3/12 flex px-4 mt-2">
                <p> {tenancyModels?.isTenancyActive ? `${t("active_txt")}` : `${t("former_txt")}`}</p>
              </div>
</div>
              ))              
            
            }

        </div>

      ) : (
        <div></div>
      )}
    </div>
  )
}


export default CustomerResultDetails