import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import staticSortableFields from "../../models/Property/SortableFields";
import * as api from "../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import PropertyResponse, { Assets } from "../../models/Property/PropertyResponse";
import { isNullOrUndefined } from "../../utils/formatting";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { MobilePagination, Pagination, Paginationbox, WebPagination } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux";
import { assetData } from "../../ducks/redux/actions/getAddress";
import { propertyListData } from "../../ducks/redux/actions/userChoices";
import useBackToTop from '../BackToTop/useBackToTop'
import BackToTopComponent from "../BackToTop";

interface PropertyResultProps extends RouteComponentProps<any> {
    searchText?: string;
    searchTextCount?: number;
    handleSort?: any;
    sortValue?: any;
    propertyType?: any;
    setShowFilter?: boolean;
}
const PropertyResultComponent: React.FC<PropertyResultProps> = (props) => {
    const propertyValue = useSelector(
        (state: any) => state?.userChoicesReducer?.propertyList
    );
    const IsSearch = useSelector(
        (state: any) => state?.userChoicesReducer?.IsSearch
    );
    const array: Assets[] = [];
    const [propertyList, setPropertyList] = useState(array);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] = useState(isNullOrUndefined(props.sortValue) ? "search.score() desc" : props.sortValue);
    const searchTextCountValue = !props.searchTextCount ? 0 : props.searchTextCount;
    const searchTextValue = !props.searchText ? '' : props.searchText;
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const { width, backToTop } = useBackToTop();
    

    useEffect(() => {
        (async () => {
            if (!checkSearchCriteria()) {
                setIsLoading(false);
                return;
            }
            if (!props.setShowFilter && IsSearch) {
                setIsLoading(true);
                const queryParams = {
                    searchText: props.searchText,
                    limit: 10,
                    skip: 0,
                    orderBy: defaultSortOrder,
                    assetType: isNullOrUndefined(props.propertyType) ? '' : props.propertyType,
                };

                getPropertyData(queryParams);
            }
            else {
                [propertyValue].map((items) => {
                    const data = new PropertyResponse(items)
                    setPropertyList(data?.assets);
                    setTotalCount(data?.totalCount);
                    setIsLoading(false);
                });
            }
        })();
    }, []);

    const getPropertyData = (queryParams: any) => {
        (async () => {
            try {
                const propertySearchApi = await api.getPropertyData(queryParams);
                dispatch(propertyListData(propertySearchApi));
                [propertySearchApi].map((items) => {
                    const data = new PropertyResponse(items)
                    setPropertyList(data?.assets);
                    setTotalCount(data?.totalCount);
                    setIsLoading(false);
                });
            } catch (e) {
                setIsLoading(false);
                console.error(e);
                props.history.push("/GenericError");
            }
        })();
    }


    const updateAssetData = (searchText: any, limit: any, skip: any, orderBy: any) => {
        (async () => {
            setIsLoading(true);
            const queryParams = {
                searchText: searchText,
                limit: limit,
                skip: skip,
                orderBy: defaultSortOrder,
                assetType: isNullOrUndefined(props.propertyType) ? '' : props.propertyType,
            };

            getPropertyData(queryParams);
        })();
    }

    const getValidationMessage = () => {
        if (isNullOrUndefined(props.searchText))
            return "blank_search_criteria";

        if ((/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1))))
            return "invalid_search_criteria";

        if (props.searchText?.includes('*')
            && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue)
            return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

        if (searchTextValue?.length < searchTextCountValue)
            return t("minimum_number_of_characters");

        return "assets_search_no_results";
    }

    const checkSearchCriteria = () => {
        if (isNullOrUndefined(props.searchText) || (/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1)))
            || (props.searchText?.includes('*') && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue) || searchTextValue?.length < searchTextCountValue)
            return false;

        return true;
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setCurrentPage(page);
        setSkip(resultsPerPage * page);
        updateAssetData(props.searchText, resultsPerPage, resultsPerPage * page, defaultSortOrder);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setResultsPerPage((e.target as any).value);
        setSkip(0);
        setCurrentPage(0);
        updateAssetData(props.searchText, (e.target as any).value, 0, defaultSortOrder);
    };

    const fetchData = () => {
        let skipResult = 0;
        let totalValue = 0;
        if (totalCount > resultsPerPage) {
            if (skip === 0 && resultsPerPage < 998) {
                skipResult = 0
                totalValue = 999
                setSkip(0);
            }
            else {
                skipResult = resultsPerPage
                totalValue = 999 + skipResult
                setSkip(skipResult);
            }
            setResultsPerPage(totalValue);
            updateAssetData(props.searchText, totalValue, skipResult, defaultSortOrder);
        }
    };

    const callProperty360 = (propertyData: any) => {
        dispatch(assetData(propertyData))
        props.history.push("/Asset360View");

    }

    const displayPropertyList = () => {
       return(<div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto">

                                {!propertyList?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t(getValidationMessage())}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="flex flex-col md:flex-row justify-between items-center">
                                            {/* ====== <!-- count --> ==== */}
                                            <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                <div className="w-1/2 md:w-auto pr-4 md:text-left ml-2"><strong>{totalCount}</strong> {t("property(s)")}</div>
                                            </div>

                                        </div>

                                        {propertyList.map((property) => (
                                            <div className="w-full py-2">
                                                <div className="border border-grey bg-grey rounded-lg">
                                                    <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <p className="my-0 text-lg">
                                                                <a className="my-0 text-lg text-black cursor-pointer" onClick={() => callProperty360(property)}> <strong>{t("asset_uprn")} {`${property.uprn}`}</strong></a>

                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                                        <div className="mb-2">
                                                            <strong>{t("address_txt")}</strong>{" "}
                                                            <br></br>

                                                            {`${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.addressLine1) ? "" : property?.addresses?.postalAddresses[0]?.addressLine1} 
                                       ${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.addressLine2) ? "" : property?.addresses?.postalAddresses[0]?.addressLine2} 
                                       ${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.addressLine3) ? "" : property?.addresses?.postalAddresses[0]?.addressLine3} 
                                       ${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.addressLine4) ? "" : property?.addresses?.postalAddresses[0]?.addressLine4} 
                                       ${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.addressLine5) ? "" : property?.addresses?.postalAddresses[0]?.addressLine5} 
                                       ${isNullOrUndefined(property?.addresses?.postalAddresses[0]?.postcode) ? "" : property?.addresses?.postalAddresses[0]?.postcode} `}
                                                        </div>

                                                        <div className="mb-2 md:ml-4">
                                                            <strong>{t("property_type_txt")}</strong>
                                                            <br></br>
                                                            {`${property.assetType}`}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        ))}

                                        {width>820 && <div className="page-mar">
                                            <Paginationbox>
                                                <label>
                                                    <Pagination
                                                        className=" ml-4 "
                                                        colSpan={7}
                                                        count={totalCount}
                                                        page={currentPage}
                                                        rowsPerPage={resultsPerPage}
                                                        rowsPerPageOptions={[10, 20, 100]}
                                                        onPageChange={onChangePage}
                                                        data-testid="propertyPaginationChange"
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </label>
                                            </Paginationbox>
                                        </div>}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="div-loader">
                                <div className="SpinnerWrapper">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        )}
                    </div>
                   
                    {((backToTop && width<=820) ||(backToTop && resultsPerPage>10 && width>820)) && <BackToTopComponent/>}
            </div>)
    }

    return (
        <div>
             {width<=820? 
                <InfiniteScroll
                        dataLength={propertyList?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                    {displayPropertyList()}
                </InfiniteScroll>:
                displayPropertyList()
             }
        </div>
    )
}

export default PropertyResultComponent;